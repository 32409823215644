import { IconSettings, Input, Modal, Spinner, Tooltip } from "@salesforce/design-system-react";
import Button from "@salesforce/design-system-react/components/button";
import React, { useEffect, useRef, useState } from "react";

import Record from "../../helpers/recordLayer.js";
import PsFilter2 from "../ps-filter/PsFilter2";
import RecordConstants from "../../constants/RecordConstants.js";
import useToastContext from "../../context/useToastContext.tsx";
import { FIELD_ERROR_MESSAGES } from "../../constants/index.js";

// PROPS FROM PARENT
// <FilterSet pattern={cmpState.processed} bubbleEvent={bubbleEvent} />
// so props.pattern contains processed. Pattern doesn't get updated in this component, so we can use use it as props

const FilterSet = (props) => {
    const [filterSetState, setFilterSetState] = useState({
        //Parameters
        filters: [],

        // FEEDBACK
        // records get set in 'setRecords' function
        records: [],

        //Selected Filter
        listOrder: null,
        selectedFilter: null,
        selectedContainerId: null,

        //User Interface
        filterModalIsOpen: false,
        loading: false,

        showRequiredFieldError: false,
    });

    const cmpWorking = useRef({});
    const filterCmpRef = useRef(null);

    const { addToast } = useToastContext();

    useEffect(() => {
        cmpWorking.current = { ...filterSetState };
    }, []);

    useEffect(() => {
        parsePattern();
    }, [props.pattern]);

    const cmp = {
        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setFilterSetState((prev) => ({ ...prev, [key]: value }));
        },

        dispatchEvent: function (event) {
            props.childToParent(event);
        },

        bubbleEvent: function (event) {
            let stopPropagation = false;

            if (!stopPropagation) {
                props.childToParent(event);
            }
        },
    };

    const setRecords = (records) => {
        cmpWorking.current.records = records;
        setFilterSetState((prev) => ({
            ...prev,
            records,
        }));
        // applyFilters();
    };

    const setLoading = () => {
        cmp.set("loading", true);
    };

    const unsetLoading = () => {
        cmp.set("loading", false);
    };

    const parseRecord = (record, listOrder) => {
        // listOrder
        record.listOrder = listOrder;

        // can revert
        record.reset = record.floatingId && ["Session", "Project", "DashBoard", "Global"].includes(record.scope);

        // key values (only available for filters retrieved from Filter modal)
        var settings = record.settings || {};
        var selectedValues = settings.values;
        var valuesOptions = record.filterKey?.values;
        var selectedValue = selectedValues && selectedValues.length === 1 ? selectedValues[0] : null;
        var labelsOptions = [];
        if (valuesOptions) {
            valuesOptions = valuesOptions.map(({ label }) => ({
                label,
                value: label,
            })); // API expects labels, not values
            valuesOptions = JSON.parse(JSON.stringify(valuesOptions)); // take a deepcopy, to prevent coupling picklists for the same Keys or presets
            valuesOptions.forEach((item) => {
                item.selected = item.value === selectedValue;
            });
            valuesOptions.unshift({ label: "--None--", value: "" });
            labelsOptions = valuesOptions.map(({ label }) => label);
        }

        // operator
        var type = record.type;
        var alias = settings.alias;
        var operator = record.operator;

        // settings.alias overrides the operator
        if (alias) {
            operator = alias;
        }
        var options = RecordConstants.FILTER_TYPES[type] || [];
        var option = options.find((i) => i.value === operator) || {};
        var useInput = option.useInput;

        if (useInput === "ListValues") {
            useInput = valuesOptions && valuesOptions.length ? "MultiSelect" : "FreeText";
        }

        // values
        if (useInput === "FreeText") {
            record.useInput = "FreeText";
            record.operator = option.label + ": ";
            record.freeTextValues = Record.toCSV(selectedValues);
        } else if (useInput === "MultiSelect") {
            if (!selectedValues || selectedValues.length <= 1) {
                // single-select for up to 1 item selected
                record.useInput = "SingleSelect";
                record.operator = option.label;
                record.selected = selectedValue;
                record.valuesOptions = valuesOptions;
            } else {
                // multiselect not yet supported
                // var selectedLabels = selectedValues.reduce((obj, item) => {
                //   if (labelsOptions.includes(item)) {
                //     obj.push(item);
                //   }
                //   return obj;
                // }, []);
                var selectedLabels = selectedValues.reduce((arr, item) => {
                    if (item && item && labelsOptions.includes(item)) {
                        arr.push(item);
                    }
                    return arr;
                }, []);

                record.useInput = null;
                record.operator = option.label + ": ";
                record.values = selectedLabels && selectedLabels.length ? Record.toCSV(selectedLabels) : "--Empty--";
            }
        } else if (useInput === "BetweenNumber") {
            record.useInput = null;
            if (settings.min == null && settings.max == null) {
                record.operator = "";
                record.values = "";
            } else if (settings.min == null) {
                record.operator = settings.includeMax == null || settings.includeMax ? "≤" : "< ";
                record.values = settings.max;
            } else if (settings.max == null) {
                record.operator = settings.includeMin == null || settings.includeMin ? "≥ " : "> ";
                record.values = settings.min;
            } else {
                record.operator = "Between ";
                record.values = settings.min + " and " + settings.max;
            }
        } else if (useInput === "BetweenDateTime") {
            record.useInput = null;
            if (settings.min == null && settings.max == null) {
                record.operator = "";
                record.values = "";
            } else if (settings.min == null) {
                record.operator = "Until: ";
                record.values = settings.max;
            } else if (settings.max == null) {
                record.operator = "From: ";
                record.values = settings.min;
            } else {
                record.operator = "Between ";
                //   record.values =
                //     $A.localizationService.formatDateTime(settings.min) +
                //     " and " +
                //     $A.localizationService.formatDateTime(settings.max);
                const formattedMinDate = new Date(settings.min).toLocaleString();
                const formattedMaxDate = new Date(settings.max).toLocaleString();
                record.values = `${formattedMinDate} and ${formattedMaxDate}`;
            }
        } else if (useInput === "RelativeDateTime") {
            record.useInput = null;
            if (settings.min == null && settings.max == null) {
                record.operator = "";
                record.values = "";
            } else if (settings.min == null) {
                record.operator = "Until ";
                record.values = Record.intervalToHuman(settings.max);
            } else if (settings.max == null) {
                record.operator = "Starting ";
                record.values = Record.intervalToHuman(settings.min);
            } else {
                record.operator = "Between ";
                record.values = Record.intervalToHuman(settings.min) + " and " + Record.intervalToHuman(settings.max);
            }
        } else if (useInput === "Preset") {
            var preset = settings.preset;
            var presets = record.presets;
            var presetDefaults = JSON.parse(JSON.stringify(RecordConstants.FILTER_PRESETS[type])); // deepclone to prevent changing original values
            var presetOptions = presets || presetDefaults || [];
            presetOptions.forEach((item) => {
                item.selected = item.value === preset;
            });
            record.useInput = "Preset";
            record.operator = "";
            record.values = "";
            record.selected = preset;
            record.valuesOptions = presetOptions;
        } else if (useInput === "Missing") {
            record.operator = operator === "Missing" ? "Is Missing" : "Is Not Missing";
            record.values = "";
        } else {
            record.operator = null;
            record.values = null;
        }
    };

    const parseRecords = (records) => {
        for (var i = 0; i < records.length; i++) {
            parseRecord(records[i], i);
        }
        setRecords(records);
        unsetLoading();
    };

    // IMRPROVEMEN; check if there is a standard way of doing this
    const append = (obj, attrib, value) => {
        var arr = obj[attrib];
        if (arr) {
            arr.push(value);
        } else {
            obj[attrib] = [value];
        }
    };

    const filtersToRecords = (filters) => {
        // extract keyIds from filter spec
        var records = [];
        var record, nodes, keyId;
        var keyIdToRecord = {};

        // copy filters to records; records are used in the UI so underlying filters are not affected
        for (let filter of filters) {
            // check filter.keyId and filter.key.id
            record = JSON.parse(JSON.stringify(filter));
            records.push(record);
        }

        // exctract keyIds, and map them to their original records
        for (let record of records) {
            nodes = Record.flatten(record, "inputs");
            for (let node of nodes) {
                keyId = node.keyId || node.key?.id;
                if (keyId) {
                    if (keyId) {
                        append(keyIdToRecord, keyId, record);
                    }
                }
            }
        }

        // query Keys, and store them to records
        var keyIds = Object.keys(keyIdToRecord);
        if (keyIds.length) {
            var onSuccess = function (response) {
                for (let result of response) {
                    for (let record of keyIdToRecord[result.id] || []) {
                        record.filterKey = result;
                    }
                }
                // TODO: nothing is done with the response, why are we making a callout here?
                parseRecords(records);
            };

            var onError = function (response) {
                parseRecords(records);
            };

            Record.getRecords("store", "values", { id: keyIds }, onSuccess, onError);
        } else {
            parseRecords(records);
        }
    };

    const parsePattern = () => {
        var FLT_IND = 4;
        var pattern = props.pattern || {};
        var nodes = pattern.inputs || [];
        var filters = [];

        // extract Filters
        var filter;
        nodes.forEach((node) => {
            if (node.argOrder === FLT_IND) {
                filter = JSON.parse(JSON.stringify(node.filter || {})); // deepcopy, to prevent changing the original filter in the pattern
                // DeepSigma loads a record from the database if an 'id' is provided, so we remove it and keep track of the original 'id' in 'floatingId'
                if (filter.id) {
                    filter.floatingId = filter.id;
                    filter.id = undefined;
                }
                filters.push(filter);
            }
        });

        // save and create ui records (only if filters changed)
        if (JSON.stringify(cmpWorking.current.filters) !== JSON.stringify(filters)) {
            cmpWorking.current.filters = filters;
            setFilterSetState((prev) => ({ ...prev, filters }));
            setLoading();
            filtersToRecords(filters);
        }
    };

    const editFilter = (listOrder) => {
        // var listOrder = event.getSource().get("v.name");
        var filters = cmpWorking.current.filters || [];
        var filter = filters[listOrder];
        var pattern = props.pattern;

        //cmpWorking was not being set here. Is there a risk that new state was not being picked up by cmpWorking?
        cmpWorking.current.listOrder = listOrder;
        cmpWorking.current.selectedFilter = filter;
        cmpWorking.current.selectedContainerId = pattern?.container?.id;

        setFilterSetState((prev) => ({
            ...prev,
            listOrder,
            selectedFilter: filter,
            selectedContainerId: pattern?.container?.id,
            filterModalIsOpen: true,
        }));
    };

    const deleteFilter = (listOrder) => {
        // update ui
        var records = cmpWorking.current.records || [];
        records[listOrder] = null;

        // update filter
        var filters = cmpWorking.current.filters || [];
        filters[listOrder] = null;
        cmpWorking.current.filters = filters;
        cmpWorking.current.records = records;
        setFilterSetState((prev) => ({ ...prev, filters, records }));
        applyFilters();
    };

    const toggleFilterActive = (listOrder) => {
        // update UI
        var records = cmpWorking.current.records || [];
        var record = records[listOrder];
        record.active = !record.active;
        // update filter
        var filters = cmpWorking.current.filters || [];
        var filter = filters[listOrder];
        filter.active = record.active;
        cmpWorking.current.records = records;
        cmpWorking.current.filters = filters;
        setFilterSetState((prev) => ({ ...prev, filters, records }));
        applyFilters();
    };

    const updateFilterValue = (listOrder, value) => {
        // update ui
        var records = cmpWorking.current.records || [];
        var record = records[listOrder] || {};
        var useInput = record.useInput;
        var settings = record.settings || {};
        var presetOption;
        if (useInput === "SingleSelect") {
            settings.values = value ? [value] : null;
            record.valuesOptions?.forEach((item) => {
                item.selected = item.value === value;
            });
        } else if (useInput === "FreeText") {
            var newValues = Record.parseCSV(value);
            if (JSON.stringify(settings.values) === JSON.stringify(newValues)) {
                // no change
                return;
            }
            settings.values = newValues;
        } else if (useInput === "Preset") {
            presetOption = record.valuesOptions.find((i) => i.value === value) || {};
            Object.assign(settings, presetOption.settings || {});
            settings.preset = value;
            record.valuesOptions.forEach((item) => {
                item.selected = item.value === value;
            });
        }
        // update filters
        var filters = cmpWorking.current.filters || [];
        var filter = filters[listOrder];
        filter.settings = record.settings;
        if (presetOption) {
            filter.operator = presetOption.operator;
        }
        cmpWorking.current.records = records;
        cmpWorking.current.filters = filters;
        setFilterSetState((prev) => ({ ...prev, filters, records }));
        applyFilters();
    };

    const helperUpdateFilterValue = (item, value, listOrder) => {
        // var listOrder = event.getSource().get("v.name");
        // var value = event.getSource().get("v.value");
        // helper.updateFilterValue(cmp, listOrder, value);

        // var listOrder = event.getSource().get("v.name");
        // var value = event.getSource().get("v.value");

        cmpWorking.current.records = cmpWorking.current.records.map((record, index) => (index === listOrder ? { ...record, [item]: value } : record));

        setFilterSetState((prev) => ({
            ...prev,
            records: cmpWorking.current.records,
        }));

        updateFilterValue(listOrder, value);
    };

    const newFilter = () => {
        var pattern = props.pattern;
        cmpWorking.current.listOrder = null;
        cmpWorking.current.selectedFilter = null;
        cmpWorking.current.selectedContainerId = pattern?.container?.id;
        cmpWorking.current.filterModalIsOpen = true;
        setFilterSetState((prev) => ({
            ...prev,
            listOrder: null,
            selectedFilter: null,
            selectedContainerId: pattern?.container?.id,
            filterModalIsOpen: true,
        }));
    };

    const cancelFilter = () => {
        cmpWorking.current.listOrder = null;
        cmpWorking.current.filterModalIsOpen = false;
        cmpWorking.current.showRequiredFieldError = false;
        setFilterSetState((prev) => ({
            ...prev,
            listOrder: null,
            filterModalIsOpen: false,
            showRequiredFieldError: false,
        }));
    };

    const isFormValid = (record, cardBody, fieldErrors, setFieldErrors) => {
        if (record && fieldErrors && Object.entries(fieldErrors).some(([key, error]) => error || (key in record && record[key] === ""))) {
            return false;
        }

        let isValid = true;
        const validateElement = (element) => {
            if (!element) return;

            if (React.isValidElement(element)) {
                const { type, props } = element;

                if (props.body && props.body?.props?.required && (!props.body?.props?.value || props.body?.props?.value === "--None--")) {
                    isValid = false;

                    const field = props.body.props.name;
                    if (field) {
                        const errorMessage = props.body.props.errorMessage || FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;
                        setFieldErrors(field, errorMessage);
                    }
                }
                if (props.children) {
                    React.Children.forEach(props.children, validateElement);
                }
            } else if (Array.isArray(element)) {
                element.forEach(validateElement);
            }
        };
        validateElement(cardBody);

        return isValid;
    };

    // this saveFilter is coming from filterSetController
    const saveFilter = () => {
        const record = filterCmpRef.current.record;
        const cardBody = filterCmpRef.current.cardBody;
        const fieldErrors = filterCmpRef.current.fieldErrors;
        const setFieldErrors = filterCmpRef.current.setFieldErrors;
        const filterJson = filterCmpRef.current.filterJson();

        if (!isFormValid(record, cardBody, fieldErrors, setFieldErrors)) {
            const toastId = record?.id || "recordCreateId";
            addToast("error", "Input Error", "Please update the invalid form entries and try again.", toastId);
            return;
        }
        const filter = filterJson;
        const listOrder = filterSetState.listOrder;
        saveFilterHelper(filter, listOrder);

        cmpWorking.current.filterModalIsOpen = false;
        cmpWorking.current.listOrder = null;

        setFilterSetState((prev) => ({
            ...prev,
            filterModalIsOpen: false,
            listOrder: null,
        }));
    };

    // this saveFilter is coming from filterSetHelper
    const saveFilterHelper = (filter, listOrder) => {
        try {
            // deepcopy and parse
            var record = JSON.parse(JSON.stringify(filter));
            delete filter.filterKey;
            parseRecord(record, listOrder);
            var filters = cmpWorking.current.filters || [];
            var records = cmpWorking.current.records || [];
            if (listOrder == null) {
                record.listOrder = records.length;
                filters.push(filter);
                records.push(record);
            } else {
                filters[listOrder] = filter;
                records[listOrder] = record;
            }
            cmpWorking.current.filters = filters;
            setFilterSetState((prev) => ({ ...prev, filters }));
            setRecords(records);
        } catch (err) {
            console.error(err);
        } finally {
            cancelFilter();
            applyFilters();
        }
    };

    const applyFilters = () => {
        try {
            var pattern = helperApplyFilters();
            cmp.dispatchEvent({ type: "dataCompEvent", action: "update", pattern: pattern });
            props.onPatternChange && props.onPatternChange(pattern, true);
        } catch (err) {
            console.error(err);
        }
    };

    const helperApplyFilters = () => {
        try {
            var FLT_IND = 4;
            var pattern = props.pattern || {};
            var nodes = pattern.inputs || [];
            var records = cmpWorking.current.records;
            var filters = cmpWorking.current.filters;

            // first, update existing filters (same order as they are extracted in parsePattern)
            var listOrder = 0;
            nodes.forEach((node) => {
                if (node.argOrder === FLT_IND) {
                    node.filter = filters[listOrder];
                    listOrder++;
                }
            });

            // next, add new filters inside a Where Node
            if (listOrder < filters.length) {
                for (var i = listOrder; i < filters.length; i++) {
                    nodes.push({
                        type: "Where",
                        argOrder: FLT_IND,
                        rootOrder: FLT_IND,
                        filter: filters[i],
                        inputs: [],
                    });
                }
            }

            // remove Where nodes with deleted filters
            nodes = nodes.filter((node) => node.argOrder !== FLT_IND || node.filter);

            // force recalculation of previously calculated intervals in 'Refine' Nodes
            nodes.forEach((node) => {
                if (node.type === "Refine" && node.parameters) {
                    delete node.parameters.interval;
                }
            });

            // update filters so removed items and listOrder stay in sync even if pattern doesn't return correctly
            pattern.inputs = nodes;

            records = records.filter((item) => item);
            filters = filters.filter((item) => item);

            cmpWorking.current.filters = filters;
            cmpWorking.current.records = records;
            setFilterSetState((prev) => ({
                ...prev,
                records,
                filters,
            }));
            return pattern;
        } catch (err) {
            console.error(err);
        }
    };

    const recordValueWithToolTip = (record) => {
        const content = record?.operator + " " + record?.values;
        return (
            <>
                <span title={record?.name}>{record?.name}</span>
                <br />
                {!record?.useInput && <span title={content}>{content}</span>}
            </>
        );
    };

    return (
        <>
            <div className="slds-is-relative">
                {filterSetState.loading && <Spinner assistiveText={{ label: "Loading" }} />}
                <div className="slds-scrollable--y slds-m-bottom_x-small" style={{ maxHeight: "200px" }}>
                    {filterSetState.records.length > 0 &&
                        filterSetState.records.map((record, index) =>
                            record == null ? null : (
                                <div key={index} className="slds-m-vertical_x-small">
                                    <lightning-layout verticalAlign="center" style={{ display: "flex", marginBottom: "15px" }}>
                                        <div
                                            // alignmentBump="right"
                                            className="slds-truncate slds-has-flexi-truncate"
                                            style={{ maxWidth: "35em" }}
                                        >
                                            {recordValueWithToolTip(record)}
                                        </div>
                                        <div style={{ float: "right", marginRight: "-10px" }}>
                                            <button
                                                className={`slds-button slds-button_icon ${record?.active ? "slds-button_icon-brand" : "slds-button_icon-border"}`}
                                                title={record?.active ? "Active" : "Inactive"}
                                                onClick={() => toggleFilterActive(record.listOrder)}
                                            >
                                                <svg className="slds-button__icon" aria-hidden="true">
                                                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#filterList"></use>
                                                </svg>
                                                <span className="slds-assistive-text">Search</span>
                                            </button>
                                            <Button
                                                assistiveText={{ icon: "Edit this Filter" }}
                                                iconCategory="utility"
                                                iconName="edit"
                                                iconVariant="border"
                                                variant="icon"
                                                title="Edit"
                                                onClick={() => editFilter(record.listOrder)}
                                            />
                                            <Button
                                                className="slds-m-right_small"
                                                assistiveText={{ icon: "Delete this Filter" }}
                                                iconCategory="utility"
                                                iconName="delete"
                                                iconVariant="border"
                                                variant="icon"
                                                disabled={record?.reset}
                                                title={record?.reset ? `Cannot delete filter with ${record?.scope} scope` : "Delete"}
                                                onClick={record?.reset ? () => console.log(`Cannot delete filter with ${record?.scope} scope`) : () => deleteFilter(record.listOrder)}
                                            />
                                        </div>
                                    </lightning-layout>
                                    <div className="slds-truncate">
                                        {/* Preset */}
                                        {record?.useInput === "Preset" && (
                                            <div className="slds-form-element slds-form-element_stacked">
                                                <div className="slds-form-element">
                                                    <div className="slds-form-element__control">
                                                        <div className="slds-select_container">
                                                            <select
                                                                className="slds-select"
                                                                id="select-01"
                                                                onChange={(e) => helperUpdateFilterValue("selected", e.target.value, record.listOrder)}
                                                                value={record?.selected || ""}
                                                            >
                                                                {record?.valuesOptions.map((item, idx) => (
                                                                    <option key={idx} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* FreeText */}
                                        {record?.useInput === "FreeText" && (
                                            <div className="slds-col slds-size--12-of-12 slds-form-element slds-form-element_stacked">
                                                <Input
                                                    type="text"
                                                    name={record?.listOrder.toString()}
                                                    value={record?.freeTextValues || ""}
                                                    label={record.operator}
                                                    fieldLevelHelpTooltip={
                                                        <Tooltip
                                                            id="field-level-help-tooltip"
                                                            align="top left"
                                                            content='Enter one or more values separated by commas. Enclose values with commas in double quotes. Example: orange, "pear, apple", banana'
                                                        />
                                                    }
                                                    onChange={(e) => helperUpdateFilterValue("freeTextValues", e.target.value, record.listOrder)}
                                                    onBlur={(e) => helperUpdateFilterValue(e)}
                                                    autocomplete="off"
                                                />
                                            </div>
                                        )}

                                        {/* SingleSelect */}
                                        {record?.useInput === "SingleSelect" && (
                                            <div className="slds-col slds-size--12-of-12 slds-form-element slds-form-element_stacked">
                                                <div className="slds-form-element">
                                                    <label className="slds-form-element__label" htmlFor="select-01">
                                                        {record?.operator}
                                                    </label>
                                                    <div className="slds-form-element__control">
                                                        <div className="slds-select_container">
                                                            <select
                                                                className="slds-select"
                                                                name={record?.listOrder}
                                                                value={record?.selected || ""}
                                                                id="select-01"
                                                                onChange={(e) => helperUpdateFilterValue("selected", e.target.value, record.listOrder)}
                                                            >
                                                                {record?.valuesOptions.map((item, idx) => (
                                                                    <option key={idx} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                </div>
                <div className="slds-grid slds-gutters_xx-small">
                    <div className="slds-col slds-size_1-of-2">
                        <button type="submit" className="slds-button slds-button_neutral slds-button_stretch" onClick={newFilter} label="New..." title="New Filter">
                            New...
                        </button>
                    </div>
                </div>
            </div>
            {/* <!-- Filters Modal Window --> */}
            <IconSettings iconPath="/assets/icons">
                <Modal
                    isOpen={filterSetState.filterModalIsOpen}
                    footer={[<Button key="Cancel" label="Cancel" onClick={cancelFilter} />, <Button key="Save" label="Save" variant="brand" onClick={saveFilter} />]}
                    onRequestClose={cancelFilter}
                    heading="Filter"
                >
                    <div className="slds-modal__content slds-p-around_medium" id="modal-content-id-filter">
                        {/* <PsFilter
                            recordValue={filterSetState.selectedFilter}
                            parentId={filterSetState.selectedContainerId}
                            newScopes={["Pattern"]}
                            isModal={true}
                            ref={filterCmpRef}
                            filterSetState={filterSetState}
                            showRequiredFieldError={filterSetState.showRequiredFieldError}
                        /> */}

                        <PsFilter2
                            parentId={filterSetState.selectedContainerId}
                            ref={filterCmpRef}
                            options={{
                                filter: {
                                    newScopes: ["Pattern"],
                                    isModal: true,
                                    filterSetState: filterSetState,
                                    showRequiredFieldError: filterSetState.showRequiredFieldError,
                                    recordValue: filterSetState.selectedFilter,
                                },
                            }}
                        />
                    </div>
                </Modal>
            </IconSettings>
        </>
    );
};

export default FilterSet;
