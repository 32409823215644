const Divider = ({ handleExpandCollapse }) => {
    return (
        <div
            style={{
                width: "30px",
                marginLeft: "-10x",
            }}
        >
            <div className="divider">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <button
                        className="slds-button slds-button_icon slds-button_icon-brand slds-button_icon-x-small"
                        title="Expand"
                        style={{ borderRadius: "50%", marginTop: "10px" }}
                        onClick={handleExpandCollapse}
                    >
                        <svg className="slds-button__icon" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#jump_to_right"></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Divider;
