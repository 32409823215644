export const RECORD_COLUMNS = [
    {
        key: "namePlain",
        label: "Pattern",
        property: "namePlain",
        type: "link",
        action: "details",
        minWidth: 200,
    },
    {
        key: "relevance",
        label: "Relevance",
        property: "relevance",
        type: "percent",
        width: 120,
    },
    {
        key: "containerName",
        label: "Object",
        property: "containerName",
        type: "text",
        width: 200,
    },
    {
        key: "sourceName",
        label: "Source",
        property: "sourceName",
        type: "text",
        width: 200,
    },
];
