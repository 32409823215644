export const RECORD_COLUMNS = [
    {
        key: "name",
        label: "Name",
        property: "name",
        type: "link",
        href: "/DataManagement?selected=pump_connector_${id}", // eslint-disable-line no-template-curly-in-string
        action: "details",
        sortable: true,
        minWidth: 200,
    },
    {
        key: "sourceName",
        label: "Source",
        property: "sourceName",
        type: "link",
        href: "/DataManagement?selected=pump_source_${sourceId}", // eslint-disable-line no-template-curly-in-string
        action: "viewSource",
        sortable: true,
        width: 200,
    },
    {
        key: "connectorTypeName",
        label: "Type",
        property: "connectorTypeName",
        type: "text",
        sortable: false,
        width: 200,
    },
    {
        key: "status",
        label: "Status",
        property: "status",
        type: "text",
        sortable: true,
        width: 120,
    },
    {
        key: "schedule",
        label: "Schedule",
        property: "schedule",
        type: "text",
        width: 100,
    },
    {
        key: "runEnd",
        label: "Last Run Completed",
        property: "runEnd",
        type: "formattedDate",
        sortable: true,
        width: 200,
    },
    {
        key: "runStatus",
        label: "Last Run Status",
        property: "runStatus",
        type: "text",
        sortable: true,
        width: 170,
    },
];
