import { useEffect } from "react";
import Cookies from "js-cookie";
import { Navigate, Route, Routes, useLocation } from "react-router";

import useAuthContext from "../context/useAuthContext";
import PsSEO from "../components/ps-SEO/PsSEO";
import Home from "../pages/home/Home";
import Explore from "../pages/explore/Explore";
import SearchPage from "../pages/search/Search";
import Build from "../pages/builder/Build";
import SavedInsights from "../pages/saved-insights/SavedInsights";
import PsPermissionWrapper from "../components/ps-permission-wrapper/PsPermissionWrapper";
import RecordConstants from "../constants/RecordConstants";
import { setupRoutes } from "../pages/setup/routes";
import Store from "../pages/store/Store";
import DataManagement from "../pages/data-management/DataManagement";
import Profile from "../pages/profile/Profile";
import NotFound from "../pages/not-found/NotFound";
import { PAGE_SEO } from "../constants";

const AuthRouter = () => {
    const { handleLogout } = useAuthContext();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === "/signup") handleLogout();
    }, [pathname]);

    return (
        <Routes>
            <Route
                path=""
                element={
                    <>
                        <PsSEO {...PAGE_SEO.home} /> <Home />
                    </>
                }
            />
            <Route
                path="explore"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.explore} /> <Explore />
                    </>
                }
            />
            <Route
                path="search"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.search} /> <SearchPage />
                    </>
                }
            />
            <Route
                path="build"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.build} /> <Build />
                    </>
                }
            />
            <Route
                path="savedInsights"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.saveInsights} /> <SavedInsights />
                    </>
                }
            />

            {setupRoutes.map((item) => (
                <Route
                    key={"Setup"}
                    path={item.path}
                    element={
                        <>
                            <PsPermissionWrapper permittedUserTypes={[RecordConstants.USER_TYPE.ADMINISTRATOR.value]} redirectPath="/">
                                <PsSEO {...PAGE_SEO.setup} />
                                {item.element}
                            </PsPermissionWrapper>
                        </>
                    }
                />
            ))}
            <Route
                path="storeManagement"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.store} /> <Store />
                    </>
                }
            />
            <Route
                path="dataManagement"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.dataManagement} /> <DataManagement />
                    </>
                }
            />
            <Route
                path="profile"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.profile} /> <Profile />
                    </>
                }
            />

            <Route path="forgotPassword" element={<Navigate to="/" replace />} />
            <Route path="login" element={<Navigate to="/" replace />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AuthRouter;
