import { useState, useEffect } from "react";
import { Card } from "@salesforce/design-system-react";
import { useSearchParams } from "react-router-dom";

import "../Pages.css";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import NewPasswordForm from "./components/NewPasswordForm";

const Login = () => {
    const [searchParams] = useSearchParams();
    const [newUser, setNewUser] = useState(null);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [mode, setMode] = useState(""); //login, resetPassword, newPassword

    useEffect(() => {
        onPageReferenceChange();
    }, [searchParams]);

    const onPageReferenceChange = () => {
        try {
            var mode = searchParams.get("mode") || "login";
            setMode(mode.toLowerCase());
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="login-signup-container">
            <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
                {mode === "login" && <LoginForm setMode={setMode} setNewUser={setNewUser} />}

                {mode === "resetpassword" && <ResetForm confirmationCode={confirmationCode} setConfirmationCode={setConfirmationCode} setMode={setMode} />}

                {mode === "newpassword" && <NewPasswordForm newUser={newUser} />}
            </Card>
        </div>
    );
};

export default Login;
