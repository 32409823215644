import React, { useEffect, useState } from "react";
import "./PsTopMenuBar.css";
import Record from "../../helpers/recordLayer";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { Avatar, Button, GlobalHeaderProfile, IconSettings } from "@salesforce/design-system-react";
import Popover from "@salesforce/design-system-react/components/popover";
import HeaderProfileCustomContent from "./components/HeaderProfileCustomContent";
import useAuthContext from "../../context/useAuthContext";
import PsPermissionWrapper from "../ps-permission-wrapper/PsPermissionWrapper";
import { PAGE_ROUTES } from "../../constants";
import RecordConstants from "../../constants/RecordConstants";

const tabs = [
    { path: "/", title: "Home" },
    { path: "/Explore", title: "Explore" },
    { path: "/Search", title: "Search" },
    { path: "/Build", title: "Build" },
    { path: "/SavedInsights", title: "Saved Insights" },
    { path: "/DataManagement", title: "Data Management" },
    { path: "/StoreManagement", title: "Store Management" },
];
const setupPath = PAGE_ROUTES.SETUP.DEFAULT.path;

const PsTopMenuBar = () => {
    const [openProfile, setOpenProfile] = useState(false);
    const [zIndex, setZIndex] = useState(999);
    const { user, isAuthenticated } = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(location.pathname);

    const addBB = selectedTab === "/" && !!isAuthenticated;
    const avatar = <Avatar assistiveText={{ icon: "Avatar image" }} imgSrc="https://lightningdesignsystem.com/assets/images/profile_avatar_200.png" imgAlt="Person Name" />; //  <Avatar variant="user" label={user?.username} size="medium" />;

    useEffect(() => {
        setSelectedTab(location.pathname);
    }, [location.pathname]);

    const handleClickMenuAndLink = (path) => {
        setSelectedTab(path);
    };

    const menuItems = tabs.map((tab) => ({
        key: tab.path,
        label: (
            <div key={tab.path} onClick={() => handleClickMenuAndLink(tab.path)}>
                <Link to={tab.path} className="ps-tab-link">
                    <span className="slds-truncate" title={tab.title}>
                        {tab.title}
                    </span>
                </Link>
            </div>
        ),
    }));

    //Both tab and path are required and should be strings. isSetupPage return boolean
    function isSetupPage(tab, path) {
        const tabLowerCase = tab?.toLowerCase();
        const pathLowerCase = path?.toLowerCase();
        if (tabLowerCase === pathLowerCase || tabLowerCase.includes(pathLowerCase)) return true;
        return false;
    }

    return (
        <IconSettings iconPath="/assets/icons">
            <div
                className={`ps-top-menu-bar-container ${addBB ? "top-menu-bb" : ""} ${!isAuthenticated ? "top-menu-bs" : ""}`}
                style={{
                    zIndex: zIndex,
                }}
            >
                {/* LOGO */}
                <div className="logo-wrapper">
                    <img src="/assets/images/PsLogo.png" alt="Logo" className="logo-img" onClick={() => navigate("/", { replace: true })} />
                </div>

                {!!isAuthenticated && (
                    <>
                        <div className="tab-container">
                            <Menu className="ps-top-tab-navigation" onClick={(e) => handleClickMenuAndLink(e.key)} selectedKeys={[selectedTab]} mode="horizontal" items={menuItems} />
                        </div>
                        {/* <div
              style={{
                width: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Search />
            </div> */}
                        <div className="setting-profile-container">
                            {/* <div
            style={{
              display: "flex",
              gap: "5px",
            }}
          >
            <Button
              assistiveText={{ icon: "Icon Bare Small" }}
              iconCategory="utility"
              iconName="notification"
              iconSize="medium"
              iconVariant="bare"
              onClick={() => {
                console.log("Icon Bare Clicked");
              }}
              variant="icon"
            />
            <Button
              assistiveText={{ icon: "Icon Bare Small" }}
              iconCategory="utility"
              iconName="question_mark"
              iconSize="medium"
              iconVariant="bare"
              onClick={() => {
                console.log("Icon Bare Clicked");
              }}
              variant="icon"
            />
            </div> */}
                            <PsPermissionWrapper // Show this button only if is admin user
                                permittedUserTypes={[RecordConstants.USER_TYPE.ADMINISTRATOR.value]}
                            >
                                <Button
                                    className={`ps-setup-btn ${isSetupPage(selectedTab, setupPath) ? "ps-setup-active" : ""}`}
                                    assistiveText={{ icon: "Setting icon" }}
                                    iconCategory="utility"
                                    iconName="settings"
                                    iconSize="medium"
                                    iconVariant="global-header"
                                    variant="icon"
                                    onClick={() => navigate(setupPath)} // Navigates to the setup page
                                />
                            </PsPermissionWrapper>

                            <div className="profile-container">
                                <GlobalHeaderProfile
                                    userName=""
                                    avatar={avatar}
                                    popover={
                                        <Popover
                                            isOpen={openProfile}
                                            onClick={() => setOpenProfile(true)}
                                            onRequestClose={() => setOpenProfile(false)}
                                            onOpen={() => setZIndex(9999)}
                                            onClose={() => setZIndex(999)}
                                            id="header-profile-popover-id"
                                            ariaLabelledby="profile-heading"
                                            heading=""
                                            body={
                                                <HeaderProfileCustomContent
                                                    avatar={avatar}
                                                    onEditProfile={() => {
                                                        setOpenProfile(false);
                                                        navigate(`/Profile`);
                                                    }}
                                                />
                                            }
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </IconSettings>
    );
};

export default PsTopMenuBar;
