import { Input } from "@salesforce/design-system-react";
import React, { useEffect, useRef } from "react";

import CustomPopover from "../../../ui/custom-popover/CustomPopover";
import { Bucket } from "./types";

type BucketPopoverPropsType = {
    selectedBucket: Bucket;
    saveBucket: (selectedBucketLabel: string, selectedBucketValue: string) => void;
    setSelectedBucket: React.Dispatch<React.SetStateAction<Bucket>>;
    setShowNewBucketPopover: React.Dispatch<React.SetStateAction<boolean>>;
};

const BucketPopover: React.FC<BucketPopoverPropsType> = ({ saveBucket, selectedBucket = { value: "", label: "" }, setSelectedBucket, setShowNewBucketPopover }) => {
    const popoverRef = useRef({});

    const onClickOutside = () => {
        setSelectedBucket((prev) => {
            const selectedBucketLabel = prev.label;
            const selectedBucketValue = prev.value;
            saveBucket(selectedBucketLabel, selectedBucketValue);
            return prev;
        });
        setShowNewBucketPopover(false);
    };

    const popoverContent = (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                saveBucket(selectedBucket.label, selectedBucket.value);
            }}
        >
            <Input
                value={selectedBucket?.label || ""}
                placeholder="Enter a bucket name"
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSelectedBucket((prev: Bucket) => ({ ...prev, label: e.target.value }))}
            />
        </form>
    );

    const onCancel = () => {
        setSelectedBucket({ value: "", label: "" });
        setShowNewBucketPopover(false);
    };

    return (
        <CustomPopover
            popoverRef={popoverRef}
            content={popoverContent}
            onSave={() => saveBucket(selectedBucket.label, selectedBucket.value)}
            onCancel={() => onCancel()}
            onClickOutside={() => onClickOutside()}
        />
    );
};

export default BucketPopover;
