const Toggle = ({ label, active, setActive, showStatus = false, ...inputCheckboxProps }) => {
    return (
        <div>
            <span className="slds-form-element__label slds-m-bottom_none">{label}</span>
            <label className="slds-checkbox_toggle slds-grid">
                <input {...inputCheckboxProps} type="checkbox" checked={active} onChange={() => {}} onClick={() => setActive()} />
                <span id="checkbox-toggle-16" className="slds-checkbox_faux_container">
                    <span className="slds-checkbox_faux"></span>
                    {showStatus && (
                        <>
                            <span className="slds-checkbox_on">Yes</span>
                            <span className="slds-checkbox_off">No</span>
                        </>
                    )}
                </span>
            </label>
        </div>
    );
};

export default Toggle;
