export const RECORD_COLUMNS = [
    {
        label: "Name",
        type: "link",
        property: "name",
        key: "name",
        sortable: true,
        title: "View Details",
        action: "details",
        minWidth: 200,
    },
    {
        label: "Source Identifier",
        width: 200,
        property: "sourceIdentifier",
        key: "sourceIdentifier",
        type: "text",
    },
    {
        label: "Status",
        width: 120,
        property: "status",
        key: "status",
        type: "text",
        sortable: true,
    },
    {
        label: "# Records Loaded",
        width: 170,
        property: "totalRecords",
        key: "totalRecords",
        type: "number",
        sortable: true,
    },
    {
        label: "Found in Source",
        width: 140,
        property: "sourceExists",
        key: "sourceExists",
        type: "boolean",
    },
    {
        label: "Last Run",
        width: 180,
        property: "lastRunOn",
        key: "lastRunOn",
        type: "formattedDate",
        sortable: true,
    },
    {
        label: "Restrictions",
        width: 140,
        property: "restrictions",
        key: "restrictions",
        type: "text",
    },
    //IMPROVEMENT:  set 'Include' and 'Exclude' action dynamically, based on the object's status
];
