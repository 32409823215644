import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import { GENERAL_ERROR } from "../../../constants/ToastMessages";
import "../../Pages.css";
import RegionSelection from "../../../components/region-selection";
import useAuthContext from "../../../context/useAuthContext";
import useToastContext from "../../../context/useToastContext";

const LoginForm = ({ setMode, setNewUser }) => {
    const { userHasAuthenticated, user, setUser } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const { username, password, region } = user;

    const navigate = useNavigate();

    const { addToast } = useToastContext();

    const handleUserInputChange = (e, item) => {
        setUser((prevUser) => ({ ...prevUser, [item]: e.target.value }));
    };

    const handleSubmitLoginForm = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true);

            var user = await Auth.signIn(username, password);

            if (user) {
                if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    setNewUser(user);
                    setMode("newpassword");
                    return;
                }
                setUser((prev) => ({
                    ...prev,
                    ...user,
                    username: user.username,
                    email: user.attributes.email,
                    id: user.attributes.sub,
                }));

                // set region in cookies
                Cookies.set("region", region, { expires: 90 });

                // CHECK: this is not done in ConfirmationForm? is that not setting email?
                userHasAuthenticated(true); //also used in ConfirmationForm
                navigate("/", { replace: true });
                return;
            }
            addToast("error", "Error", GENERAL_ERROR);
        } catch (err) {
            if (err.code === "PasswordResetRequiredException") {
                setMode("resetpassword");
            }
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmitLoginForm}>
            <p className="form-title"> Sign in with your username and password</p>
            <RegionSelection />
            <div className="user-inputs">
                <Input type="text" label="Username" value={username} onChange={(e) => handleUserInputChange(e, "username")} autoComplete="username" />
            </div>
            <div className="user-inputs">
                <Input type="password" label="Password" value={password} onChange={(e) => handleUserInputChange(e, "password")} autoComplete="current-password" />
                <p className="login-forgot-password">
                    <Link to="/forgotPassword"> Forgot your password? </Link>
                </p>
            </div>
            <div className="login-signup-button">
                <Button
                    type="submit"
                    label={<>Sign in {isLoading ? <Spinner size="small" /> : null}</>}
                    variant="brand"
                    disabled={isLoading || !username || !password || !region}
                    style={{ width: "100%" }}
                />
            </div>
            <nav className="login-signup-link">
                <p>
                    Need an account? <Link to="/signup">Sign up</Link>
                </p>
            </nav>
        </form>
    );
};

export default LoginForm;
