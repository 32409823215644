import Users from "./users/Users";
import { PAGE_ROUTES } from "../../constants";
import Account from "./account/Account";
import Setup from "./setup/Setup";

// Return a Array of Routes so we can use deferent components if it needs
export const setupRoutes = [
    {
        path: PAGE_ROUTES.SETUP.DEFAULT.path,
        element: <Setup />,
    },
    {
        path: PAGE_ROUTES.SETUP.USERS.path,
        element: <Users />,
    },
    {
        path: PAGE_ROUTES.SETUP.ACCOUNT.path,
        element: <Account />,
    },
    // add more routes here
];
