//This replaces the PatternFolderGrid
import { useState, useEffect } from "react";
import { Input } from "@salesforce/design-system-react";

import Record from "../../helpers/recordLayer";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { toastErrorMessage } from "../../helpers/index.js";
import Modal from "../../ui/Modal";
import { RECORD_COLUMNS } from "./constants";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart.js";
import useToastContext from "../../context/useToastContext";

const PsDashboardGrid = ({ parentId, queryFilter, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("grid");
    const [orderBy, setOrderBy] = useState("createdOn");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToastContext();

    const [folder, setFolder] = useState(null);
    const [original, setOriginal] = useState(null);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

    const handleRecordRowAction = (action, row) => {
        switch (action) {
            case "details":
                childToParent({ type: "dataCompEvent", action: "viewDetails", pattern: row });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        getFolder(queryFilter?.folderId);
    }, [queryFilter?.folderId]);

    const getFolder = (folderId) => {
        var onSuccess = function (response) {
            const record = parseFolder(response)[0];
            setFolder(record);
            setOriginal(JSON.parse(JSON.stringify(record)));
            setLoading(false);
        };

        var onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
        };

        setFolder(null); // set to null immediately, so we don't render any records from the new Folder with the previous Folder's layout
        setLoading(true);
        Record.getRecord("relate", "folder", folderId, {}, "", "GET", onSuccess, onError);
    };

    const parseFolder = (response) => {
        return response.map(({ id, name, type, description, grid }) => ({
            id,
            name,
            type,
            description,
            grid,
        }));
    };

    const handleCancelEdit = () => {
        setFolder(JSON.parse(JSON.stringify(original)));
    };

    const handleSaveEdit = (layout) => {
        const deleteIds = layout.filter((item) => item.static).map((item) => item.i);
        const newLayout = layout.filter((item) => !item.static);
        const newFolder = { ...folder, grid: { layout: newLayout } };
        const folderId = queryFilter?.folderId;
        let deleteIndex = 0;

        setFolder(newFolder); // set this immediately to prevent reverting back to the old layout, and flickering back to the new layout after saving
        setOriginal(JSON.parse(JSON.stringify(newFolder)));

        var onFolderSuccess = function (response) {
            setLoading(false);
        };

        var onFolderError = function (response) {
            addToast("error", "Error saving Folder", toastErrorMessage(response));
            setLoading(false);
        };

        var onDeleteSuccess = function (response) {
            deleteNext();
        };

        var onDeleteError = function (response) {
            addToast("error", "Unable to delete Pattern", toastErrorMessage(response));
            setLoading(false);
        };

        const deleteNext = function () {
            if (deleteIndex < deleteIds.length) {
                deleteIndex++; // watch out; this prevents endlessly calling the delete API!
                Record.deleteRecord("relate", "pattern", deleteIds[deleteIndex - 1], onDeleteSuccess, onDeleteError);
            } else {
                Record.updateRecord("relate", "folder", JSON.stringify(parseInputPlainText(newFolder)), folderId, onFolderSuccess, onFolderError);
            }
        };

        setLoading(true);
        deleteNext();
    };

    const parseInputPlainText = (record) => {
        var data = record.id
            ? (({ id, name, description, grid }) => ({
                  id,
                  name,
                  description,
                  grid,
              }))(record) // select fields that can be updated
            : (({ name, description, grid }) => ({
                  name,
                  description,
                  grid,
              }))(record); // select fields that can be created
        return data;
    };

    const deleteFolder = () => {
        const folderId = queryFilter?.folderId;

        var onSuccess = function (response) {
            addToast("success", "Folder Deleted", "Folder was deleted successfully");
            childToParent({ type: "record", action: "delete", parentId, id: folderId, record: {}, module: "relate", obj: "folder" });
            setLoading(false);
        };

        var onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
        };

        if ((folder?.name || "").toLowerCase() === "liked") {
            addToast("error", "Cannot delete Folder", 'Cannot delete the "Liked" Folder.');
        } else {
            setLoading(true);
            Record.deleteRecord("relate", "folder", folderId, onSuccess, onError);
        }
    };

    const parseResponse = (response) => {
        let records = response.map(({ id, compositionId, name, type, description, treeHash, relevance, createdOn, lastRunOn, container, overridden }) => ({
            id,
            compositionId,
            name,
            type,
            namePlain: Record.removeMarkup(name),
            nameMarkup: Record.markupToHtml(name),
            treeHash,
            relevance,
            createdOn,
            lastRunOn,
            container,
            containerName: container?.name,
            sourceName: container?.source?.name,
            title: container?.source?.name + ": " + container?.name,
            overridden,
        }));

        return records.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn)); // TODO: remove when createdOn is supported in the API
    };

    const handleDelete = () => {
        setShowDeleteConfirmDialog(true);
    };

    const handleConfirmDialogDelete = () => {
        deleteFolder();
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDialogCancel = () => {
        setShowDeleteConfirmDialog(false);
    };

    const header = () => {
        return (
            <>
                {mode === "edit" ? (
                    <div className="slds-grid slds-m-around_medium slds-wrap">
                        <div className="slds-col slds-size_1-of-1">
                            <Input
                                type="text"
                                label="Folder Description"
                                autoComplete="off"
                                value={folder?.description || ""}
                                onChange={(e) => setFolder((prev) => ({ ...prev, description: e.target.value }))}
                            />
                        </div>
                        {view === "grid" && (
                            <div className="slds-col slds-size_1-of-1">
                                <br />
                                Drag tiles to the desired location and reshape tiles from the right bottom corner.
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="slds-m-around_medium">{folder?.description}</div>
                )}
            </>
        );
    };

    const footer = () => {
        return (
            <>
                {showDeleteConfirmDialog ? (
                    <Modal
                        apply={handleConfirmDialogDelete}
                        cancel={handleConfirmDialogCancel}
                        header="Confirmation"
                        modalContent="Are you sure you want to delete this Folder?"
                        applyButtonContent="Delete"
                    />
                ) : null}
            </>
        );
    };

    return (
        <PsRecordGrid
            module="relate"
            object="pattern"
            queryFilter={queryFilter}
            layout={folder ? folder?.grid?.layout || [] : null}
            gridComponent={PsPatternChart}
            showTitle={true}
            title={folder ? folder.name || "--Empty--" : " "}
            header={header}
            emptyLine="This Folder is empty"
            viewOptions={[
                { label: "Table", value: "table" },
                { label: "Grid", value: "grid" },
            ]}
            useLayout={true}
            changeView={true}
            recordLabel="Pattern"
            recordLabelPlural="Patterns"
            parseResponse={parseResponse}
            parentId={parentId}
            footer={footer}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            //showLoadMore={true} // doesn't make sense if allowing edit mode
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            showEdit={true}
            onSave={handleSaveEdit}
            onCancel={handleCancelEdit}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100} // TODO: build queing mechanism to prevent loading many many plots, e.g., when refreshing or large dashboards
            onRecordRowAction={handleRecordRowAction}
            showDelete={true}
            onDeleteClick={handleDelete}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
        />
    );
};

export default PsDashboardGrid;
