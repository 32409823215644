import { Checkbox, Combobox } from "@salesforce/design-system-react";
import { useEffect, useMemo, useState } from "react";

import "./InputSlotModal.css";
import { flattenWithIndex, getChainDetails, getKeyOptions } from "./helpers";

const KeyChainSelect = ({ index, inputSlot, onChange, setLoading, title, onChangeAllChains }) => {
    const [allSlotsKeysChains, setAllSlotsKeyChains] = useState([]);
    const [keysChainsPair, setKeyChainsPair] = useState([]);

    // Use useMemo to only recompute when inputSlot changes
    // Update slot with pathIndex using flattenWithIndex (for more ready flattenWithIndex comment)
    const { updateSlot, flattened } = useMemo(() => {
        const deepCopiedSlot = JSON.parse(JSON.stringify(inputSlot)); // Deep copy
        const flattenedInput = flattenWithIndex(deepCopiedSlot, "inputs");
        return { updateSlot: deepCopiedSlot, flattened: flattenedInput };
    }, [inputSlot]);

    useEffect(() => {
        // useEffect to update editableKeys and editableChains whenever the slot is changed
        const findEditableKeys = allSlotsKeysChains.find((d) => d.floatingId === inputSlot.floatingId);
        if (allSlotsKeysChains.length && !!findEditableKeys) {
            setKeyChainsPair(findEditableKeys.keysChains);
            setLoading(false);
            return;
        }
        const fetchOptions = async () => {
            setLoading(true);
            let keyOptions = [];
            let chainOptions = [];
            for (let index = 0; index < flattened.length; index++) {
                const item = flattened[index];

                if (item.type === "Load") {
                    try {
                        const options = await getKeyOptions(item);
                        keyOptions.push({
                            pathIndex: index, // Replace 'pathIndex' with the index
                            options: options || [],
                        });
                    } catch (error) {
                        console.error("Error fetching key options:", error);
                    }
                }

                if (item.type === "Join" && item?.chain?.id) {
                    try {
                        const options = await getChainDetails(item.chain.id);
                        chainOptions.push({
                            pathIndex: index, // Replace 'pathIndex' with the index
                            options: options || [],
                        });
                    } catch (error) {
                        console.error("Error fetching chain options:", error);
                    }
                }
            }
            let keysChains = [];
            keyOptions.forEach((key) => {
                const findChainPair = chainOptions.find((chain) => chain.pathIndex + 1 === key.pathIndex);
                keysChains.push({
                    key,
                    chain: findChainPair || null,
                    changeChainForAll: false,
                });
            });

            setKeyChainsPair(keysChains);

            // Cache editableKeys and editableChains for each input slot using the floatingId to avoid repeated requests on slot change
            const copySlotsKeys = [...allSlotsKeysChains];
            copySlotsKeys.push({
                floatingId: inputSlot.floatingId,
                keysChains,
            });
            setAllSlotsKeyChains(copySlotsKeys);
            setLoading(false);
        };
        fetchOptions();
    }, [index]);

    function onSelectKey(keyId, options, pathIndex) {
        const findKey = options.find((key) => key.id === keyId);
        if (!!findKey) {
            flattened[pathIndex].key = findKey;
            onChange(updateSlot, index);
        }
    }
    function onSelectChain(chainId, options, pathIndex, changeChainForAll) {
        const findChain = options.find((chain) => chain.id === chainId);
        if (!!findChain) {
            if (changeChainForAll) {
                const oldChainId = flattened[pathIndex]?.chain?.id;
                onChangeAllChains(oldChainId, findChain, pathIndex);
                return;
            }
            flattened[pathIndex].chain = findChain;
            onChange(updateSlot, index);
        }
    }
    function onChangeCheckbox(checked, index) {
        const copyKeysChainsPair = [...keysChainsPair];
        copyKeysChainsPair[index] = { ...copyKeysChainsPair[index], changeChainForAll: checked };
        setKeyChainsPair(copyKeysChainsPair);
    }

    return (
        <>
            {keysChainsPair?.map(({ key, chain, changeChainForAll }, i) => {
                const keyValue = flattened[key?.pathIndex]?.key;
                const keySelection = keyValue && keyValue?.id ? [{ id: keyValue.id, label: keyValue.name }] : undefined;
                const chainValue = flattened[chain?.pathIndex]?.chain;
                const chainSelection = chainValue && chainValue?.id ? [{ id: chainValue.id, label: chainValue.name }] : undefined;

                return (
                    <div key={i}>
                        <div className="pair-key-chain">
                            <Combobox
                                key={i}
                                options={key.options.map((opt) => ({
                                    id: opt.id,
                                    label: opt.name,
                                }))}
                                labels={{
                                    label: "Select a Field of the same Data Type from the same Object:",
                                }}
                                events={{
                                    onSelect: (_e, v) => onSelectKey(v.selection[0]?.id, key.options, key.pathIndex),
                                }}
                                selection={keySelection}
                                menuPosition="relative"
                                menuItemVisibleLength={10}
                                variant="readonly"
                            />
                            {!!chain && (
                                <Combobox
                                    options={chain.options.map((opt) => ({
                                        id: opt.id,
                                        label: opt.name,
                                    }))}
                                    labels={{
                                        label: "Select a Path to connect to the Pattern:",
                                    }}
                                    events={{
                                        onSelect: (_e, v) => onSelectChain(v.selection[0]?.id, chain.options, chain.pathIndex, changeChainForAll),
                                    }}
                                    selection={chainSelection}
                                    menuPosition="relative"
                                    menuItemVisibleLength={10}
                                    variant="readonly"
                                />
                            )}
                        </div>
                        {!!chain && (
                            <div className="pair-key-chain">
                                <div className="pair-label"> </div>
                                <Checkbox checked={changeChainForAll} onChange={(e) => onChangeCheckbox(e.target.checked, i)} labels={{ label: "Change all paths" }} />
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default KeyChainSelect;
