import RecordConstants from "../../../constants/RecordConstants";

export const getTitle = (breadcrumb) => {
    var type = RecordConstants.TERMINOLOGY["dataType"] || "Unknown";
    breadcrumb = breadcrumb.map((item) => item.name).filter((item) => item != null);
    var title = breadcrumb.length ? type + ": " + breadcrumb.join("->") : "loading...";
    return title;
};

export const getBreadCrumb = (object, record) => {
    if (object === "dataType") {
        var dataRole = record.dataRole || {};
        return [
            { id: dataRole.id, name: dataRole.name },
            { id: record.id, name: record.name },
        ];
    }
    return [];
};
