import { Input } from "@salesforce/design-system-react";
import { useEffect, useRef, useState } from "react";
import Combobox from "@salesforce/design-system-react/components/combobox";

import PsRecord from "../ps-record/PsRecord";
import EditButtonIcon from "../../ui/EditButtonIcon";
import ProgressBarComponent from "../../ui/ProgressBarComponent";
import Modal from "../../ui/Modal";
import RecordConstants from "../../constants/RecordConstants";

const PsSource = (props) => {
    const [cmpState, setCmpState] = useState({
        recordLabel: "Source",
        recordModule: "core",
        recordObject: "source",
        loading: false,
        mode: "init",
        record: {},
        showEdit: true,
        showDelete: true,
        showDeleteConfirmDialog: false,
        errorTextcheckFields: ["name"],
        missingRequiredFields: [],
        statusOptions: Object.values(RecordConstants.ENVIRONMENT_STATUS),
        restrictOptions: Object.values(RecordConstants.SOURCE_RESTRICT),
    });

    const cmpWorking = useRef({});

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        cmp.init();
    }, []);

    useEffect(() => {
        if (!props.parentToChildEvent) {
            return;
        }
        cmp.handleEvent(props.parentToChildEvent);
    }, [props.parentToChildEvent]);

    useEffect(() => {
        cmp.set("recordId", props.recordId);
        cmp.handleReload();
    }, [props.recordId]);

    const cmp = {
        init: function () {},

        handleReload: function () {
            PsRecord.getRecord(cmp);
        },

        handleEdit: function () {
            PsRecord.setMode(cmp, "edit");
        },

        handleCancel: function () {
            PsRecord.cancelRecord(cmp);
        },

        handleSubmit: function (cmp) {
            PsRecord.submitRecord(cmp);
        },

        handleDelete: function (cmp) {
            cmp.set("showDeleteConfirmDialog", true);
        },

        // --- SourceHelper.js ---

        // This overrides the default PsRecord function
        parseInputPlainText: function (record) {
            return record.id
                ? (({ id, name, status }) => ({ id, name, status }))(record) // select fields that can be updated
                : (({ name, status, restrict }) => ({ name, status, restrict }))(record); // select fields that can be created
        },

        getDefaultRecord: function (cmp) {
            return {
                name: "",
                status: RecordConstants.ENVIRONMENT_STATUS.ACTIVE.value,
                restrict: "",
            };
        },

        parseResponse: function (response) {
            return response.map(({ id, name, status, restrict }) => ({
                id,
                name,
                status,
                restrict,
            }));
        },

        setParent: function (record) {
            var parentId = "source";
            cmp.set("parentId", parentId);
            return parentId;
        },

        handleNameChange: function (value) {
            let record = cmp.get("record");
            record = { ...record, name: value };
            cmp.set("record", record);
            if (value) {
                cmp.set("missingRequiredFields", []);
            }
        },

        handleSelectStatus: function (event, data) {
            if (data.selection.length === 0) {
                return;
            }

            let record = cmp.get("record");
            record = { ...record, status: data.selection[0].value };
            cmp.set("record", record);
        },

        handleSelectRestrict: function (event, data) {
            if (data.selection.length === 0) {
                return;
            }

            let record = cmp.get("record");
            record = {
                ...record,
                restrict: data.selection[0].value === "--None--" ? "" : data.selection[0].value,
            };
            cmp.set("record", record);
        },

        dispatchEvent: function (event) {
            props.childToParent(event);
        },

        bubbleEvent: function (event) {
            let stopPropagation = false;
            if (!stopPropagation) {
                props.childToParent(event);
            }
        },

        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        checkForm: function () {
            try {
                const record = cmp.get("record");

                if (!record.name) {
                    cmp.set("missingRequiredFields", ["record.name"]);
                }
                return !record.name;
            } catch (err) {
                console.error(err);
            }
        },

        handleEvent: (event) => {
            if (event.type === "reload") {
                cmp.handleReload();
                //props.parentCmp.set("parentToChildEvent", {});
            }
        },

        body: function () {
            // Combobox gives warning without id
            const statusOptionsWithId =
                cmpState.statusOptions && cmpState.statusOptions.length > 0
                    ? cmpState.statusOptions.map((item) => ({
                          ...item,
                          id: item.label,
                      }))
                    : cmpState.statusOptions;

            const restrictOptionsWithId =
                cmpState.restrictOptions && cmpState.restrictOptions.length > 0
                    ? cmpState.restrictOptions.map((item) => ({
                          ...item,
                          id: item.label,
                      }))
                    : cmpState.restrictOptions;

            return (
                <div className="slds-form slds-m-around_medium" role="list">
                    <h3 className="slds-section-title--divider slds-m-top_medium">Source Details</h3>

                    <div className="slds-form__row">
                        {/* <!-- Name --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {cmpState.mode === "view" && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Name</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.name}</div>
                                            <div className="slds-button slds-button__icon slds-button__icon_hint">
                                                <EditButtonIcon handleEdit={cmp.handleEdit} />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                                    <Input
                                        id="checkField"
                                        name="name"
                                        autoComplete="off"
                                        label="Name"
                                        required={true}
                                        value={cmpState.record?.name}
                                        onChange={(e) => cmp.handleNameChange(e.target.value)}
                                        errorText={cmp.get("missingRequiredFields").includes("record.name") ? "Complete this field." : ""}
                                    />
                                )}
                            </div>
                        </div>

                        {/* <!-- Status --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {cmpState.mode === "view" && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Status</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.status}</div>
                                            <div className="slds-button slds-button__icon slds-button__icon_hint">
                                                {/* <lightning:buttonIcon aura:id="EditRecord" iconName="utility:edit" variant="bare" alternativeText="Edit" onclick="{!c.handleEdit}" /> */}
                                                <EditButtonIcon handleEdit={cmp.handleEdit} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                                    <Combobox
                                        events={{
                                            onSelect: (event, data) => cmp.handleSelectStatus(event, data),
                                        }}
                                        labels={{
                                            label: "Status",
                                            placeholder: "--Please Select--",
                                        }}
                                        menuPosition="relative"
                                        options={statusOptionsWithId}
                                        selection={[statusOptionsWithId.find((option) => option.value === cmpState.record?.status)]}
                                        value={cmpState.record?.status}
                                        variant="readonly"
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="slds-form__row">
                        {/* <!-- Restrict --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {(cmpState.mode === "view" || cmpState.mode === "edit") && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Restrict</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.restrict === "--None--" ? "" : cmpState.record?.restrict}</div>
                                        </div>
                                    </div>
                                )}
                                {cmpState.mode === "new" && (
                                    <Combobox
                                        menuItemVisibleLength={10}
                                        events={{
                                            onSelect: (event, data) => cmp.handleSelectRestrict(event, data),
                                        }}
                                        labels={{
                                            label: "Restrict",
                                            placeholder: "--Please Select--",
                                        }}
                                        menuPosition="relative"
                                        options={restrictOptionsWithId}
                                        selection={cmpState.record?.restrict === "" ? [restrictOptionsWithId[0]] : [restrictOptionsWithId.find((item) => item.value === cmpState.record?.restrict)]}
                                        value={cmpState.record?.restrict}
                                        variant="readonly"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    };

    return (
        <>
            {cmpState.showDeleteConfirmDialog ? (
                <Modal
                    apply={() => PsRecord.confirmDeleteRecord(cmp)}
                    cancel={() => PsRecord.cancelDeleteRecord(cmp)}
                    header="Confirmation"
                    modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
                    applyButtonContent="Delete"
                />
            ) : null}
            {PsRecord.render(cmp, cmpState)}
        </>
    );
};

export default PsSource;
