export const RECORD_COLUMNS = [
    {
        label: "Name",
        property: "name",
        key: "name",
        type: "text",
        wrapText: false,
        minWidth: 200,
    },
    {
        label: "Order",
        width: 80,
        property: "argOrder",
        key: "argOrder",
        type: "number",
    },
    {
        label: "Source",
        type: "link",
        width: 200,
        property: "sourceName",
        key: "sourceName",
        action: "viewSource",
        href: "/StoreManagement?selected=links_source_${sourceId}", // eslint-disable-line no-template-curly-in-string
    },
    {
        label: "Object",
        type: "link",
        width: 200,
        property: "containerName",
        key: "containerName",
        action: "viewContainer",
        href: "/StoreManagement?selected=links_container_${containerId}", // eslint-disable-line no-template-curly-in-string
    },
    {
        label: "Field",
        type: "link",
        href: "/StoreManagement?selected=keys_key_${id}", // eslint-disable-line no-template-curly-in-string
        width: 200,
        property: "keyName",
        key: "keyName",
        action: "viewKey",
    },
    {
        label: "# Distinct",
        width: 170,
        property: "robustDistinct",
        key: "robustDistinct",
        type: "number",
    },
];
