import { Button, Combobox, Input, Tooltip } from "@salesforce/design-system-react";
import React, { useRef, useState, forwardRef, useEffect, useImperativeHandle } from "react";

import RecordConstants from "../../constants/RecordConstants";
import PsRecord2 from "../ps-record/PsRecord2";
import { Mode, PsRecordPropsType, FilterType, FilterOption, SelectedContainerType, ParseInputPlainTextFilterType } from "../../types";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Field from "../../ui/wrappers/Field";
import Toggle from "../../ui/Toggle";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import Record from "../../helpers/recordLayer";
import { getRecordAPI, getRecordsAPI } from "../../services/api";
import { getTitle, getUseInput, getBreadCrumb, filterJson } from "./components/utils";
import DualListBox from "../../ui/DualListBox";
import { convertDateTimeFormat, getSafeInputValue, selectLoadedOption, checkRequiredField } from "../../utils/index2";
import FormItemWrapper from "../../ui/wrappers/FormItemWrapper";
import { FIELD_ERROR_MESSAGES } from "../../constants";

const recordObject = "filter";

export type DataType = {
    format: string;
    id: string;
    name: string;
    role: string;
};

export type KeyType = {
    id: string;
    name: string;
    title: string;
    dataType: DataType;
};

interface PsFilterRef {
    setFieldErrors: (field: string, value: string) => void;
    cardBody: React.ReactNode;
    record?: FilterType;
    fieldErrors: { [key: string]: string };
}

const PsFilter2 = forwardRef<PsFilterRef, PsRecordPropsType>((props, ref) => {
    const { recordId, parentId, childToParent, parentToChildEvent, options } = props;
    const { filter } = options || {};
    const { newScopes, hideAppliesTo, filterSetState, recordValue, isModal, selectedFieldRecord, selectedFilterBuild } = filter || {};
    const scopes = newScopes ? [{ id: newScopes[0], value: newScopes[0], label: newScopes[0], selected: true }] : [];

    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<FilterType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [useInput, setUseInput] = useState<string>("");
    const [valuesOptions, setValuesOptions] = useState<FilterOption[]>([]);
    const [operator, setOperator] = useState<string>("");
    const [operatorLabel, setOperatorLabel] = useState<string>("");
    const [operatorOptions, setOperatorOptions] = useState<FilterOption[]>([]);
    const [operatorSelection, setOperatorSelection] = useState<FilterOption[]>([{ id: "Not Available", value: "Not Available", label: "Not Available", selected: true }]);
    const [containerFilter, setContainerFilter] = useState(null);
    const [selectedContainer, setSelectedContainer] = useState<SelectedContainerType | FilterType>();
    const [containerItem, setContainerItem] = useState(null);
    const [selectedKey, setSelectedKey] = useState<FilterType>(null);
    const [keyItem, setKeyItem] = useState(null);
    const [chain, setChain] = useState({
        chainFilter: null,
        selectedChain: null,
        chainItem: null,
        needsChain: false,
    });
    const [selectedPreset, setSelectedPreset] = useState<string>("");
    const [presetOptions, setPresetOptions] = useState<FilterOption[]>([]);
    const [presetSelection, setPresetSelection] = useState<FilterOption[]>([]);
    const [selectedPresetLabel, setSelectedPresetLabel] = useState<string>("");
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [freeTextValues, setFreeTextValues] = useState<string>("");
    const [minNumber, setMinNumber] = useState<string | number>();
    const [maxNumber, setMaxNumber] = useState<string | number>();
    const [minDate, setMinDate] = useState<string>("");
    const [maxDate, setMaxDate] = useState<string>("");
    const [minDateAmount, setMinDateAmount] = useState<string | number>("");
    const [minDateUnit, setMinDateUnit] = useState<string | number>("");
    const [minDateDefault, setMinDateDefault] = useState<string>("");
    const [minNumberDefault, setMinNumberDefault] = useState<number>();
    const [maxNumberDefault, setMaxNumberDefault] = useState<number>();
    const [maxDateDefault, setMaxDateDefault] = useState<string>("");
    const [maxDateAmount, setMaxDateAmount] = useState<string>("");
    const [maxDateUnit, setMaxDateUnit] = useState<string>("");
    const [minDateUnitLabel, setMinDateUnitLabel] = useState<string>("");
    const [maxDateUnitLabel, setMaxDateUnitLabel] = useState<string>("");
    const [dateTimeUnits, setDateTimeUnits] = useState(RecordConstants.DATETIME_UNITS || []);
    const [minDateTimeUnitsSelection, setMinDateTimeUnitsSelection] = useState([]);
    const [maxDateTimeUnitsSelection, setMaxDateTimeUnitsSelection] = useState([]);
    const [scopeSelection, setScopeSelection] = useState<FilterOption[]>(scopes);
    const [scopeOptions, setScopeOptions] = useState<FilterOption[]>(scopes);
    const [scope, setScope] = useState<string>(newScopes[0]);
    const [cmpState, setCmpState] = useState({
        activeField: "",
        isExpanded: false,
    });
    const searchFieldRef = useRef<HTMLInputElement | null>(null);
    const keyFieldRef = useRef<HTMLInputElement | null>(null);
    const pathFieldRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        handleReload();
    }, [recordId]);

    useEffect(() => {
        loadContainer();
    }, [parentId]);

    // This useEffect is used to update the filter for the build page only
    useEffect(() => {
        if (!!selectedFilterBuild) {
            setIsSaved(true); // On the build page, "isSaved" should always be true
            return;
        }
        if (!!selectedFieldRecord) {
            setIsSaved(true);
            // Updates the "Field" input by adding the record selected from the tree menu (outside of the filters)
            handleKeySelect(selectedFieldRecord);
        }
    }, [selectedFieldRecord, selectedFilterBuild]);

    // TODO: this will move to the PsNavigationInput comp
    useEffect(() => {
        // get DOM element
        const listDivElement = document.getElementById("listDiv");
        if (!listDivElement) return;

        const handleClick = (e: MouseEvent) => {
            const target = e.target as Node;
            if (
                (searchFieldRef.current && searchFieldRef.current.contains(target)) ||
                (keyFieldRef.current && keyFieldRef.current.contains(target)) ||
                (pathFieldRef.current && pathFieldRef.current.contains(target))
            ) {
                return;
            }
            setCmpState((prev) => ({ ...prev, activeField: "", isExpanded: false }));
        };

        // add Event listener
        listDivElement.addEventListener("click", handleClick);

        // Cleanup event listener
        return () => {
            listDivElement.removeEventListener("click", handleClick);
        };
    }, []);

    function loadContainer() {
        const containerId = parentId;
        if (containerId) {
            const onSuccess = function (response: any) {
                let record = response[0];
                record.title = getTitle(getBreadCrumb("container", record));
                setSelectedContainer(record);
                setContainerItem(Record.nameFromDetails("containers", "container", record.id));
                setContainerFilter({ containers: { container: { id: record.id } } });
                setContainer(record);
            };
            const onError = function (response: any) {
                unloadContainer();
            };
            getRecordAPI({ module: "store", object: "container", recordId: containerId }, onSuccess, onError);
        } else {
            unloadContainer();
        }
    }

    function handleReload() {
        const updatedIsInit = true;
        setIsInit(updatedIsInit);

        if (!recordId) {
            loadContainer();

            // set default record for a new filter
            const defaultRecord = {
                name: "",
                scope: newScopes[0],
                active: true,
                acceptMissing: false,
            };

            // this recordValue is coming from Filter modal on PsPatternDetailedView comp
            const record = recordValue ? recordValue : defaultRecord;
            setRecord({ ...record });
            updateUI(record, updatedIsInit);
        }
    }

    function unloadContainer() {
        setLoading(false);
        setChainFilter("leftContainerId", null);
        setSelectedContainer(null);
        setContainerItem(null);
        setContainerFilter(null);
    }

    // This overrides the default PsRecord function
    const parseInputPlainText = (filter: FilterType): ParseInputPlainTextFilterType => {
        let updatedFilter = filterJson(
            filter,
            operatorOptions,
            operator,
            valuesOptions,
            minDate,
            maxDate,
            selectedPreset,
            presetOptions,
            selectedValues,
            minDateAmount,
            maxDateAmount,
            minDateUnit,
            maxDateUnit,
            minNumber,
            maxNumber,
            scope,
            selectedKey,
            selectedContainer,
            chain.selectedChain,
            freeTextValues
        );
        updatedFilter = JSON.parse(JSON.stringify(updatedFilter)); // deepcopy to prevent changing original record

        if (updatedFilter.id) {
            // Fields for existing records
            const { id, active, name, acceptMissing, operator, settings } = updatedFilter;
            return { id, active, name, acceptMissing, operator, settings };
        } else {
            // Fields for new records
            const { active, type, name, scope, acceptMissing, containerId, operator, settings, inputs } = updatedFilter;
            return { active, type, name, scope, acceptMissing, containerId, operator, settings, inputs };
        }
    };

    const handleSelectScope = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) return;
        setRecord((prev) => ({ ...prev, status: data.selection[0].value }));
        setScopeSelection(data.selection);
    };

    const handleSelectPreset = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) return;
        setSelectedPreset(data.selection[0].value);
        setSelectedPresetLabel(data.selection[0].label);
        setPresetSelection(data.selection);

        if (fieldErrors?.preset) {
            setFieldErrors((prev) => ({ ...prev, preset: "" }));
        }
    };

    function handleMinDateAmount(value: string) {
        setMinDateAmount(value);
        setFieldErrors((prev) => ({ ...prev, minDateAmount: value ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD }));
    }

    function handleMaxDateAmount(value: string) {
        setMaxDateAmount(value);
        setFieldErrors((prev) => ({ ...prev, maxDateAmount: value ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD }));
    }

    function handleMinDateUnitChange(event: React.MouseEvent<HTMLDivElement>, data: any) {
        if (data.selection.length === 0) return;
        setMinDateUnitLabel(data.selection[0].label);
        setMinDateTimeUnitsSelection(data.selection);
        setMinDateUnit(data.selection[0].value);
        if (data.selection[0]?.value !== "--None--") {
            setFieldErrors((prev) => ({ ...prev, minDateUnit: "" }));
        }
    }

    function handleMaxDateUnitChange(event: React.MouseEvent<HTMLDivElement>, data: any) {
        if (data.selection.length === 0) return;
        setMaxDateUnitLabel(data.selection[0].label);
        setMaxDateTimeUnitsSelection(data.selection);
        setMaxDateUnit(data.selection[0].value);
        if (data.selection[0]?.value !== "--None--") {
            setFieldErrors((prev) => ({ ...prev, maxDateUnit: "" }));
        }
    }

    const unloadChains = () => {
        setChain((prevChain) => ({
            ...prevChain,
            selectedChain: null,
            chainItem: Object.assign(Record.nameFromDetails("chains", "chain", null), {
                label: "Not Available",
            }),
        }));
        setLoading(false);
    };

    const loadChains = () => {
        setLoading(true);

        const onSuccess = function (response: any) {
            if (!response || !response.length) {
                unloadChains();
            } else {
                let chain = response[0];
                chain.title = getTitle(getBreadCrumb("chain", chain));
                setChain((prevChain) => ({
                    ...prevChain,
                    selectedChain: chain,
                    chainItem: Record.nameFromDetails("chains", "chain", chain.id),
                }));
                setLoading(false);
            }
        };

        const onError = function () {
            unloadChains();
        };

        // unset selectedChain if it doesn't match the newly selected leftContainerId or rightContainerId
        const needsChain = chain.needsChain;
        if (needsChain) {
            const filter = chain.chainFilter?.chains?.chain || {};
            const selectedChain = chain.selectedChain || {};
            const leftContainerId = selectedChain.leftContainerId || selectedChain.leftContainer?.id;
            const rightContainerId = selectedChain.rightContainerId || selectedChain.rightContainer?.id;
            const changed = leftContainerId !== filter.leftContainerId || rightContainerId !== filter.rightContainerId;
            if (changed) {
                const queryFilter = Object.assign({}, filter, {
                    orderby: "relevance DESC",
                });
                getRecordsAPI({ module: "store", object: "chain", filters: queryFilter }, onSuccess, onError);
            } else {
                setLoading(false);
            }
        } else {
            unloadChains();
        }
    };

    const setChainFilter = (name: string, containerId: string) => {
        setChain((prevChain) => {
            let updatedChainFilter = prevChain.chainFilter || { chains: { chain: {} } };

            if (containerId) {
                updatedChainFilter.chains.chain[name] = containerId;
            } else {
                delete updatedChainFilter.chains.chain[name];
            }

            const leftContainerId = updatedChainFilter.chains.chain.leftContainerId;
            const rightContainerId = updatedChainFilter.chains.chain.rightContainerId;

            const needsChain = leftContainerId && rightContainerId && leftContainerId !== rightContainerId;

            if (!Object.keys(updatedChainFilter.chains.chain).length) {
                updatedChainFilter = null;
            }

            return { ...prevChain, chainFilter: updatedChainFilter, needsChain };
        });
    };

    const setContainer = (container: FilterType) => {
        setChainFilter("leftContainerId", container.id);

        // set selected key to the pattern.key's container, for ease of use
        const key = selectedKey || { containerId: "", container: { id: "" } };
        const selectedContainerId = key.containerId || key.container?.id;
        if (!selectedContainerId) {
            setKeyItem(Record.nameFromDetails("keys", "container", container.id));
        }
    };

    const setMinDateValues = (newMinDateAmount: string, newMinDateUnit: string) => {
        const { selectedValue, options, selectedItemLabel } = selectLoadedOption(dateTimeUnits, newMinDateUnit, false);
        setMinDateUnit(selectedValue);
        setMinDateTimeUnitsSelection([options.find((option) => option.value === selectedValue)]);
        setDateTimeUnits(options);
        setMinDateUnitLabel(selectedItemLabel);
        setMinDateAmount(newMinDateAmount);
    };

    const setMaxDateValues = (newMaxDateAmount: string, newMaxDateUnit: string) => {
        const { selectedValue, options, selectedItemLabel } = selectLoadedOption(dateTimeUnits, newMaxDateUnit, false);
        setMaxDateUnit(selectedValue);
        setMaxDateTimeUnitsSelection([options.find((option) => option.value === selectedValue)]);
        setDateTimeUnits(options);
        setMaxDateUnitLabel(selectedItemLabel);
        setMaxDateAmount(newMaxDateAmount);
    };

    const setScopeValues = (newScopeOptions: FilterOption[], newScopeValue: string) => {
        const { options, selectedValue } = selectLoadedOption(newScopeOptions, newScopeValue);
        setScopeOptions(options);
        setScope(selectedValue);
    };

    function unloadKeyDetails() {
        setRecord((prev) => ({ ...prev, type: null }));
        setOperatorOptions([{ id: "Not Available", value: "Not Available", label: "Not Available", selected: true }]);
        setOperator("Not Available");
        setOperatorLabel(null);
        setUseInput(null);
        setValuesOptions([{ id: "Not Available", value: "Not Available", label: "Not Available", selected: true }]);
        setSelectedValues(null);
        setFreeTextValues(null);
        setSelectedPreset(null);
        setSelectedPresetLabel(null);
        setPresetOptions(null);
        setMinDateUnit(null);
        setMaxDateUnit(null);
        setMinDateUnitLabel(null);
        setMaxDateUnitLabel(null);
        setChainFilter("rightContainerId", null);
        loadChains();
    }

    function setOperatorValue(
        operatorOptions: FilterOption[],
        operator: string,
        record: FilterType,
        minNumberDefault: number,
        maxNumberDefault: number,
        updatedIsInit: boolean,
        updatedValuesOptions: FilterOption[]
    ) {
        const { selectedValue, selectedItemLabel } = selectLoadedOption(operatorOptions, operator, !updatedIsInit);
        setOperatorLabel(selectedItemLabel);
        setOperator(selectedValue);
        setOperatorSelection([operatorOptions.find((option) => option.value === selectedValue)]);
        setUseInput(getUseInput(operatorOptions, selectedValue, updatedValuesOptions));

        const filter = record || { type: "", settings: {}, presets: [] as any[] };
        // const type = filter.type;
        type FilterType = keyof typeof RecordConstants.FILTER_PRESETS;
        const type = filter.type as FilterType;
        if (!updatedIsInit) {
            const presets = filter.presets;
            const presetDefaults = JSON.parse(JSON.stringify(RecordConstants.FILTER_PRESETS[type])); // deepclone to prevent changing original values
            setPresetOptions(presets || presetDefaults);
            if (type === "Text") {
                setFreeTextValues(null);
            } else if (type === "DateTime") {
                setMinDate(minDateDefault);
                setMaxDate(maxDateDefault);
            } else if (type === "Number") {
                setMinNumber(minNumberDefault);
                setMaxNumber(maxNumberDefault);
            }
        }
    }

    function loadKeyDetails(operator: string, selectedKey: FilterType, updatedIsInit: boolean) {
        // load existing record, or open new record
        const key = selectedKey;
        const isInit = updatedIsInit;

        if (key) {
            let type = "" as keyof typeof RecordConstants.FILTER_TYPES;
            let record = {} as FilterType;
            let updatedMinNumberDefault = minNumberDefault;
            let updatedMaxNumberDefault = maxNumberDefault;
            setLoading(true);

            const onSuccess = function (response: any[]) {
                record = response[0];
                type = record.dataType?.format as keyof typeof RecordConstants.FILTER_TYPES;

                record = { ...record, type };
                record.title = getTitle(getBreadCrumb("key", record));

                setRecord((prev) => ({ ...prev, type }));
                setSelectedKey({ ...record });
                const operatorOptions = RecordConstants.FILTER_TYPES[type];
                if (!operatorOptions) {
                    unloadKeyDetails();
                } else {
                    // set values before selecting operator, so it can switch between MultiSelect and FreeText
                    if (!isInit) {
                        if (!recordValue) {
                            setRecord((prev) => ({ ...prev, name: record.name }));
                        }
                        setSelectedValues(null);
                        if (fieldErrors.name) {
                            setFieldErrors((prev) => ({ ...prev, name: "" }));
                        }
                    }
                    const values = record.values || [];
                    const newValuesOptions = values.map(({ label }) => ({ id: label, label, value: label })); // API expects labels, not values
                    setValuesOptions(newValuesOptions);

                    // defaults
                    if (type === "DateTime") {
                        const minDate = record.robustMin ? new Date(record.robustMin / 1000000).toISOString() : null;
                        const maxDate = record.robustMax ? new Date(record.robustMax / 1000000).toISOString() : null;
                        setMinDateDefault(minDate);
                        setMaxDateDefault(maxDate);
                    } else if (type === "Number") {
                        updatedMinNumberDefault = record.robustMin;
                        updatedMaxNumberDefault = record.robustMax;
                        setMinNumberDefault(updatedMinNumberDefault);
                        setMaxNumberDefault(updatedMaxNumberDefault);
                    }
                    setOperatorOptions(operatorOptions);
                    setOperatorValue(operatorOptions, operator, record, updatedMinNumberDefault, updatedMaxNumberDefault, updatedIsInit, newValuesOptions);
                    // chain
                    setChainFilter("rightContainerId", record.containerId || record.container?.id);
                    loadChains();
                }
            };

            const onError = function (response: any) {
                unloadKeyDetails();
            };

            getRecordAPI({ module: "store", object: "key", recordId: key.id }, onSuccess, onError);
        } else {
            unloadKeyDetails();
        }
    }

    function updateUI(record: FilterType, isInitArg: boolean) {
        const updatedIsInit = isInitArg === undefined ? isInit : isInitArg;
        const filter = record;

        // get Key and Chain from nodes
        let key, multiple;
        let updatedChain: any;
        let container = filter.container;
        let nodes = Record.flatten(filter, "inputs");
        for (let node of nodes) {
            if (key && node.key) {
                multiple = true;
            }
            if (updatedChain && node.chain) {
                multiple = true;
            }
            key = node.key;
            updatedChain = node.chain;
        }
        // for now, unset key and chain if there are mulitple
        // IMPROVEMENT: edit tree structure in UI
        if (multiple) {
            key = null;
            updatedChain = null;
        }
        // set selected key and chain
        if (container) {
            container.title = getTitle(getBreadCrumb("container", container));
            setSelectedContainer(container);
            setContainerItem(Record.nameFromDetails("containers", "container", container.id));
            setContainerFilter({ containers: { container: { id: container.id } } });
            setChainFilter("leftContainerId", container.id);
        }
        if (key) {
            key.title = getTitle(getBreadCrumb("key", key));
            setSelectedKey(key);
            setKeyItem(Record.nameFromDetails("keys", "key", key.id));
            setChainFilter("rightContainerId", key.containerId || key.container?.id);
        }
        if (updatedChain) {
            updatedChain.title = getTitle(getBreadCrumb("chain", updatedChain));
            // the chain isn't always loaded with left and right container: IMPROVEMENT: udpate API to include left and right container
            updatedChain.leftContainerId = updatedChain.leftContainerId || updatedChain.leftContainer?.id || filter.containerId || container?.id;
            updatedChain.rightContainerId = updatedChain.rightContainerId || updatedChain.rightContainer?.id || key.containerId || key?.container?.id;
            setChainFilter("leftContainerId", updatedChain.leftContainerId || updatedChain.leftContainer?.id);
            setChainFilter("rightContainerId", updatedChain.rightContainerId || updatedChain.rightContainer?.id);
            // make sure to set selectedChain as the last step, because setChainFilter may unset it
            setChain((prevChain) => ({ ...prevChain, selectedChain: updatedChain, chainItem: Record.nameFromDetails("chains", "chain", updatedChain.id) }));
        }
        // parse fields
        let type = filter.type as keyof typeof RecordConstants.FILTER_TYPES;
        let settings = filter.settings || {};
        let alias = settings.alias;
        let operator = filter.operator;
        // settings.alias overrides the operator
        if (alias) {
            operator = alias;
        }
        setOperator(operator);

        // put settings into input fields
        let options = RecordConstants.FILTER_TYPES[type] || [];
        let option = options.find((i) => i.value === operator) || { useInput: "" };
        let useInput = option.useInput;
        if (useInput === "Preset") {
            let presets = filter.presets || (JSON.parse(JSON.stringify(RecordConstants.FILTER_PRESETS[type])) as typeof presetSelection); // deepclone to prevent changing original values
            setSelectedPreset(settings.preset);
            setPresetOptions(presets);
            setPresetSelection([presets.find((preset) => preset.value === settings.preset)]);
            const { selectedValue, options, selectedItemLabel } = selectLoadedOption(presets, settings.preset, false);
            if (JSON.stringify(presets) !== JSON.stringify(options)) {
                setPresetOptions(options);
            }
            if (JSON.stringify(settings.preset) !== JSON.stringify(selectedValue)) {
                setSelectedPreset(selectedValue);
            }
            setSelectedPresetLabel(selectedItemLabel);
        } else if (["FreeText", "MultiSelect", "ListValues"].includes(useInput)) {
            setSelectedValues(settings.values);
            setFreeTextValues(Record.toCSV(settings.values));
        } else if (useInput === "BetweenDateTime") {
            setMinDate(settings.min);
            setMaxDate(settings.max);
        } else if (useInput === "RelativeDateTime") {
            const minParts = (settings.min || "").split(" ");
            const maxParts = (settings.max || "").split(" ");
            const newMinDateAmount = minParts[0];
            const newMinDateUnit = minParts[1];
            const newMaxDateAmount = maxParts[0];
            const newMaxDateUnit = maxParts[1];
            setMinDateValues(newMinDateAmount, newMinDateUnit);
            setMaxDateValues(newMaxDateAmount, newMaxDateUnit);
        } else if (useInput === "BetweenNumber") {
            setMinNumber(settings.min);
            setMaxNumber(settings.max);
        }
        // new / existing record
        const isSaved = Boolean(filter.id || filter.floatingId);
        setIsSaved(isSaved);

        // scope
        let filterScopes = JSON.parse(JSON.stringify(RecordConstants.FILTER_SCOPES)); // deepcopy to prevent changing underlying values
        if (!isSaved) {
            filterScopes = filterScopes.filter(function (v) {
                return newScopes.includes(v.value);
            });
        }
        const newScopeOptions = filterScopes;
        const newScopeValue = filter.scope;
        setScopeValues(newScopeOptions, newScopeValue);
        const selectedKey = key;
        loadKeyDetails(operator, selectedKey, updatedIsInit);
    }

    const handleContainerSelect = (record: FilterType) => {
        setSelectedContainer({ ...record });
        setContainer(record);
        loadChains();
    };

    const handleKeySelect = (record: FilterType) => {
        const selectedKey = { ...record };
        setSelectedKey({ ...selectedKey });
        const updatedIsInit = false;
        setIsInit(updatedIsInit);
        loadKeyDetails(operator, selectedKey, updatedIsInit);
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    const handleOperatorChange = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) {
            return;
        }

        const newOperator = data.selection[0].value;
        setOperator(newOperator);
        setOperatorLabel(data.selection[0].label);
        setOperatorSelection(data.selection);

        const updatedIsInit = false;
        setIsInit(updatedIsInit);
        setOperatorValue(operatorOptions, newOperator, record, minNumberDefault, maxNumberDefault, updatedIsInit, valuesOptions);
    };

    const handleSelectedValuesChange = (value: string, process: string) => {
        let updatedSelectedValues;
        if (process === "optionsToSelected") {
            updatedSelectedValues = selectedValues && selectedValues.includes(value) ? selectedValues : [...(selectedValues || []), value];
        } else {
            updatedSelectedValues = selectedValues && selectedValues.length > 0 ? selectedValues.filter((item) => item !== value) : [];
        }
        setSelectedValues(updatedSelectedValues);
        setSelectedValue(null);
    };

    const handleSelectedValueUpOrDown = (process: string) => {
        const currentIndex = selectedValues.indexOf(selectedValue);

        if (currentIndex === -1) {
            return;
        }

        const updatedSelectedValues = [...selectedValues];
        if (process === "up" && currentIndex > 0) {
            const temp = updatedSelectedValues[currentIndex];
            updatedSelectedValues[currentIndex] = updatedSelectedValues[currentIndex - 1];
            updatedSelectedValues[currentIndex - 1] = temp;
        } else if (process === "down" && currentIndex < updatedSelectedValues.length - 1) {
            const temp = updatedSelectedValues[currentIndex];
            updatedSelectedValues[currentIndex] = updatedSelectedValues[currentIndex + 1];
            updatedSelectedValues[currentIndex + 1] = temp;
        }
        setSelectedValues(updatedSelectedValues);
    };

    const handleChangeSelectedValue = (selected: string) => setSelectedValue(selected);

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-var-m-around_medium" role="list" id="listDiv">
            <h3 className="slds-section-title--divider slds-var-m-top_medium">Filter Details</h3>
            <div className="slds-form__row">
                <FormItemWrapper>
                    <div className="slds-grid slds-grid_vertical slds-wrap">
                        <div className="slds-col slds-size_1-of-1">
                            <div className="slds-grid slds-grid_vertical-align-center slds-var-m-bottom_small">
                                {/* Active */}
                                <div className="slds-col slds-size_xxx-small">
                                    <Field
                                        mode={mode}
                                        isEditable={true}
                                        isFormItem={true}
                                        value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.active} />}
                                        label="Active"
                                        onEdit={onEdit}
                                        body={<Toggle label="Active" active={record?.active} setActive={() => setRecord((prev) => ({ ...prev, active: !prev?.active }))} />}
                                    />
                                </div>
                                {/* Name */}
                                <div className="slds-grow slds-m-left_small">
                                    <Field
                                        recordObject={recordObject}
                                        setRecord={setRecord}
                                        mode={mode}
                                        value={record?.name}
                                        label="Name"
                                        onEdit={onEdit}
                                        fieldName="name"
                                        isEditable={true}
                                        isFormItem={true}
                                        hasRevert={true}
                                        checkValidity={checkRequiredField}
                                        setFieldErrors={setFieldErrors}
                                        body={<Input name="name" autoComplete="off" label="Name" required={true} value={getSafeInputValue(record?.name)} errorText={fieldErrors?.name} />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormItemWrapper>
                {/* Scope */}
                <FormItemWrapper>
                    <Field
                        mode={mode}
                        value={record?.scope}
                        label="Scope"
                        onEdit={onEdit}
                        isEditable={true}
                        isFormItem={true}
                        body={
                            <Combobox
                                events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleSelectScope(event, data) }}
                                labels={{ label: "Scope", placeholder: "--Please Select--" }}
                                menuPosition="relative"
                                options={scopeOptions}
                                selection={scopeSelection}
                                value={record?.scope || ""}
                                variant="readonly"
                                singleInputDisabled={isSaved}
                                disabled={isSaved}
                                name="scope"
                            />
                        }
                    />
                </FormItemWrapper>
            </div>

            {!hideAppliesTo && (
                <>
                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Applies To</h3>
                    <div className="slds-form__row">
                        {/* Container */}
                        <Field
                            mode={mode}
                            value={selectedContainer?.name}
                            label="Object"
                            onEdit={onEdit}
                            fieldName="dataTypeId"
                            setRecord={setSelectedContainer}
                            body={
                                <div ref={searchFieldRef} id="checkPsNavigationInputOfObject">
                                    <PsNavigationInput
                                        label="Object"
                                        object="container"
                                        sections={["containers"]}
                                        selected={containerItem}
                                        record={selectedContainer}
                                        required={true}
                                        disabled={isSaved}
                                        onChange={handleContainerSelect}
                                        filters={containerFilter}
                                        isExpanded={cmpState.isExpanded}
                                        activeField={cmpState.activeField}
                                        setFilterState={setCmpState}
                                        filterSetState={filterSetState}
                                        fieldErrors={fieldErrors}
                                        childToParent={childToParent}
                                    />
                                </div>
                            }
                        />

                        {/* Chain */}
                        {chain.needsChain ? (
                            <Field
                                mode={mode}
                                value={chain.selectedChain?.name}
                                label="Path"
                                onEdit={onEdit}
                                fieldName="selectedChain.name"
                                body={
                                    <div ref={searchFieldRef} id="checkPsNavigationInputOfObject">
                                        <PsNavigationInput
                                            label="Path"
                                            object="chain"
                                            sections={["chains"]}
                                            selected={chain.chainItem}
                                            record={chain.selectedChain}
                                            disabled={isSaved}
                                            required={true}
                                            filters={chain.chainFilter}
                                            isExpanded={cmpState.isExpanded}
                                            activeField={cmpState.activeField}
                                            setFilterState={setCmpState}
                                            filterSetState={filterSetState}
                                            fieldErrors={fieldErrors}
                                            childToParent={childToParent}
                                        />
                                    </div>
                                }
                            />
                        ) : (
                            <FormItemWrapper></FormItemWrapper>
                        )}
                    </div>
                </>
            )}
            <h3 className="slds-section-title--divider slds-var-m-top_medium">Settings</h3>
            <div className="slds-form__row">
                {/* Key (Field) */}
                <Field
                    mode={mode}
                    value={selectedKey?.name}
                    label="Field"
                    onEdit={onEdit}
                    fieldName="selectedKey.name"
                    body={
                        <div ref={searchFieldRef} id="checkPsNavigationInputOfObject">
                            <PsNavigationInput
                                label="Field"
                                object="key"
                                sections={["keys"]}
                                selected={keyItem}
                                record={selectedKey}
                                required={true}
                                disabled={isSaved}
                                onChange={handleKeySelect}
                                activeField={cmpState.activeField}
                                setFilterState={setCmpState}
                                filterSetState={filterSetState}
                                fieldErrors={fieldErrors}
                                childToParent={childToParent}
                            />
                        </div>
                    }
                />

                {/* Operator */}
                <Field
                    mode={mode}
                    value={operatorLabel}
                    label="Operator"
                    onEdit={onEdit}
                    isEditable={true}
                    body={
                        <Combobox
                            menuItemVisibleLength={10}
                            events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleOperatorChange(event, data) }}
                            labels={{ label: "Operator", placeholder: "--Please Select--" }}
                            menuPosition="relative"
                            options={operatorOptions}
                            selection={operatorSelection}
                            value={operator || ""}
                            variant="readonly"
                            required
                            name="operator"
                            errorText={fieldErrors?.operator}
                            disabled={operatorOptions.length === 0 ? true : false}
                        />
                    }
                />
            </div>
            {operator && (
                <div className="slds-form__row">
                    {/* Preset */}
                    {useInput === "Preset" && (
                        <Field
                            mode={mode}
                            value={selectedPresetLabel}
                            label="Preset"
                            onEdit={onEdit}
                            isEditable={true}
                            body={
                                <Combobox
                                    name="preset"
                                    menuItemVisibleLength={10}
                                    events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleSelectPreset(event, data) }}
                                    labels={{ label: "Preset", placeholder: "--Please Select--" }}
                                    menuPosition="relative"
                                    options={presetOptions}
                                    selection={presetSelection}
                                    value={selectedPreset || ""}
                                    variant="readonly"
                                    required
                                    errorText={fieldErrors?.preset}
                                />
                            }
                        />
                    )}

                    {/* Freetext */}
                    {useInput === "FreeText" && (
                        <Field
                            mode={mode}
                            value={freeTextValues}
                            label="Values"
                            onEdit={onEdit}
                            fieldName="freeTextValues"
                            body={
                                <Input
                                    name="freeTextValues"
                                    label="Values"
                                    autocomplete="off"
                                    value={getSafeInputValue(freeTextValues)}
                                    fieldLevelHelpTooltip={
                                        <Tooltip
                                            id="field-level-help-tooltip"
                                            align="top left"
                                            content='Enter one or more values separated by commas. Enclose values with commas in double quotes. 
    For example: orange, "pear, apple", banana'
                                        />
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setFreeTextValues(e.target.value)}
                                />
                            }
                        />
                    )}

                    {/* MultiSelect */}
                    {useInput === "MultiSelect" && (
                        <Field
                            mode={mode}
                            value={freeTextValues}
                            label="Values"
                            onEdit={onEdit}
                            fieldName="freeTextValues"
                            body={
                                <DualListBox
                                    handleSelectedValuesChange={handleSelectedValuesChange}
                                    handleChangeSelectedValue={handleChangeSelectedValue}
                                    handleSelectedValueUpOrDown={handleSelectedValueUpOrDown}
                                    label="Values"
                                    fieldLevelHelp=""
                                    valuesOptions={selectedValues && selectedValues.length > 0 ? valuesOptions.filter((option) => !selectedValues.includes(option.value)) : valuesOptions}
                                    selectedOptions={
                                        selectedValues && selectedValues.length > 0
                                            ? selectedValues.map((selectedValue) => ({
                                                  value: selectedValue,
                                                  label: selectedValue === "POINT SIGMA EMPTY VALUE" ? "--None--" : selectedValue,
                                              }))
                                            : []
                                    }
                                    selectedValue={selectedValue}
                                />
                            }
                        />
                    )}

                    {/* Between Number  */}
                    {useInput === "BetweenNumber" && (
                        <>
                            {/* Min Number */}
                            <Field
                                mode={mode}
                                value={minNumber}
                                label="From"
                                onEdit={onEdit}
                                fieldName="minNumber"
                                body={
                                    <Input
                                        auraId="checkField"
                                        type="text"
                                        name="minnumber"
                                        autoComplete="off"
                                        label="From"
                                        required={false}
                                        value={getSafeInputValue(minNumber)}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Minimum value (included). Leave empty to ignore." />}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMinNumber(e.target.value)}
                                    />
                                }
                            />
                            {/* Max Number */}
                            <Field
                                mode={mode}
                                value={maxNumber}
                                label="To"
                                onEdit={onEdit}
                                fieldName="maxNumber"
                                body={
                                    <Input
                                        auraId="checkField"
                                        type="text"
                                        name="maxnumber"
                                        autoComplete="off"
                                        label="To"
                                        required={false}
                                        value={getSafeInputValue(maxNumber)}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Maximum value (included). Leave empty to ignore." />}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMaxNumber(e.target.value)}
                                    />
                                }
                            />
                        </>
                    )}

                    {/* Between DateTime */}
                    {useInput === "BetweenDateTime" && (
                        <>
                            {/* Min DateTime */}
                            <Field
                                mode={mode}
                                value={<Button auraId="EditRecord" iconName="utility:edit" iconCategory="utility" iconVariant="bare" alternativeText="Edit" />}
                                label="From"
                                onEdit={onEdit}
                                fieldName="mindate"
                                body={
                                    <Input
                                        auraId="checkField"
                                        type="datetime-local"
                                        name="mindate"
                                        autoComplete="off"
                                        label="From"
                                        value={convertDateTimeFormat(minDate)}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => setMinDate(data.value)}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Minimum date and time (included). Leave empty to ignore." />}
                                    />
                                }
                            />

                            {/* Max DateTime */}
                            <Field
                                mode={mode}
                                value={<Button auraId="EditRecord" iconName="utility:edit" iconCategory="utility" iconVariant="bare" alternativeText="Edit" />}
                                label="To"
                                onEdit={onEdit}
                                fieldName="maxdate"
                                body={
                                    <Input
                                        auraId="checkField"
                                        type="datetime-local"
                                        name="maxdate"
                                        autoComplete="off"
                                        label="To"
                                        value={convertDateTimeFormat(maxDate)}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => setMaxDate(data.value)}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Maximum date and time (excluded). Leave empty to ignore." />}
                                    />
                                }
                            />
                        </>
                    )}

                    {/* Relative DateTime */}
                    {useInput === "RelativeDateTime" && (
                        <>
                            <FormItemWrapper label="From">
                                {/* Min Amount */}
                                <Field
                                    isFormItem={true}
                                    mode={mode}
                                    value={minDateAmount}
                                    label="Amount"
                                    onEdit={onEdit}
                                    fieldName="minDateAmount"
                                    body={
                                        <Input
                                            type="number"
                                            step={1}
                                            auraId="checkField"
                                            name="minDateAmount"
                                            label="Amount"
                                            required={minDateUnit && minDateUnit !== "--None--" ? true : false}
                                            value={getSafeInputValue(minDateAmount)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMinDateAmount(e.target.value)}
                                            errorText={fieldErrors?.minDateAmount}
                                        />
                                    }
                                />
                                {/* Min Unit */}
                                <Field
                                    isFormItem={true}
                                    mode={mode}
                                    value={minDateUnitLabel}
                                    label="Unit"
                                    onEdit={onEdit}
                                    fieldName="minDateUnit"
                                    body={
                                        <Combobox
                                            menuItemVisibleLength={10}
                                            events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleMinDateUnitChange(event, data) }}
                                            labels={{
                                                label: "Unit",
                                                placeholder: "Select an option",
                                            }}
                                            menuPosition="relative"
                                            options={dateTimeUnits}
                                            selection={minDateTimeUnitsSelection}
                                            value={minDateUnit}
                                            name="minDateUnit"
                                            variant="readonly"
                                            required={minDateAmount ? true : false}
                                            errorText={fieldErrors?.minDateUnit}
                                        />
                                    }
                                />
                            </FormItemWrapper>
                            <FormItemWrapper label="To">
                                {/* Max Amount */}
                                <Field
                                    isFormItem={true}
                                    mode={mode}
                                    value={maxDateAmount}
                                    label="Amount"
                                    onEdit={onEdit}
                                    fieldName="maxDateAmount"
                                    body={
                                        <Input
                                            type="number"
                                            step={1}
                                            auraId="checkField"
                                            name="maxDateAmount"
                                            label="Amount"
                                            required={maxDateUnit && maxDateUnit !== "--None--" ? true : false}
                                            value={getSafeInputValue(maxDateAmount)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMaxDateAmount(e.target.value)}
                                            errorText={fieldErrors?.maxDateAmount}
                                        />
                                    }
                                />
                                {/* Max Unit */}
                                <Field
                                    isFormItem={true}
                                    mode={mode}
                                    value={maxDateUnitLabel}
                                    label="Unit"
                                    onEdit={onEdit}
                                    fieldName="maxDateUnit"
                                    body={
                                        <Combobox
                                            menuItemVisibleLength={10}
                                            events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleMaxDateUnitChange(event, data) }}
                                            labels={{
                                                label: "Unit",
                                                placeholder: "Select an option",
                                            }}
                                            menuPosition="relative"
                                            options={dateTimeUnits}
                                            selection={maxDateTimeUnitsSelection}
                                            name="maxDateUnit"
                                            value={maxDateUnit}
                                            variant="readonly"
                                            required={maxDateAmount ? true : false}
                                            errorText={fieldErrors?.maxDateUnit}
                                        />
                                    }
                                />
                            </FormItemWrapper>
                        </>
                    )}
                </div>
            )}

            {/* AcceptMissingValuesToggle */}
            <div className={`slds-form__row ${mode === "edit" ? "slds-size_1-of-4" : "slds-size_1-of-2"}`}>
                <Field
                    mode={mode}
                    isEditable={true}
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.acceptMissing} />}
                    label="Accept missing values"
                    onEdit={onEdit}
                    body={<Toggle label="Accept missing values" active={record?.acceptMissing} setActive={() => setRecord((prev) => ({ ...prev, acceptMissing: !prev?.acceptMissing }))} />}
                />
            </div>
        </div>
    );

    // To access the parent component child state
    useImperativeHandle(ref, () => ({
        setFieldErrors: (field, errorMessage) => {
            setFieldErrors((prev) => ({
                ...prev,
                [field]: errorMessage,
            }));
        },
        cardBody,
        record,
        fieldErrors,
        filterJson: () => {
            const filter = record;
            let updatedFilter = filterJson(
                filter,
                operatorOptions,
                operator,
                valuesOptions,
                minDate,
                maxDate,
                selectedPreset,
                presetOptions,
                selectedValues,
                minDateAmount,
                maxDateAmount,
                minDateUnit,
                maxDateUnit,
                minNumber,
                maxNumber,
                scope,
                selectedKey,
                selectedContainer,
                chain.selectedChain,
                freeTextValues
            );
            return updatedFilter;
        },
    }));

    return (
        <PsRecord2
            recordLabel="Filter"
            recordModule="store"
            recordObject={recordObject}
            record={record}
            showEdit={true}
            showDelete={true}
            isModal={isModal}
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            parentToChildEvent={parentToChildEvent}
            fieldErrors={fieldErrors}
            updateUI={updateUI}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseInputPlainText={parseInputPlainText}
            cardBody={cardBody}
        />
    );
});

export default PsFilter2;
