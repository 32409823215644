import "./SetupPanel.css";
import { useState } from "react";

import PsNavigationHeader from "../../../components/ps-navigation-header/PsNavigationHeader";
import { useLocation, useNavigate } from "react-router";
import { PAGE_ROUTES } from "../../../constants";
import { Menu } from "antd";
import PsSetupStatus from "../../../components/ps-setup-status/PsSetupStatus";

const { SETUP } = PAGE_ROUTES;

// This items can be created with useMemo depending on request if We need to add ids as key and paths
const menuItems = [
    {
        key: "grp",
        label: "Overview",
        type: "group",
        children: [
            { key: SETUP.USERS.path, label: "Users" },
            {
                key: SETUP.ACCOUNT.path,
                label: "Account Settings",
            },
        ],
    },
];

const SetupPanel = ({ childToParent, children }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const selectedKey = pathname.toLowerCase();
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);

    // e parameter is click menu antd type
    const onClick = (e) => {
        navigate(e.key);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (!stopPropagation) {
            childToParent(event);
        }
    };

    return (
        <div className="setup-page-container">
            <PsNavigationHeader childToParent={bubbleEvent} />
            <div className="tab-content slds-p-around_medium">
                {/* <!-- left panel view --> */}
                <div className="um-left slds-m-right_medium">
                    <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small">Setup</h2>
                    <div className="slds-card__body">
                        <Menu className="ant-side-menu-custom-style" onClick={onClick} defaultSelectedKeys={[selectedKey]} mode="vertical" items={menuItems} />
                    </div>
                </div>
                {/* <!-- right panel view --> */}
                <div className="um-right" style={{ overflow: setupStatusVisible ? "hidden" : "scroll" }}>
                    <PsSetupStatus title="Setup" tagLine="Manage your settings." includeStatus={["Error"]} onSetupStatusVisible={setSetupStatusVisible} />

                    {children}
                </div>
            </div>
        </div>
    );
};

export default SetupPanel;
