import { Modal, Button } from "@salesforce/design-system-react";
import React, { useRef, useState } from "react";

// import { PsFilter } from "../../../../../components/ps-filter/PsFilter";
// import { filterJson } from "../../../../../components/ps-filter/components/Helper";
import PsFilter2 from "../../../../../components/ps-filter/PsFilter2";
import useToastContext from "../../../../../context/useToastContext";
import { FIELD_ERROR_MESSAGES } from "../../../../../constants";

const FilterModal = ({ openFilter, onSaveFilter, onCancel }) => {
    const [filterSetState, setFilterSetState] = useState({
        filters: [],
        records: [],
        listOrder: null,
        selectedFilter: openFilter?.filter || null,
        selectedContainerId: null,
        loading: false,
        showApplyButton: false,
        scriptsLoaded: false,
        showRequiredFieldError: false,
        isSaved: true,
    });

    // global toast
    const { addToast } = useToastContext();

    const filterCmpRef = useRef(null);

    const isFormValid = (record, cardBody, fieldErrors, setFieldErrors) => {
        if (record && fieldErrors && Object.entries(fieldErrors).some(([key, error]) => error || (key in record && record[key] === ""))) {
            return false;
        }

        let isValid = true;
        const validateElement = (element) => {
            if (!element) return;

            if (React.isValidElement(element)) {
                const { type, props } = element;

                if (props.body && props.body?.props?.required && (!props.body?.props?.value || props.body?.props?.value === "--None--")) {
                    isValid = false;

                    const field = props.body.props.name;
                    if (field) {
                        const errorMessage = props.body.props.errorMessage || FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;
                        setFieldErrors(field, errorMessage);
                    }
                }
                if (props.children) {
                    React.Children.forEach(props.children, validateElement);
                }
            } else if (Array.isArray(element)) {
                element.forEach(validateElement);
            }
        };
        validateElement(cardBody);

        return isValid;
    };

    const saveFilter = () => {
        const record = filterCmpRef.current.record;
        const cardBody = filterCmpRef.current.cardBody;
        const fieldErrors = filterCmpRef.current.fieldErrors;
        const setFieldErrors = filterCmpRef.current.setFieldErrors;
        const filterJson = filterCmpRef.current.filterJson();

        if (!isFormValid(record, cardBody, fieldErrors, setFieldErrors)) {
            const toastId = record?.id || "recordCreateId";
            addToast("error", "Input Error", "Please update the invalid form entries and try again.", toastId);
            return;
        }
        const filter = filterJson;
        onSaveFilter(filter, openFilter?.index);
    };

    return (
        <Modal
            isOpen={!!openFilter}
            footer={[<Button key="Cancel" label="Cancel" onClick={onCancel} />, <Button key="Save" label="Save" variant="brand" onClick={saveFilter} />]}
            onRequestClose={onCancel}
            heading="Filter"
        >
            <div className="slds-modal__content slds-p-around_medium" id="modal-content-id-filter">
                {/* <PsFilter
                    recordValue={filterSetState.selectedFilter}
                    parentId={filterSetState.selectedContainerId}
                    newScopes={["Pattern"]}
                    isModal={true}
                    ref={filterCmpRef}
                    filterSetState={filterSetState}
                    showRequiredFieldError={filterSetState.showRequiredFieldError}
                    hideAppliesTo
                    selectedFieldRecord={openFilter?.record || undefined}
                    selectedFilterBuild={openFilter?.filter || undefined}
                /> */}
                <PsFilter2
                    parentId={filterSetState.selectedContainerId}
                    ref={filterCmpRef}
                    options={{
                        filter: {
                            newScopes: ["Pattern"],
                            isModal: true,
                            filterSetState: filterSetState,
                            showRequiredFieldError: filterSetState.showRequiredFieldError,
                            hideAppliesTo: false,
                            selectedFieldRecord: openFilter?.record || undefined,
                            selectedFilterBuild: openFilter?.filter || undefined,
                            recordValue: filterSetState.selectedFilter,
                        },
                    }}
                />
            </div>
        </Modal>
    );
};

export default FilterModal;
