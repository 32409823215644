import { useEffect, useState } from "react";
import Record from "../../../helpers/recordLayer";
import SetupPanel from "../setup-panel/SetupPanel";
import AccountForm from "./AccountForm";
import { PAGE_ROUTES } from "../../../constants";
import useAccountSettingsContext from "../../../context/useAccountSettingsContext";
import useAuthContext from "../../../context/useAuthContext.js";
import { formatDuration, intervalToDuration } from "date-fns";

import useToastContext from "../../../context/useToastContext";

const initializeReadOnlyValues = {
    name: "", // string
    setupStatus: "", // string
    computeCredit: 0, //number
    totalStorageBytes: 0, //number
    deferUpdatesSeconds: 0, //number
    deferUpdatesInterval: "", // string
    maxNumPatterns: 0, //number
    limitsGenerativeAI: "", // string
};

const Account = () => {
    const [isEdit, setIsEdit] = useState(false);
    const { module, object } = PAGE_ROUTES.SETUP.ACCOUNT;
    const [accountValues, setAccountValues] = useState(null); // accountValues is same type with formValues and can be updated by the user
    const [readOnlyValues, setReadOnlyValues] = useState(initializeReadOnlyValues); // This values can NOT be updated by the user
    const [isLoading, setIsLoading] = useState(false);
    const { handleLogout } = useAuthContext();

    const { addToast } = useToastContext();

    const { account, isLoading: loading, refetchAccount } = useAccountSettingsContext();

    // values parameter is same type with accountValues
    async function onSaveAccountData(values) {
        setIsLoading(true);
        const onSuccess = (_res) => {
            addToast("success", "Account Updated", "Account details have been successfully updated.");
            refetchAccount(); // refetch Account details
            setIsEdit(false);
            setIsLoading(false);
        };
        const onError = (err) => {
            // TODO: use standard Toast error message handling
            const errMessage = err?.data?.message || "Account details could not be saved. Please try again.";
            addToast("error", "Save Failed", errMessage);
            setIsLoading(false);
            return true;
        };
        try {
            // ---> Please do not change the key naming in the below object like this should save in back end.
            const settings = {
                enableGenerativeAIExplain: values.enableGenerativeAIExplain, // save on server ->  boolean
                enableGenerativeAISearch: values.enableGenerativeAISearch, // save on server ->  boolean
                generativeAI: values.generativeAI, // save on server ->  string
                disableDropOutliers: values?.disableDropOutliers, // save on server ->  boolean
                disableDecimation: values?.disableDecimation, // save on server ->  boolean
                preferBarOverDoughnut: values?.preferBarOverDoughnut, // save on server ->  boolean
                ...(values?.significantDigits !== "" && { significantDigits: Number(values?.significantDigits) }), //  Add significantDigits only if is not empty, save on server -> number or undefined
                ...(values?.defaultColorMap !== "" && { defaultColorMap: values.defaultColorMap }), //  Add defaultColorMap only if is not empty, save on server -> string or undefined
                ...(values?.dateTimeFormat !== "" && { dateTimeFormat: values.dateTimeFormat }), //  Add dateTimeFormat only if is not empty, save on server -> string or undefined
            };
            Record.submitRecord(module, object, { id: values.id, settings }, onSuccess, onError);
        } catch (error) {
            console.error("Failed to fetch user details:", error);
        }
    }

    async function childToParent(event) {
        // TODO: make this work the same way as in all other tabs
        try {
            if (event.type === "navigation") {
            } else if (event.type === "record") {
            } else if (event.type === "reload") {
                refetchAccount();
            } else if (event.type === "reload") {
                handleLogout();
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        // update the
        if (!!account) {
            const resData = account;
            const readOnly = {
                name: resData?.name || "", // string
                setupStatus: resData?.setupStatus || "", // string
                computeCredit: Math.floor(resData?.usage?.computeCredit || 0), //number
                totalStorageBytes: resData?.usage?.totalStorageBytes || 0, //number
                deferUpdatesSeconds: resData?.limits?.deferUpdatesSeconds || 0, //number
                deferUpdatesInterval: formatDuration(intervalToDuration({ start: 0, end: resData?.limits?.deferUpdatesSeconds * 1000 || 0 })),
                maxNumPatterns: resData?.limits?.maxNumPatterns || 0, // number
                limitsGenerativeAI: resData?.limits?.generativeAI || "", // string
            };

            const editableValues = {
                id: resData?.id,
                generativeAI: resData.settings?.generativeAI || "", // string
                enableGenerativeAIExplain: resData?.settings?.enableGenerativeAIExplain || false, // boolean
                enableGenerativeAISearch: resData?.settings?.enableGenerativeAISearch || false, // boolean
                disableDropOutliers: resData?.settings?.disableDropOutliers || false, // boolean
                disableDecimation: resData?.settings?.disableDecimation || false, // boolean
                significantDigits: resData?.settings?.significantDigits ?? "", // string
                preferBarOverDoughnut: resData?.settings?.preferBarOverDoughnut || false, // boolean
                defaultColorMap: resData.settings?.defaultColorMap || "", // string
                dateTimeFormat: resData.settings?.dateTimeFormat || "", // string
            };
            setReadOnlyValues(readOnly);
            setAccountValues(editableValues);
        }
    }, [account]);

    return (
        <SetupPanel childToParent={childToParent}>
            <AccountForm loading={isLoading || loading} formValues={accountValues} isEdit={isEdit} setIsEdit={setIsEdit} onSave={onSaveAccountData} readOnlyValues={readOnlyValues} />
        </SetupPanel>
    );
};

export default Account;
