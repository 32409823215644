import React from "react";
import { ProgressBar } from "@salesforce/design-system-react";

const FieldSkeleton: React.FC = () => {
    return (
        <div className="slds-form-element slds-form-element_stacked">
            <span className="slds-form-element__label">&nbsp;</span>
            <div className="slds-form-element__static">
                <ProgressBar value={0} variant="circular" />
            </div>
        </div>
    );
};

export default FieldSkeleton;
