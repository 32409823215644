import { useState } from "react";
import { Button, Input, Spinner, Card } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import "../Pages.css";
import { GENERAL_ERROR } from "../../constants/ToastMessages";
import RegionSelection from "../../components/region-selection";
import useAuthContext from "../../context/useAuthContext";
import useToastContext from "../../context/useToastContext";

const ForgotPassword = () => {
    const { addToast } = useToastContext();

    const [username, setUsername] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // const [mode,setMode] = useState('login'); //login, resetpassword, newpassword - would we need confirm password mode too?

    const { user } = useAuthContext();
    const region = user.region;

    const navigate = useNavigate();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true);

            const data = await Auth.forgotPassword(username);
            addToast("success", "Password Reset", "Please check your email for further instructions.");
            navigate("/login?mode=resetPassword");
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-signup-container">
            <form onSubmit={handleSubmit}>
                <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
                    <p className="form-title">Enter your username below and we will send a message to reset your password</p>
                    <RegionSelection />
                    <div className="user-inputs">
                        <Input type="text" label="Username" value={username} onChange={handleUsernameChange} autoComplete="username" />
                    </div>
                    <div className="login-signup-button">
                        <Button
                            type="submit"
                            label={<>Reset my password{isLoading ? <Spinner size="small" /> : null}</>}
                            variant="brand"
                            disabled={isLoading || !username || !region}
                            className="login-submit-button"
                        />
                    </div>
                </Card>
            </form>
        </div>
    );
};

export default ForgotPassword;
