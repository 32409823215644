import PsTopMenuBar from "../components/ps-top-menu-bar/PsTopMenuBar";
import useAuthContext from "../context/useAuthContext";
import AuthRouter from "./AuthRouter";
import UnAuthRouter from "./UnAuthRouter";

const CustomRouter = () => {
    const { isAuthenticating, isAuthenticated } = useAuthContext();

    return isAuthenticating ? null : (
        <div className="App">
            <PsTopMenuBar />
            <div style={{ height: "40px" }}></div>
            <>{isAuthenticated ? <AuthRouter /> : <UnAuthRouter />}</>
        </div>
    );
};

export default CustomRouter;
