import { useState } from "react";
import { Card } from "@salesforce/design-system-react";

import "./../Pages.css";
import UserForm from "./components/UserForm";
import ConfirmationForm from "./components/ConfirmationForm";

const Signup = () => {
    const [newUser, setNewUser] = useState(null);
    const [userForm, setUserForm] = useState({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    return (
        <div className="login-signup-container">
            <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
                {newUser ? <ConfirmationForm userForm={userForm} /> : <UserForm setNewUser={setNewUser} userForm={userForm} setUserForm={setUserForm} />}
            </Card>
        </div>
    );
};

export default Signup;
