import { useState, useEffect, useRef } from "react";
import Input from "@salesforce/design-system-react/components/input";
import { InputIcon } from "@salesforce/design-system-react";

import PsNavigationTree from "../ps-navigation-tree/PsNavigationTree";
import Record from "../../helpers/recordLayer";
import SearchIcon from "../../ui/SearchIcon";

const PsNavigationInput = (props) => {
    const [cmpState, setCmpState] = useState({
        searchText: "",
        isExpanded: props.isExpanded || false,
        navigationLoading: false,
        placeholder: "Search...",
        validity: {},
        object: props.object,
        selected: props.selected,
    });

    const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);
    const searchFieldRef = useRef(null);
    const navTreeRef = useRef(null);

    useEffect(() => {
        try {
            cmpWorking.current = { ...cmpState };
            cmp.init();
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (cmpState.isExpanded && searchFieldRef.current) {
                handleSearchBlur();
            }

            // This process is done only during the first load:
            if (props.selected && !cmpWorking.current.selected) {
                cmpWorking.current.selected = props.selected;
                setCmpState((prev) => ({ ...prev, selected: props.selected }));
            }

            // // TODO Can be made more general later
            // if (props.setParentCmpState && !props.isExpanded) {
            //     cmp.set("isExpanded", false);
            // }
        } catch (err) {
            console.error(err);
        }
    }, [props]);

    useEffect(() => {
        try {
            if (cmpState.isExpanded && searchFieldRef.current) {
                searchFieldRef.current.focus();
            }
        } catch (err) {
            console.error(err);
        }
    }, [cmpState.isExpanded]);

    useEffect(() => {
        // get DOM element
        const listDivElement = document.getElementById("listDiv");

        if (!listDivElement || !props.leftPsNavigationInputRef || !props.leftPsNavigationInputRef) return;

        const handleClick = (e) => {
            const target = e.target;
            if (
                (props.leftPsNavigationInputRef?.current && props.leftPsNavigationInputRef?.current.contains(target)) ||
                (props.rightPsNavigationInputRef?.current && props.rightPsNavigationInputRef?.current.contains(target))
            ) {
                return;
            }
            setCmpState((prev) => ({ ...prev, activeField: "", isExpanded: false }));
        };

        // add Event listener
        listDivElement.addEventListener("click", handleClick);

        // Cleanup event listener
        return () => {
            listDivElement.removeEventListener("click", handleClick);
        };
    }, []);

    const cmp = {
        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        init: () => {
            checkValidity();
        },
    };

    const checkValidity = () => {
        var valid = !props.required || props.record;
        if (valid) {
            cmpWorking.current.validity = { valid: true };
        } else {
            cmpWorking.current.validity = { valid: false, valueMissing: true };
        }
        return valid;
    };

    const focusSearch = () => {
        cmpWorking.current.isExpanded = true;
        cmpWorking.current.searchText = "";

        // TODO Can be made more general later
        if (props.setFilterState) {
            props.setFilterState((prev) => ({ ...prev, activeField: props.label }));
        }

        if (props.setParentCmpState) {
            props.setParentCmpState((prev) => ({
                ...prev,
                activeField: props.label,
            }));
        }

        setCmpState(cmpWorking.current);

        // set focus after the new search field is rendered
        // var timer = setTimeout(function () {
        //   $A.getCallback(function () {
        //     var inputs = [].concat(cmp.find("searchField") || []);
        //     inputs.forEach((input) => {
        //       input.focus();
        //     });
        //   })();
        // }, 0);
        if (searchFieldRef.current) {
            searchFieldRef.current.focus();
        }
    };

    const handleSearchClick = () => {
        try {
            focusSearch();
        } catch (err) {
            console.error(err);
        }
    };

    const handleSearchBlur = () => {
        if (!props.activeField) {
            cmp.set("isExpanded", false);
        }
    };

    const dispatchEvent = (event) => {
        props.childToParent(event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (event.type === "navigation") {
            stopPropagation = true;
            handleNavigationEvent(event);
        }

        if (!stopPropagation) {
            props.childToParent(event);
        }
    };

    const handleNavigationEvent = (event) => {
        // scroll only
        var source = event.source;
        if (["change", "closeSearch"].includes(source)) {
            var scroll = event.scroll;
            var scrollers = [].concat(navTreeRef.current || []);

            const searchInput = searchFieldRef.current;
            if (searchInput && scrollers && scroll != null) {
                scrollers.forEach((scroller) => {
                    var timer = setTimeout(() => {
                        var top = searchInput.offsetTop + searchInput.offsetHeight;
                        scroller.scrollTop = scroll * (scroller.scrollHeight - top);
                    }, 0);
                });
            }
        }

        // set selected and record, and copy breadcrumb to record title field
        if (source === "tree") {
            var object = event.obj;

            if (
                object === props.object // cmpWorking.current.object
            ) {
                var item = Record.itemFromEvent(event, {});
                var record = Object.assign({}, event.record || {}, {
                    title: item.title,
                });

                cmpWorking.current = {
                    ...cmpWorking.current,
                    selected: item.name,
                    value: record.id,
                    // record,
                    isExpanded: false,
                };
                setCmpState(cmpWorking.current);
                if (props.setFilterState) {
                    props.setFilterState((prev) => ({
                        ...prev,
                        record: record,
                        selected: item.name,
                    }));
                }

                // TODO Can be made more general later
                if (props.object === "dataType") {
                    props.setParentCmpState((prev) => ({
                        ...prev,
                        record: {
                            ...prev.record,
                            leftKey: record,
                            leftKeySelected: item.name,
                        },
                    }));
                }

                if (props.object === "key" && (props.label === "From Field" || props.label === "To Field")) {
                    props.setParentCmpState((prev) => ({
                        ...prev,
                        record: {
                            ...prev.record,
                            dataType: record,
                            dataTypeSelected: item.name,
                        },
                    }));
                }

                if (props.onChange) {
                    //TODO   Can be made more general later
                    if (props.object === "dataType") {
                        props.onChange("dataTypeId", record);
                    } else {
                        props.onChange(record);
                    }

                    //   $A.enqueueAction(onchange);
                } // IMPROVEMENT: this doesn't set the event context; see https://developer.salesforce.com/docs/atlas.en-us.lightning.meta/lightning/ref_attr_types_aura_action.htm for suggestions on how to improve
            } else {
                // clear search and focus
                focusSearch();
            }
        }
        checkValidity();
    };

    const handleSearchTextChange = (e) => {
        setCmpState((prev) => ({
            ...prev,
            navigationLoading: true,
            searchText: e.target.value,
        }));
    };

    const handleSearchTextClick = () => {
        // TODO Can be made more generic
        if (props.setFilterState) {
            props.setFilterState((prev) => ({
                ...prev,
                activeField: props.label,
            }));
        }

        if (props.setParentCmpState) {
            props.setParentCmpState((prev) => ({
                ...prev,
                activeField: props.label,
            }));
        }
    };

    const hasRecord = props.record && Object.keys(props.record).length !== 0;

    return (
        <div className="slds-form-element slds-form-element_stacked" style={{ marginBottom: "0" }}>
            {(!props.showRequiredFieldError || cmpState.isExpanded) && (
                <label className="slds-form-element__label">
                    {props.required && (
                        <abbr title="required" className="slds-required">
                            *
                        </abbr>
                    )}
                    {props.label}
                </label>
            )}

            {cmpState.isExpanded ? (
                <>
                    <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right slds-input-has-icon_group-right">
                        <SearchIcon />
                        <div className="slds-form-element">
                            <div className="slds-form-element__control">
                                {/* when typing */}
                                <input
                                    ref={searchFieldRef}
                                    type="text"
                                    id="searchField"
                                    placeholder={cmpState.placeholder}
                                    required={props.required}
                                    className="slds-input"
                                    onChange={(e) => handleSearchTextChange(e)}
                                    onClick={() => handleSearchTextClick()}
                                    variant="base"
                                    autoComplete="off"
                                    value={cmpState.searchText}
                                    name={props.object}
                                />
                            </div>
                        </div>
                        <div className="slds-input__icon-group slds-input__icon-group_right">
                            {cmpState.navigationLoading ? (
                                <div role="status" className="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner">
                                    <span className="slds-assistive-text">Loading</span>
                                    <div className="slds-spinner__dot-a"></div>
                                    <div className="slds-spinner__dot-b"></div>
                                </div>
                            ) : null}
                            {cmpState.searchText ? (
                                <button
                                    className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
                                    title="Clear"
                                    onClick={() =>
                                        setCmpState((prev) => ({
                                            ...prev,
                                            searchText: "",
                                        }))
                                    }
                                >
                                    <svg className="slds-button__icon slds-icon-text-light" aria-hidden="true">
                                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
                                    </svg>
                                    <span className="slds-assistive-text">Clear</span>
                                </button>
                            ) : null}
                        </div>
                        {props.activeField === props.label && (
                            <div
                                className="dropdownwrapper"
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    className="slds-box slds-theme_default dropdown"
                                    style={{
                                        position: "absolute",
                                        top: "100%",
                                        left: 0,
                                        zIndex: 999,
                                        minWidth: "100%",
                                        width: "auto",
                                    }}
                                >
                                    <div ref={navTreeRef} id="dropdownscroll" className="slds-p-around_small slds-scrollable" style={{ maxHeight: "40vh", overflowY: "visible" }}>
                                        <PsNavigationTree
                                            multiSelect={false}
                                            sections={props.sections}
                                            selected={cmpState.selected}
                                            searchText={cmpState.searchText}
                                            filters={props.filters}
                                            setLoading={(value) => cmp.set("navigationLoading", value)}
                                            parentToChildEvent={props.parentToChildEvent}
                                            parentCmp={cmp}
                                            ref={cmpNavigationTree}
                                            childToParent={bubbleEvent}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="backdrop" onClick={handleSearchBlur}></div>
                </>
            ) : (
                <>
                    {/* selected */}
                    {hasRecord && (
                        <div title={props.record.title}>
                            <Input
                                label={!props.filterSetState?.showRequiredFieldError || !hasRecord ? "" : props.label}
                                id="searchField"
                                variant="base"
                                autoComplete="off"
                                type="text"
                                value={props.record.name || ""}
                                title={props.record.title}
                                onFocus={handleSearchClick}
                                disabled={props.disabled}
                                required={props.required}
                                name={props.object}
                            />
                        </div>
                    )}
                    {!hasRecord && (
                        <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right slds-input-has-icon_group-right">
                            <div style={{ paddingBottom: 10 }}>
                                {/* when search icon show */}
                                <Input
                                    iconLeft={
                                        <InputIcon
                                            assistiveText={{
                                                icon: "Search",
                                            }}
                                            name="search"
                                            category="utility"
                                        />
                                    }
                                    label={(!props.filterSetState?.showRequiredFieldError || hasRecord || !props.fieldErrors?.name) && !cmpState.isExpanded ? "" : props.label}
                                    id="searchField"
                                    variant="base"
                                    autoComplete="off"
                                    type="search"
                                    placeholder={cmpState.placeholder}
                                    required={props.required}
                                    onFocus={handleSearchClick}
                                    disabled={props.disabled}
                                    errorText={(props.showRequiredFieldError ? "Complete this field." : null) || props.fieldErrors?.name}
                                    name={props.object}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default PsNavigationInput;
