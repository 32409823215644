import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import { GENERAL_ERROR, INVALID_EMAIL, PASSWORD_MISMATCH } from "../../../constants/ToastMessages";
import { isValidEmail } from "../../../utils";
import "../../Pages.css";
import RegionSelection from "../../../components/region-selection";
import useAuthContext from "../../../context/useAuthContext";
import useToastContext from "../../../context/useToastContext";

const UserForm = ({ setNewUser, userForm, setUserForm }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { username, email, password, confirmPassword } = userForm;
    const { user } = useAuthContext();

    const { addToast } = useToastContext();

    const handleUserInputChange = (e, item) => {
        setUserForm((prevUser) => ({ ...prevUser, [item]: e.target.value }));
    };

    const handleSubmitUserForm = async (event) => {
        try {
            event.preventDefault();

            if (!isValidEmail(email)) {
                addToast("warning", "Invalid Email Address", INVALID_EMAIL);
                return;
            }
            if (password !== confirmPassword) {
                addToast("warning", "Password Mismatch", PASSWORD_MISMATCH);
                return;
            }

            setIsLoading(true);
            const newUser = await Auth.signUp({
                username,
                password,
                attributes: { email },
            });
            if (newUser) {
                setNewUser(newUser);
                addToast("success", "Registration Successful", "Please check your email for verification.");
            } else {
                addToast("error", "Error", GENERAL_ERROR);
            }
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmitUserForm}>
            <p className="form-title"> Sign up with a new account</p>
            <RegionSelection />
            <div className="user-inputs">
                <Input type="text" label="Username" value={username} onChange={(e) => handleUserInputChange(e, "username")} autoComplete="new-username" />
            </div>
            <div className="user-inputs">
                <Input type="text" label="Email" value={email} onChange={(e) => handleUserInputChange(e, "email")} />
            </div>
            <div className="user-inputs">
                <Input type="password" label="Password" value={password} onChange={(e) => handleUserInputChange(e, "password")} autoComplete="new-password" />
            </div>
            <div className="user-inputs">
                <Input type="password" label="Confirm password" value={confirmPassword} onChange={(e) => handleUserInputChange(e, "confirmPassword")} autoComplete="new-password" />
            </div>
            <div className="login-signup-button">
                <Button
                    type="submit"
                    label={<>Sign up {isLoading ? <Spinner size="small" /> : null}</>}
                    variant="brand"
                    disabled={isLoading || !username || !password || !confirmPassword || !email || !user.region}
                    style={{ width: "100%" }}
                />
            </div>
            <nav className="login-signup-link">
                Already have an account? <Link to="/login">Sign in</Link>
            </nav>
        </form>
    );
};

export default UserForm;
