import Icon from "@salesforce/design-system-react/components/icon";

import "./HeaderProfileCustomContent.css";
import useAuthContext from "../../../context/useAuthContext";

const HeaderProfileCustomContent = ({ onEditProfile, avatar }) => {
    const { user, handleLogout, userDetails } = useAuthContext();
    const region = process.env[`REACT_APP_COGNITO_REGION_LABEL_${user?.region}`];
    const regionNumberCount = parseInt(process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT, 10);

    return (
        <div id="header-profile-custom-popover-content">
            <div className="slds-m-around_medium">
                <div className="slds-tile slds-tile_board slds-m-horizontal_small">
                    <div className="user-container">
                        <div className="avatar">{avatar}</div>
                        <div className="user-info">
                            <p className="tile__title slds-text-heading_small slds-truncate user-name" title={user?.username}>
                                {user?.username}
                            </p>
                            <h2 className="slds-tree__item-label slds-truncate user-email" title={user?.email}>
                                {user?.email}
                            </h2>
                        </div>
                    </div>
                    <div
                        className="slds-tile__detail" // style={{ marginLeft: 17, marginTop: 5 }}
                    >
                        <p className="logout">
                            {/* <a className="slds-m-right_medium" href="">
                Settings
              </a> */}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLogout();
                                }}
                            >
                                Log Out
                            </a>
                        </p>
                    </div>

                    {regionNumberCount > 1 && (
                        <div className="region-wrapper">
                            <div className="region-badge" title={region}>
                                <img className="img-region" src="/assets/images/global-header/cloud.svg" alt="location" />

                                <p className="slds-tile__detail slds-truncate" title={"Instance: " + region}>
                                    Instance: {region}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="border-element" />

                <div className="header-profile-custom-content">
                    <div className="header-profile-custom-content-row" onClick={() => onEditProfile()}>
                        <div className="header-profile-custom-content-item-icon">
                            <Icon assistiveText={{ label: "edit" }} category="utility" colorVariant="default" name="user" size="x-small" />
                        </div>
                        My Profile
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HeaderProfileCustomContent;
