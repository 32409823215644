import { KeyType, MapType } from "../../../types";
import Record from "../../../helpers/recordLayer";
import { OptionItem } from "./types";

type Mappings = { [bucket: string]: (string | null)[] }[];

export function getOptionsMap(useLabels: boolean, valuesOptions: OptionItem[] = [], selectedValues: string[] = []): Mappings {
    const optionsMap: Record<string, OptionItem> = valuesOptions.reduce(
        (obj, item) => {
            obj[item.value] = item;
            return obj;
        },
        {} as Record<string, OptionItem>
    );

    let mappings: Mappings = [];
    let currentValues: (string | null)[] = [];

    selectedValues?.forEach((value) => {
        const item = optionsMap[value];

        if (!item) {
            return;
        }

        if (item.bucket) {
            // Start a new bucket
            currentValues = [];
            mappings.push({ [item.bucket]: currentValues });
        } else {
            // Add value or label to the current bucket
            if (useLabels) {
                currentValues.push(item.label);
            } else {
                currentValues.push(item.value === "POINT SIGMA EMPTY VALUE" ? null : item.value || "");
            }
        }
    });

    return mappings;
}

export const mapJson = (
    record: MapType,
    useInput: string,
    freeTextValues: string,
    minDate: string,
    maxDate: string,
    minDateAmount: string | number,
    maxDateAmount: string | number,
    minNumber: string | number,
    maxNumber: string | number,
    minDateUnit: string | number,
    maxDateUnit: string | number,
    scope: string,
    selectedKey: MapType | KeyType,
    selectedValues: string[],
    valuesOptions: OptionItem[]
) => {
    let updatedRecord = record;
    let settings = { map: [] as Mappings, values: "", min: "" as string | number, max: "" as string | number };

    // parse input fields to settings
    if (useInput === "MultiSelect") {
        const mappings = getOptionsMap(false, valuesOptions, selectedValues);

        settings.map = mappings && mappings.length ? mappings : undefined;
    } else if (useInput === "FreeText") {
        const newValues = Record.parseCSV(freeTextValues);
        if (newValues) {
            settings.values = newValues;
        }
    } else if (useInput === "BetweenDateTime") {
        if (minDate) {
            settings.min = minDate;
        }
        if (maxDate) {
            settings.max = maxDate;
        }
    } else if (useInput === "RelativeDateTime") {
        let minAmount = minDateAmount;
        minAmount = minAmount || minAmount === 0 ? String(minAmount) : "";
        let maxAmount = maxDateAmount;
        maxAmount = maxAmount || maxAmount === 0 ? String(maxAmount) : "";
        const minUnit = minDateUnit;
        const maxUnit = maxDateUnit;
        settings.min = minAmount && minUnit ? minAmount + " " + minUnit : null;
        settings.max = maxAmount && maxUnit ? maxAmount + " " + maxUnit : null;
    } else if (useInput === "BetweenNumber") {
        settings.min = minNumber != null && minNumber !== "" ? parseFloat(minNumber.toString()).toString() : "";
        settings.max = maxNumber != null && maxNumber !== "" ? parseFloat(maxNumber.toString()).toString() : "";
    }
    // create / update map settings
    // include the selected Key with the Map, so that other components (e.g., MapSet) can read its values without having to query the API again

    const key = selectedKey as KeyType;
    const container = key.container;

    updatedRecord = {
        ...updatedRecord,
        scope,
        containerId: container.id,
        container,
        keyId: key.id,
        key,
        settings,
    };

    return updatedRecord;
};

export const selectedValueLabel = (valuesOptions: OptionItem[], value: string) => {
    const selectedOption = valuesOptions.find((option) => option.value === value);

    if (selectedOption) {
        if (selectedOption.value === "POINT SIGMA EMPTY VALUE") {
            return "--None--";
        } else if (selectedOption.value.includes("POINT SIGMA BUCKET VALUE")) {
            // Find the specific bucket label
            const bucketOption = valuesOptions.find((option) => option.value === value && option.bucket);
            return bucketOption?.label || "Bucket";
        } else {
            return selectedOption.label || value;
        }
    }

    return value;
};
