// these all moved from recordLayer.js
// TODO: use label as object key directly, and update all code that uses constants to select the item with the relevant label directly from the object rather than use 'find' to scan through the list

const RecordConstants = {
    //Generic Active/Inactive Status than can be reused
    GENERIC_STATUS: {
        ACTIVE: { value: "Active", label: "Active" },
        INACTIVE: { value: "Inactive", label: "Inactive" },
    },

    ENVIRONMENT_STATUS: {
        ACTIVE: {
            value: "Active",
            label: "Active",
            description: "Connectors can load inventory and data",
        },
        INACTIVE: {
            value: "Inactive",
            label: "Inactive",
            description: "Connectors won't contact the Source",
        },
    },

    SOURCE_RESTRICT: {
        NONE: { value: "--None--", label: "--None--" },
        SUPPLEMENTARY: {
            value: "Supplementary",
            label: "Supplementary",
            description: "Only combine with other Sources; don't find Patterns within this Source",
        },
        ISOLATED: {
            value: "Isolated",
            label: "Isolated",
            description: "Don't combine with other Sources",
        },
    },

    CONNECTOR_STATUS: {
        ACTIVE: {
            value: "Active",
            label: "Active",
            description: "Download new data from the Source",
        },
        INACTIVE: {
            value: "Inactive",
            label: "Inactive",
            description: "Don't contact the Source, but keep data downloaded so far",
        },
    },

    RESTRICTION_STATUS: {
        ACTIVE: { value: "Active", label: "Active" },
        INACTIVE: { value: "Inactive", label: "Inactive" },
    },

    OBJECT_STATUS: {
        INCLUDED: { value: "Included", label: "Included" },
        EXCLUDED: { value: "Excluded", label: "Excluded" },
    },

    FIELD_STATUS: {
        INCLUDED: { value: "Included", label: "Included" },
        EXCLUDED: { value: "Excluded", label: "Excluded" },
    },

    DEFAULT_OBJECT_STATUS: {
        INCLUDED: {
            value: "Included",
            label: "Included",
            description: "Automatically load new Objects",
        },
        EXCLUDED: {
            value: "Excluded",
            label: "Excluded",
            description: "Let me choose which Objects to load",
        },
    },

    DEFAULT_FIELD_STATUS: {
        INCLUDED: {
            value: "Included",
            label: "Included",
            description: "Automatically load new Fields",
        },
        EXCLUDED: {
            value: "Excluded",
            label: "Excluded",
            description: "Let me choose which Fields to load",
        },
    },

    CONNECTOR_SCHEDULE: {
        NONE: { value: "--None--", label: "--None--" },
        //MINUTE: {value: 'Every Minute', label: 'Every Minute'}, // only used for testing
        HOUR: { value: "Hourly", label: "Hourly" },
        DAY: { value: "Daily", label: "Daily" },
        WEEK: { value: "Weekly", label: "Weekly" },
        MONTH: { value: "Monthly", label: "Monthly" },
    },

    FILTER_TYPES: {
        Text: [
            {
                id: "Includes",
                value: "Includes",
                label: "Is In",
                useInput: "ListValues",
                default: true,
            },
            { id: "Excludes", value: "Excludes", label: "Is Not In", useInput: "ListValues" },
            { id: "Contains", value: "Contains", label: "Contains", useInput: "FreeText" },
            { id: "Lacks", value: "Lacks", label: "Lacks", useInput: "FreeText" },
            { id: "Missing", value: "Missing", label: "Missing", useInput: "Missing" },
            { id: "NotMissing", value: "NotMissing", label: "Not Missing", useInput: "Missing" },
            { id: "PresetText", value: "PresetText", label: "Preset", useInput: "Preset", default: true },
        ],
        Number: [
            { id: "Between", value: "Between", label: "Between", useInput: "BetweenNumber", default: true },
            { id: "Missing", value: "Missing", label: "Missing", useInput: "Missing" },
            { id: "NotMissing", value: "NotMissing", label: "Not Missing", useInput: "Missing" },
            { id: "PresetNumber", value: "PresetNumber", label: "Preset", useInput: "Preset", default: true },
        ],
        DateTime: [
            { id: "PresetDateTime", value: "PresetDateTime", label: "Preset", useInput: "Preset", default: true },
            { id: "RelativeDateTime", value: "RelativeDateTime", label: "Relative", operator: "Between", useInput: "RelativeDateTime" },
            { id: "BetweenDateTime", value: "BetweenDateTime", label: "Between", operator: "Between", useInput: "BetweenDateTime" },
            { id: "Missing", value: "Missing", label: "Missing", useInput: "Missing" },
            { id: "NotMissing", value: "NotMissing", label: "Not Missing", useInput: "Missing" },
        ],
    },

    FILTER_PRESETS: {
        Text: [
            { id: "AllValues", value: "AllValues", label: "All", operator: "Includes", description: "All values are included", settings: { values: null } },
            { id: "LoggedInUserId", value: "LoggedInUserId", label: "Logged In User Id", description: "Filter by logged in user id", operator: "Includes", settings: { values: null } },
            {
                id: "LoggedInUserValue",
                value: "LoggedInUserValue",
                label: "Logged In User Value",
                description: "Filter by user field (defined in App Builder)",
                operator: "Includes",
                settings: { values: null },
            },
            {
                id: "EmbeddedRecordValue",
                value: "EmbeddedRecordValue",
                label: "Embedded Record Value",
                description: "Filter by embedded record field (defined in App Builder)",
                operator: "Includes",
                settings: { values: null },
            },
        ],
        Number: [{ id: "AllValues", value: "AllValues", label: "All", operator: "Between", settings: { min: null, max: null } }],
        DateTime: [
            { id: "AllTime", value: "AllTime", label: "All Time", operator: "Between", settings: { min: null, max: null } },
            { id: "Today", value: "Today", label: "Today", operator: "Between", settings: { min: "0 days", max: "1 days" } },
            { id: "Last7Days", value: "Last7Days", label: "Last 7 Days", operator: "Between", settings: { min: "-7 days", max: "0 days" } },
            { id: "Next7Days", value: "Next7Days", label: "Next 7 Days", operator: "Between", settings: { min: "1 days", max: "8 days" } },
            { id: "LastWeek", value: "LastWeek", label: "Last Week", operator: "Between", settings: { min: "-1 weeks", max: "0 weeks" } },
            { id: "ThisWeek", value: "ThisWeek", label: "This Week", operator: "Between", settings: { min: "0 weeks", max: "1 weeks" } },
            { id: "NextWeek", value: "NextWeek", label: "Next Week", operator: "Between", settings: { min: "1 weeks", max: "2 weeks" } },
            { id: "Last30Days", value: "Last30Days", label: "Last 30 Days", operator: "Between", settings: { min: "-30 days", max: "0 days" } },
            { id: "Next30Days", value: "Next30Days", label: "Next 30 Days", operator: "Between", settings: { min: "1 days", max: "31 days" } },
            { id: "LastMonth", value: "LastMonth", label: "Last Month", operator: "Between", settings: { min: "-1 months", max: "0 months" } },
            { id: "ThisMonth", value: "ThisMonth", label: "This Month", operator: "Between", settings: { min: "0 months", max: "1 months" } },
            { id: "NextMonth", value: "NextMonth", label: "Next Month", operator: "Between", settings: { min: "1 months", max: "2 months" } },
            { id: "Last90Days", value: "Last90Days", label: "Last 90 Days", operator: "Between", settings: { min: "-90 days", max: "0 days" } },
            { id: "Next90Days", value: "Next90Days", label: "Next 90 Days", operator: "Between", settings: { min: "1 days", max: "91 days" } },
            { id: "LastQuarter", value: "LastQuarter", label: "Last Quarter", operator: "Between", settings: { min: "-1 quarters", max: "0 quarters" } },
            { id: "ThisQuarter", value: "ThisQuarter", label: "This Quarter", operator: "Between", settings: { min: "0 quarters", max: "1 quarters" } },
            { id: "NextQuarter", value: "NextQuarter", label: "Next Quarter", operator: "Between", settings: { min: "1 quarters", max: "2 quarters" } },
            { id: "Last Year", value: "Last Year", label: "Last Year", operator: "Between", settings: { min: "-1 years", max: "0 years" } },
            { id: "This Year", value: "This Year", label: "This Year", operator: "Between", settings: { min: "0 years", max: "1 years" } },
            { id: "Next Year", value: "Next Year", label: "Next Year", operator: "Between", settings: { min: "1 years", max: "2 years" } },
        ],
    },

    USER_TYPE: {
        USER: {
            value: "User",
            label: "User",
            description: "Standard user",
        },
        ADMINISTRATOR: {
            value: "Administrator",
            label: "Administrator",
            description: "User with administrative privileges",
        },
    },

    // ---> Do not change order of this object keys and values
    ACCOUNT_SETTINGS_GENERATIVE_AI: {
        Off: {
            value: "Off",
            label: "Off",
            description: "No access allowed", // ---> (Most restricted)
            index: 0,
        },
        Metadata: {
            value: "Metadata",
            label: "Metadata",
            description: "Access to metadata only, no data access",
            index: 1,
        },
        Data: {
            value: "Data",
            label: "Data",
            description: "Access to data, but not full system control",
            index: 2,
        },
        Full: {
            value: "Full",
            label: "Full",
            description: "Full access, including data and system controls",
            index: 3,
        },
        Debug: {
            value: "Debug",
            label: "Debug",
            description: "Full access with additional debugging capabilities", // ---> (Least restricted)
            index: 4,
        },
    },

    ACCOUNT_SETTINGS_DEFAULT_COLOR_MAP: {
        EMPTY: {
            value: "",
            label: "--None--",
            description: "No color scheme selected, default settings will apply.",
        },
        PLASMA: {
            value: "plasma",
            label: "Plasma",
            description: "A vibrant color scheme with a range of vivid, neon-like colors.",
        },
        COOL: {
            value: "cool",
            label: "Cool",
            description: "A soothing palette of blues and greens, evoking a calm, cool atmosphere.",
        },
        WARM: {
            value: "warm",
            label: "Warm",
            description: "A cozy combination of warm tones, including reds, oranges, and yellows.",
        },
        BLUE_RAIN_BOW: {
            value: "blueRainbow",
            label: "Blue Rainbow",
            description: "A gradient featuring shades of blue with hints of rainbow colors for a balanced look.",
        },
    },

    ACCOUNT_SETTINGS_DATE_TIME_FORMATS: [
        { id: "", label: "--None--" },
        { id: "%c", label: "Locale date and time" }, // Locale representation of date and time
        { id: "%Y-%m-%d %H:%M:%S", label: "YYYY-MM-DD HH:MM:SS" },
        { id: "%Y-%m-%d %I:%M %p", label: "YYYY-MM-DD HH:MM AM/PM" },
        { id: "%Y/%m/%d %H:%M:%S", label: "YYYY/MM/DD HH:MM:SS" },
        { id: "%Y/%m/%d %I:%M %p", label: "YYYY/MM/DD HH:MM AM/PM" },
        { id: "%m/%d/%Y %H:%M:%S", label: "MM/DD/YYYY HH:MM:SS" },
        { id: "%m/%d/%Y %I:%M %p", label: "MM/DD/YYYY HH:MM AM/PM" },
        { id: "%d-%b-%Y %H:%M:%S", label: "DD-MMM-YYYY HH:MM:SS" },
        { id: "%d-%b-%Y %I:%M %p", label: "DD-MMM-YYYY HH:MM AM/PM" },
        { id: "%B %d, %Y %H:%M:%S", label: "Month DD, YYYY HH:MM:SS" },
        { id: "%A, %B %d, %Y %H:%M:%S", label: "Day, Month DD, YYYY HH:MM:SS" },
        { id: "%Y-%m-%d %H:%M", label: "YYYY-MM-DD HH:MM" },
        { id: "%Y-%m-%d", label: "YYYY-MM-DD" },
        { id: "%b %d, %Y", label: "Abbrev Month DD, YYYY" },
        { id: "%B %d, %Y", label: "Full Month DD, YYYY" },
    ],

    DATETIME_UNITS: [
        { id: "--None--", label: "--None--", value: "--None--" },
        { id: "seconds", label: "Seconds", value: "seconds" },
        { id: "minutes", label: "Minutes", value: "minutes" },
        { id: "hours", label: "Hours", value: "hours" },
        { id: "days", label: "Days", value: "days" },
        { id: "weeks", label: "Weeks", value: "weeks" },
        { id: "months", label: "Months", value: "months" },
        { id: "quarters", label: "Quarters", value: "quarters" },
        { id: "years", label: "Years", value: "years" },
    ],

    DATETIME_ROLLUP: [
        { value: "N", label: "Nanosecond" },
        { value: "U", label: "Microsecond" },
        { value: "L", label: "Millisecond" },
        { value: "S", label: "Second" },
        { value: "T", label: "Minute" },
        { value: "H", label: "Hour" },
        { value: "D", label: "Day" },
        { value: "W", label: "Week" },
        { value: "M", label: "Month" },
        { value: "Q", label: "Quarter" },
        { value: "Y", label: "Year" },
        { value: "10Y", label: "Decade" },
        { value: "100Y", label: "Century" },
        { value: "", label: "Automatic" },
    ],

    FILTER_SCOPES: [
        {
            id: "Pattern",
            label: "Pattern",
            value: "Pattern",
            description: "Apply to this pattern only",
        },
        {
            id: "Outliers",
            label: "Outliers",
            value: "Outliers",
            description: "Automatic outlier filter",
        },
        {
            id: "Session",
            label: "Session",
            value: "Session",
            description: "Apply within this session",
        },
        {
            id: "Dashboard",
            label: "Dashboard",
            value: "Dashboard",
            description: "Apply within this dashboard",
        },
        {
            id: "Project",
            label: "Project",
            value: "Project",
            description: "Apply within this project",
        },
        { id: "Global", label: "Global", value: "Global", description: "Apply to all patterns" },
    ],

    MAP_SCOPES: [
        {
            label: "Pattern",
            value: "Pattern",
            description: "Apply to this pattern only",
        },
        {
            label: "Session",
            value: "Session",
            description: "Apply within this session",
        },
        {
            label: "Dashboard",
            value: "Dashboard",
            description: "Apply within this dashboard",
        },
        {
            label: "Project",
            value: "Project",
            description: "Apply within this project",
        },
        { label: "Global", value: "Global", description: "Apply to all patterns" },
    ],

    SILENT_API_ERRORS: ["NotConnected", "NoSearchMatch", "EmptyPlotError", "PlotError", "Timeout"],

    // IMPROVMENT: instead of defining this here separately, use the 'name' attributes in NavigationTree specification
    TERMINOLOGY: {
        source: "Source",
        container: "Object",
        key: "Field",
        dataType: "Data Type",
        dataRole: "Data Role",
        filter: "Filter",
        link: "Join",
        chain: "Path",
        agg: "Aggregation",
        transform: "Transformation",
        folder: "Folder",
    },
};
export default RecordConstants;
