import { Input } from "@salesforce/design-system-react";

import EditButtonIcon from "./EditButtonIcon";
import ProgressBarComponent from "./ProgressBarComponent";
import PsRecord from "../components/ps-record/PsRecord";
import UndoButtonIcon from "./UndoButtonIcon";

const NameInputWithUndoIcon = ({ cmpState, cmp }) => {
    return (
        <div className="slds-form__item" role="listitem">
            <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                        <span className="slds-form-element__label">Name</span>
                        <div className="slds-form-element__control">
                            <div className="slds-form-element__static">{cmpState.record?.name}</div>
                            <div className="slds-button slds-button__icon slds-button__icon_hint">
                                <EditButtonIcon handleEdit={() => cmp.handleEdit(cmp)} />
                            </div>
                        </div>
                    </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                    <div verticalalign="start" style={{ display: "flex" }}>
                        <div flexibility="auto" style={{ flexibility: "auto", width: "100%" }}>
                            <Input
                                id="checkField"
                                name="name"
                                autoComplete="off"
                                label="Name"
                                required={true}
                                value={cmpState.record?.name}
                                onChange={(e) => PsRecord.handleNameChange(cmp, e.target.value)}
                                errorText={cmp.get("missingRequiredFields")?.includes("record.name") ? "Complete this field." : ""}
                            />
                        </div>

                        {cmpState.record?.hasOverride?.name && <UndoButtonIcon handleRevert={cmp.handleRevert} field="name" />}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NameInputWithUndoIcon;
