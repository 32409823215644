import { useState } from "react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants";

const PsSourceList = ({ parentId, queryFilter, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("table");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const parseResponse = (response) => {
        return response.map(({ id, name, status }) => ({ id, name, status }));
    };

    return (
        <PsRecordGrid
            module="core"
            object="source"
            queryFilter={queryFilter}
            showTitle={true}
            tagLine="Manage the data sources that Point Sigma analyzes."
            emptyLine="Start by creating a new Source"
            recordLabel="Source"
            recordLabelPlural="Sources"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore={true}
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            showCreate={true}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
        />
    );
};

export default PsSourceList;
