import { createContext, useContext, useEffect, useState } from "react";
import Record from "../helpers/recordLayer";
import useAuthContext from "./useAuthContext";
import { ACCOUNT_SETUP } from "../constants";
import RecordConstants from "../constants/RecordConstants";
const { STATUS_PROGRESS_LIST } = ACCOUNT_SETUP;

// Type AccountType = null|{
//   id: string,
//   name: string,
//   status: string,
//   setupStatus: string,
//   settings: {
//     generativeAI: string,
//     enableGenerativeAIExplain: boolean,
//     enableGenerativeAISearch: boolean,
//   },
//   limits: {
//     deferUpdatesSeconds: number,
//     maxNumTables: number,
//     maxStorageBytes: number,
//     maxNumPatterns: number,
//     generativeAI: string,
//   },
//   usage: {
//     computeCredit: number,
//     totalStorageBytes: number,
//   },
//   createdOn: string,
//   createdBy: {
//     id: string,
//     name: string,
//   },
//   lastModifiedOn: string,
//   lastModifiedBy: {
//     id: string,
//     name: string,
//   },
// };

const AccountSettingsContext = createContext(null);

const useAccountSettingsContextProvider = () => {
    const module = "core";
    const object = "account";
    const [account, setAccount] = useState(null);
    const [accountSetupProgress, setAccountSetupProgress] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { isAuthenticated, handleLogout } = useAuthContext();

    async function fetchAccount() {
        setIsLoading(true);
        try {
            const accountRes = await getAccountFromBackend();
            setAccount(accountRes);
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            setAccount(null);
        } finally {
            setIsLoading(false);
        }
    }

    async function getAccountFromBackend() {
        return new Promise((resolve, reject) => {
            try {
                function onSuccess(response) {
                    if (response?.length > 0) {
                        const record = response[0];
                        resolve(record);
                    } else {
                        reject(new Error("No account found"));
                    }
                }
                function onError(response) {
                    reject(new Error("Failed to retrieve account"));
                }
                Record.getRecords(module, object, {}, onSuccess, onError);
            } catch (err) {
                console.error(err);
                reject(new Error("Unexpected error occurred while fetching account"));
            }
        });
    }

    function hasGenerativeAIAccess(requested) {
        const levels = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
        const requestedIndex = requested?.index == null ? -1 : requested.index;
        const accountLimitIndex = levels[account?.limits?.generativeAI]?.index;
        const accountSettingsIndex = levels[account?.settings?.generativeAI]?.index;
        const allowAccountLimits = requestedIndex <= (accountLimitIndex || 0);
        const allowAccountSettings = accountSettingsIndex == null || requestedIndex <= accountSettingsIndex;
        return allowAccountLimits && allowAccountSettings;
    }

    useEffect(() => {
        if (!account) {
            return;
        }
        const accountSetupProgressError = STATUS_PROGRESS_LIST[0];
        const accountSetupStatus = account.setupStatus || "Authenticated";
        const newProgress = STATUS_PROGRESS_LIST.find((item) => item.setupStatus === accountSetupStatus) || accountSetupProgressError;
        setAccountSetupProgress(newProgress);
    }, [account]);

    useEffect(() => {
        // update account and accountSetupProgress every time isAuthenticated true and has been changed
        if (!!isAuthenticated) fetchAccount();
    }, [isAuthenticated]);

    return {
        account,
        isLoading,
        accountSetupProgress,
        refetchAccount: fetchAccount,
        hasGenerativeAIAccess,
    };
};

export const AccountSettingsContextProvider = (props) => {
    const value = useAccountSettingsContextProvider();
    return <AccountSettingsContext.Provider value={value}>{props.children}</AccountSettingsContext.Provider>;
};

const useAccountSettingsContext = () => {
    const context = useContext(AccountSettingsContext);
    if (!context) throw new Error("You can't use useAccountSettingsContext() outside of <AccountSettingsContextProvider />");
    return context;
};

export default useAccountSettingsContext;
