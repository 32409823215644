import "./FormWrapper.css";
import { Button, Spinner } from "@salesforce/design-system-react";

const FormWrapper = ({
    // Required
    children,
    // Required
    title,
    desc,
    isEdit,
    isEditable = true,
    disableAllBtn,
    loading,
    hideDelete,
    onEdit = () => {
        return;
    },
    onSave = () => {
        return;
    },
    onCancel = () => {
        return;
    },
    onDelete = () => {
        return;
    },
    // e is form Submit events type
    onSubmit = (e) => {
        e.preventDefault();
        return;
    },
}) => {
    return (
        <div className="form-wrapper-container">
            <div className="form-title-wrapper">
                <div className="form-tl card-main-title-lh32">{title}</div>
                <div>{!isEdit && isEditable && <Button disabled={disableAllBtn} title={"Edit this " + title} label="Edit" onClick={onEdit} />}</div>
            </div>
            {
                // use form-m-description on form body to make same styling with main description
                <div className="form-m-description">{!!desc && <>{desc}</>}</div>
            }
            <form onSubmit={onSubmit}>
                {children}
                {!!isEdit && isEditable && (
                    <>
                        <div className="form-btn-br-top" />
                        <div className="form-btn-wrapper">
                            {!hideDelete && <Button label="Delete" title={"Delete this " + title} onClick={onDelete} variant="text-destructive" disabled={disableAllBtn} />}
                            <Button label="Cancel" title={"Cancel " + title} onClick={onCancel} disabled={disableAllBtn} />
                            <Button label="Save" title={"Save this " + title} onClick={onSave} variant="brand" disabled={disableAllBtn} />
                        </div>
                    </>
                )}
            </form>
            {!!loading && <Spinner assistiveText={{ label: "Loading" }} />}
        </div>
    );
};

export default FormWrapper;
