import { ProgressBar } from "@salesforce/design-system-react";

const ProgressBarComponent = () => {
    return (
        <>
            <span className="slds-form-element__label">&nbsp;</span>
            <div className="slds-form-element__static">
                <ProgressBar value={0} variant="circular" />
            </div>
        </>
    );
};

export default ProgressBarComponent;
