import { Combobox, Input } from "@salesforce/design-system-react";
import React, { useState } from "react";

import RecordConstants from "../../constants/RecordConstants";
import PsRecord2 from "../ps-record/PsRecord2";
import { addIds } from "../../utils";
import { RestrictOption, StatusOption, SourceType, Mode, PsRecordPropsType, SourceApiType } from "../../types";
import Field from "../../ui/wrappers/Field";
import { getSafeInputValue, checkRequiredField } from "../../utils/index2";

const statusOptions: StatusOption[] = addIds(Object.values(RecordConstants.ENVIRONMENT_STATUS));
const restrictOptions: RestrictOption[] = addIds(Object.values(RecordConstants.SOURCE_RESTRICT));
const recordObject = "source";

const PsSource2: React.FC<PsRecordPropsType> = ({ recordId, parentId, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<SourceType>();
    const [restrictSelection, setRestrictSelection] = useState<RestrictOption[]>([restrictOptions[0]]);
    const [statusSelection, setStatusSelection] = useState<RestrictOption[]>([statusOptions[0]]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [localParentId, setLocalParentId] = useState<string>(parentId || "");

    const parseResponse = (response: SourceApiType[]): SourceType[] => {
        return response.map((item) => ({
            id: item.id,
            name: item.name,
            status: item.status,
            restrict: item.restrict,
        }));
    };

    // This overrides the default PsRecord function
    const parseInputPlainText = (updatedRecord: SourceType) => {
        return updatedRecord.id
            ? (({ id, name, status }) => ({ id, name, status }))(updatedRecord) // select fields that can be updated
            : (({ name, status, restrict }) => ({ name, status, restrict: restrict === "--None--" ? "" : restrict }))(updatedRecord); // select fields that can be created
    };

    // move field??
    const handleSelectStatus = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) return;
        setRecord((prev) => ({ ...prev, status: data.selection[0].value }));
        setStatusSelection(data.selection);
    };

    const handleSelectRestrict = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) return;
        setRecord((prev) => ({ ...prev, restrict: data.selection[0].value }));
        setRestrictSelection(data.selection);
    };

    function setParent() {
        setLocalParentId("source");
    }

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-m-top_medium">Source Details</h3>

            <div className="slds-form__row">
                {/* Name */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    value={record?.name}
                    label="Name"
                    onEdit={onEdit}
                    isEditable={true}
                    fieldName="name"
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={<Input name="name" autoComplete="off" label="Name" required={true} value={getSafeInputValue(record?.name)} errorText={fieldErrors?.name} />}
                />
                {/* Status */}
                <Field
                    mode={mode}
                    value={record?.status}
                    label="Status"
                    onEdit={onEdit}
                    isEditable={true}
                    body={
                        <Combobox
                            events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleSelectStatus(event, data) }}
                            labels={{ label: "Status", placeholder: "--Please Select--" }}
                            menuPosition="relative"
                            options={statusOptions}
                            selection={statusSelection}
                            value={record?.status || ""}
                            variant="readonly"
                        />
                    }
                />
            </div>
            <div className="slds-form__row">
                {/* Restrict */}
                <Field
                    mode={mode}
                    value={record?.restrict}
                    label="Restrict"
                    showStaticViewInEditMode={true}
                    body={
                        <Combobox
                            events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleSelectRestrict(event, data) }}
                            labels={{ label: "Restrict", placeholder: "--Please Select--" }}
                            options={restrictOptions}
                            selection={restrictSelection}
                            variant="readonly"
                        />
                    }
                />
            </div>
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Source"
            recordModule="core"
            recordObject={recordObject}
            record={record}
            showEdit={true}
            showDelete={true}
            mode={mode}
            recordId={recordId}
            parentId={localParentId}
            parentToChildEvent={parentToChildEvent}
            fieldErrors={fieldErrors}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseInputPlainText={parseInputPlainText}
            setParent={setParent}
            cardBody={cardBody}
        />
    );
};

export default PsSource2;
