import RecordConstants from "../constants/RecordConstants";
import Record from "../helpers/recordLayer";
import useToastContext from "../context/useToastContext";

// TODO this record-specific code, and should be moved to the relevant component or be its own component
const Restriction = {
    //addToast: useToastContext.addToast,

    // update lists of Restriction IDs and Labels
    update: function (cmp, record, restrictions) {
        // restrictions = restrictions || [];
        // var values = [], labels = [], required = [];
        // var restrictionTypeId, restrictionTypeName;
        // for (var restriction of restrictions) {
        //     restrictionTypeId = restriction.restrictionType.id;
        //     restrictionTypeName = restriction.restrictionType.name;
        //     if(restriction.status != Record.RESTRICTION_STATUS.INACTIVE.value) {
        //         // add if not inactive
        //         values.push(restrictionTypeId);
        //         labels.push(restrictionTypeName);
        //         // make required if there is a system origin
        //         if ((restriction.allOrigins || []).some(x => x != 'User')) {
        //             required.push(restrictionTypeId);
        //         }
        //     }
        // }
        // Object.assign(record, {restrictions: restrictions, restrictionValues: values, restrictionLabels: labels.join(', '), restrictionRequired: required});
        // cmp.set('v.record', record);

        restrictions = restrictions || [];
        var values = [],
            labels = [],
            required = [];
        var restrictionTypeId, restrictionTypeName;
        for (var restriction of restrictions) {
            restrictionTypeId = restriction.restrictionType.id;
            restrictionTypeName = restriction.restrictionType.name;
            if (restriction.status !== RecordConstants.RESTRICTION_STATUS.INACTIVE.value) {
                // add if not inactive
                values.push(restrictionTypeId);
                labels.push(restrictionTypeName);
                // make required if there is a system origin
                if ((restriction.allOrigins || []).some((x) => x !== "User")) {
                    required.push(restrictionTypeId);
                }
            }
        }
        Object.assign(record, {
            restrictions: restrictions,
            restrictionValues: values,
            restrictionLabels: labels.join(", "),
            restrictionRequired: required,
        });
        cmp.set("record", record);
    },

    // merge selected restrictions with existing restriction Objects
    // IMPROVEMENT: update/create multiple restrictions in single API call (not currently available in SF)
    merge: function (cmp, whatId, record) {
        // var values = record.restrictionValues || [];
        // var restrictions = record.restrictions || [];
        // var restrictionMap = restrictions.reduce((obj, item) => { obj[item.restrictionType.id] = item; return obj; }, {} );
        // var restrictionTypes = cmp.get('v.restrictionOptions') || [];
        // var restrictionTypeMap = restrictionTypes.reduce((obj, item) => { obj[item.id] = item; return obj; }, {} );
        // var restriction, data, restrictionType;
        // var changed = false;
        // // set removed restrictions to Inactive
        // for (var value in restrictionMap) {
        //     if (!values.includes(value)) {
        //         changed = true;
        //         restriction = restrictionMap[value];
        //         restriction['status'] = Record.RESTRICTION_STATUS.INACTIVE.value;
        //         data = ( ({ id, status }) => ({ id, status }))(restriction);
        //         Record.submitRecord(cmp, this, 'pump', 'restriction', data);
        //     }
        // }
        // // added restrictions are either re-activated or created (if not yet exists)
        // for (var value of values) {
        //     restriction = restrictionMap[value];
        //     if (!restriction) {
        //         changed = true;
        //         restrictionType = restrictionTypeMap[value];
        //         restriction = {restrictionTypeId: restrictionType.id, restrictionType: restrictionType, name: restrictionType.name, status: Record.RESTRICTION_STATUS.ACTIVE.value};
        //         restriction[whatId] = record.id; // 'whatId' = objectId or fieldId
        //         Record.createRecord(cmp, this, 'pump', 'restriction', restriction);
        //         restrictions.push(restriction);
        //         restrictionMap[restrictionType.id] = restriction;
        //     } else if (restriction.status != Record.RESTRICTION_STATUS.ACTIVE.value) {
        //         changed = true;
        //         restriction['status'] = Record.RESTRICTION_STATUS.ACTIVE.value;
        //         data = ( ({ id, status }) => ({ id, status }))(restriction);
        //         Record.submitRecord(cmp, this, 'pump', 'restriction', data);
        //     }
        // }
        // // save updated restrictions
        // if (changed) {
        //     this.update(cmp, record, restrictions);
        // }

        var values = record.restrictionValues || [];
        var restrictions = record.restrictions || [];
        var restrictionMap = restrictions.reduce((obj, item) => {
            obj[item.restrictionType.id] = item;
            return obj;
        }, {});
        var restrictionTypes = cmp.get("restrictionOptions") || [];
        var restrictionTypeMap = restrictionTypes.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
        }, {});
        var restriction, data, restrictionType;
        var changed = false;
        // set removed restrictions to Inactive
        for (var value in restrictionMap) {
            if (!values.includes(value)) {
                changed = true;
                restriction = restrictionMap[value];
                restriction["status"] = RecordConstants.RESTRICTION_STATUS.INACTIVE.value;
                data = (({ id, status }) => ({ id, status }))(restriction);
                Record.submitRecord("pump", "restriction", data);
            }
        }
        // added restrictions are either re-activated or created (if not yet exists)
        for (var value of values) {
            restriction = restrictionMap[value];
            if (!restriction) {
                changed = true;
                restrictionType = restrictionTypeMap[value];
                restriction = {
                    restrictionTypeId: restrictionType.id,
                    restrictionType: restrictionType,
                    name: restrictionType.name,
                    status: RecordConstants.RESTRICTION_STATUS.ACTIVE.value,
                };
                restriction[whatId] = record.id; // 'whatId' = objectId or fieldId

                // Record.createRecord("pump", "restriction", restriction);
                Record.submitRecord("pump", "restriction", restriction);

                restrictions.push(restriction);
                restrictionMap[restrictionType.id] = restriction;
            } else if (restriction.status !== RecordConstants.RESTRICTION_STATUS.ACTIVE.value) {
                changed = true;
                restriction["status"] = RecordConstants.RESTRICTION_STATUS.ACTIVE.value;
                data = (({ id, status }) => ({ id, status }))(restriction);
                Record.submitRecord("pump", "restriction", data);
            }
        }
        // save updated restrictions
        if (changed) {
            this.update(cmp, record, restrictions);
        }
    },

    loadRestrictionTypes: function (cmp) {
        const onSuccess = function (response) {
            response.forEach((rt) => {
                rt.label = rt.name;
                rt.value = rt.id;
            });
            cmp.set("restrictionOptions", response);
            if (!response.length) {
                //this.addToast("error", "No Restriction Types Found", "Please contact Point Sigma Support");
            }
            const record = cmp.get("record");
            // picklist items post-processsing in case this runs after loading the record
            Record.selectLoadedOptions(cmp.get("restrictionOptions"), record.restrictionValues, record.restrictionLabels);
        };

        const onError = function (response) {
            //this.addToast("error", "Error loading Restriction Types", response);
        };

        Record.getRecords("core", "restrictiontype", {}, onSuccess, onError);
    },
};

export default Restriction;
