import { useEffect, useRef, useState, useMemo } from "react";
import PsUserList from "../../../components/ps-user-list/PsUserList";
import PsUser from "../../../components/ps-user/PsUser";
import Record from "../../../helpers/recordLayer.js";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import SetupPanel from "../setup-panel/SetupPanel.js";
import useAuthContext from "../../../context/useAuthContext.js";
import { PAGE_ROUTES } from "../../../constants";
import useToastContext from "../../../context/useToastContext";

const Users = () => {
    const navigate = useNavigate();
    const [searchParams, _setSearchParams] = useSearchParams();
    const { userDetails, handleLogout } = useAuthContext();

    const memoizedObject = useMemo(() => ({}), []);

    const { addToast } = useToastContext();

    const [cmpState, setCmpState] = useState({
        selectedObject: "users", // selectedObject kay handle what component to show in the main use container
        parentToChildEvent: {},
        parentId: "",
        userId: "",
        usersId: "",
        selectedItem: "overview_users_usersList", // hardcoded for now, since we only have one option
    });

    const cmpWorking = useRef({});
    const isFirstRender = useRef(true);

    useEffect(() => {
        try {
            cmpWorking.current = { ...cmpState };
            cmp.init();
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }
            cmp.onPageReferenceChange();
        } catch (err) {
            console.error(err);
        }
    }, [searchParams]);

    const cmp = {
        get: (key) => {
            return cmpWorking.current[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        init: function () {
            cmp.parsePageRef();
        },

        handleNavigationEvent: function (event) {
            var source = event.source;

            // navigate to different tab
            var tab = event.tab;
            if (tab) {
                navigate("/" + tab);
                return;
            }

            // if no object is provided (e.g., when navigating to parentId)
            var id = event.id;
            var object = event.obj;
            if (id && !object) {
                //var name = cmpNavigationTree.current.getNameFromId(id) || Record.nameFromDetails('overview', 'connectors', 'connectorList');
                var name = Record.nameFromDetails("setup", "users", "userList");
                cmp.navigateFromName(null, name);
                return;
            }

            // navigate to specified record
            if (["record", "grid", "tree"].includes(source)) {
                cmp.navigateFromEvent(event);
            }
        },

        navigateFromEvent: function (event) {
            // if id is empty (i.e., when creating a new record), set the parent
            var id = event.id;
            var parentId = id ? undefined : event.parentId;

            // navigate
            const defaultSections = { root: "setup", user: "setup", users: "setup" };
            let item = Record.itemFromEvent(event, defaultSections);

            // navigate
            cmp.navigateFromName(parentId, item.name);
        },

        navigateFromName: function (parentId, name) {
            var selectedObject = cmp.get("selectedObject");
            var selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
            var selectedParentId = cmp.get("parentId");
            var parsed = Record.parseName(name);
            var selected = searchParams.get("selected");

            if (parsed.id !== selectedId || parsed.config !== selectedObject || parentId !== selectedParentId || selected !== name) {
                let search = "?selected=" + name; // TODO: use proper URL construction methods here and throughout the code
                if (parentId) {
                    search = search + "__parent=" + parentId;
                }

                navigate({ pathname: PAGE_ROUTES.SETUP.USERS.path, search });
            }
        },

        onPageReferenceChange: function () {
            try {
                cmp.parsePageRef();
            } catch (err) {
                console.error(err.stack);
            }
        },

        parsePageRef: function () {
            let defaultSelected = Record.nameFromDetails("setup", "users", "userList");
            let selected = searchParams.get("selected") || defaultSelected;
            selected = selected === "__" ? defaultSelected : selected;

            var parsed = Record.parseName(selected);
            var config = parsed.config;
            var id = parsed.id;

            // each config has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
            var selectedIdName = config + "Id";
            cmp.set(selectedIdName, id);

            const parentId = selected.split("parent=")[1]; // TODO: NAVIGATION: use proper url parameter
            if (parentId !== cmp.get("parentId")) {
                cmp.set("parentId", parentId);
            }

            // set the config to show _after_ all its settings have been set on the component, to prevent reloading in afterscript, as well as due to a change in filter
            cmp.set("selectedObject", config);

            // update the tree
            cmp.set("selectedItem", selected);
        },
    };

    const dispatchEvent = (event) => {
        cmp.set("parentToChildEvent", event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (event.type === "navigation") {
            stopPropagation = true;
            cmp.handleNavigationEvent(event);
        } else if (event.type === "logout") {
            stopPropagation = true;
            handleLogout();
        }

        if (!stopPropagation) {
            dispatchEvent(event);
        }
    };

    return (
        <SetupPanel childToParent={bubbleEvent}>
            <>
                {cmpState.selectedObject === "users" &&
                    userDetails?.isAdmin && ( // Check if is not admin user do not render this component above it redirect the user in the home page
                        <PsUserList
                            parentId={cmpState.usersId}
                            queryFilter={memoizedObject}
                            view="table"
                            maxRecords="12"
                            childToParent={bubbleEvent}
                            parentCmp={cmp}
                            parentToChildEvent={cmpState.parentToChildEvent}
                        />
                    )}
                {cmpState.selectedObject === "user" && (
                    <div>
                        <PsUser parentId={cmpState.parentId} recordId={cmpState.userId} childToParent={bubbleEvent} parentCmp={cmp} parentToChildEvent={cmpState.parentToChildEvent} />
                    </div>
                )}
            </>
        </SetupPanel>
    );
};

export default Users;
