import React from "react";
import { BrowserRouter } from "react-router-dom";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";

import "./App.css";
import "./index.css";
import Router from "./router/Router";
import PsErrorBoundary from "./components/ps-error-boundary/PsErrorBoundary";
import { AuthContextProvider } from "./context/useAuthContext";
import { AccountSettingsContextProvider } from "./context/useAccountSettingsContext";
import { ToastContextProvider } from "./context/useToastContext";
import ToastComponent2 from "./components/toast-component/ToastComponent2";
import { GlobalStateContextProvider } from "../src/context/useGlobalStateContext";

const App = () => {
    return (
        <PsErrorBoundary>
            <ToastContextProvider>
                <AuthContextProvider>
                    <AccountSettingsContextProvider>
                        <BrowserRouter>
                            <IconSettings iconPath="/assets/icons">
                                <GlobalStateContextProvider>
                                    <Router />
                                    <ToastComponent2 />
                                </GlobalStateContextProvider>
                            </IconSettings>
                        </BrowserRouter>
                    </AccountSettingsContextProvider>
                </AuthContextProvider>
            </ToastContextProvider>
        </PsErrorBoundary>
    );
};

export default App;
