import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { Auth } from "aws-amplify";

import { GENERAL_ERROR, PASSWORD_MISMATCH } from "../../../constants/ToastMessages";
import "../../Pages.css";
import useAuthContext from "../../../context/useAuthContext";
import useToastContext from "../../../context/useToastContext";

const ResetForm = ({ confirmationCode, setConfirmationCode, setMode }) => {
    const { user, setUser } = useAuthContext();
    const { addToast } = useToastContext();

    const [isLoading, setIsLoading] = useState(false);

    const { username, password, confirmPassword } = user;

    const handleUserInputChange = (e, item) => {
        setUser((prevUser) => ({ ...prevUser, [item]: e.target.value })); //why do we have user here and above???
    };

    //needs to be separate because this is not on the user object
    const handleConfirmationCodeChange = (e) => {
        setConfirmationCode(e.target.value);
    };

    const handleSubmitResetForm = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true);

            if (password !== confirmPassword) {
                addToast("warning", "Password Mismatch", PASSWORD_MISMATCH);
                return;
            }

            var result = await Auth.forgotPasswordSubmit(username, confirmationCode, password); //this just returns success
            if (result === "SUCCESS") {
                addToast("success", "Successfully Reset Password", "Please sign in with your new password.");
                setMode("login");
            } else {
                addToast("error", "Error", "Failed to change password.");
            }
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmitResetForm}>
            <p className="form-title">We have sent a password reset code by email. Enter it below to reset your password</p>

            <div className="user-inputs">
                <Input type="text" label="Username" value={username} onChange={(e) => handleUserInputChange(e, "username")} />
            </div>
            <div className="user-inputs">
                <Input type="tel" label="Code" value={confirmationCode} onChange={(e) => handleConfirmationCodeChange(e)} />
            </div>
            <div className="user-inputs">
                <Input type="password" label="New password" value={password} onChange={(e) => handleUserInputChange(e, "password")} />
            </div>
            <div className="user-inputs">
                <Input type="password" label="Confirm new password" value={confirmPassword} onChange={(e) => handleUserInputChange(e, "confirmPassword")} />
            </div>
            <div className="login-signup-button">
                <Button
                    type="submit"
                    label={<>Change Password {isLoading ? <Spinner size="small" /> : null}</>}
                    variant="brand"
                    disabled={isLoading || !username || !password || !confirmPassword || !confirmationCode}
                    className="login-submit-button"
                />
            </div>
        </form>
    );
};

export default ResetForm;
