import Record from "../../../../helpers/recordLayer";

export async function getKeyOptions(inputSlot) {
    try {
        const dataType = inputSlot.key?.dataType || {};
        const params = {
            containerId: inputSlot.key.container?.id,
            orderBy: "name ASC",
        };
        if (dataType.format === "DateTime") {
            params.dataFormatId = dataType.dataFormat.id;
        } else if (dataType.role === "Metric" && dataType.name !== "Count") {
            params.dataRoleId = dataType.dataRole.id;
        } else {
            params.dataTypeId = dataType?.id;
        }
        const suppressCount = dataType.role === "Metric" && dataType.name !== "Count";

        const response = await new Promise((resolve, reject) => {
            Record.getRecords(
                "store",
                "key",
                params,
                (res) => resolve(res),
                (err) => reject(err)
            );
        });

        if (!response || !response.length) {
            return [];
        } else {
            // Filter out any "Count" items if suppressCount is true
            if (suppressCount) {
                return response.filter((v) => v.dataType.name !== "Count");
            }
            return response.map(({ id, container, dataType, name }) => ({
                id,
                container,
                dataType,
                name,
            }));
        }
    } catch (err) {
        console.error("err-getKeyOptions:", err);
        return [];
    }
}

export async function getChainDetails(id) {
    // Helper function to get chain options using async/await
    const getChainOptions = (leftContainerId, rightContainerId) => {
        return new Promise((resolve, reject) => {
            function onSuccess(response) {
                if (!response || !response.length) {
                    resolve([]); // Resolve with an empty array if no response
                } else {
                    const filteredResponse = response.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }));
                    resolve(filteredResponse);
                }
            }

            function onError(error) {
                reject(error); // Reject the promise with the error
            }

            if (leftContainerId && rightContainerId) {
                Record.getRecords("store", "chain", { leftContainerId, rightContainerId, orderBy: "name ASC" }, onSuccess, onError);
            } else {
                resolve([]); // Resolve with an empty array if IDs are not provided
            }
        });
    };

    // Main function to get chain details
    return new Promise((resolve, reject) => {
        function onSuccess(response) {
            const record = response[0];
            const leftContainerId = record.leftContainer.id;
            const rightContainerId = record.rightContainer.id;

            getChainOptions(leftContainerId, rightContainerId)
                .then((options) => {
                    resolve(options); // Resolve the promise with the options
                })
                .catch((error) => {
                    reject(error); // Reject the promise if there's an error
                });
        }

        function onError(error) {
            reject(error); // Reject the promise with the error
        }

        Record.getRecord("store", "chain", id, {}, "", "GET", onSuccess, onError);
    });
}

// This function flattens an object with nested arrays into a single array of references.
// Note: This function does NOT create a deep copy of the inputSlot object.
// It maintains references to the original objects within the array,
// allowing the original inputSlot to be updated directly by modifying the flattened array items.
// The function extracts items based on the specified key (attrib) and adds them to a flat array.
export function flattenWithIndex(inputSlot, attrib) {
    const flat = [];

    const flattenHelper = (items, attrib) => {
        items.forEach((item) => {
            // Push the original item reference to the flat array
            flat.push(item);

            // Check if the attribute is an array and recurse if necessary
            if (Array.isArray(item?.[attrib])) {
                flattenHelper(item[attrib], attrib);
            }
        });
    };

    // Start the flattening process
    flattenHelper(inputSlot?.[attrib] || [], attrib);
    return flat;
}
