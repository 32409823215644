import { useCallback, useEffect, useRef, useState } from "react";
import "./DropBoxBar.css";
import { Card } from "@salesforce/design-system-react";
import DropBox from "./DropBox.js";
import { aggregationsMap, boxes, transformMap } from "./constants.js";
import { arraysWithEqualValues, createConstraintValue, createQueryArray, fetchLabelTitle, findInKey } from "./helpers.js";
import FilterDropbox from "./filter/FilterDropbox.js";

const DropBoxBar = ({ updateUrlParamsConstraint, updateConstaints, grabbedItemFromMenu }) => {
    const [boxData, setBoxData] = useState(boxes);
    const [loading, setLoading] = useState(false);
    const [pendingUrlUpdate, setPendingUrlUpdate] = useState(null); // Track pending URL update
    const [grabbedItem, setGrabbedItem] = useState(null);

    useEffect(() => {
        if (!updateConstaints) {
            setBoxData(boxes); // set drop box state to empty
            return;
        }
        const { flt, ...rest } = updateConstaints; // remove flt from updateConstaints because flt is deferent box
        initializeBoxData(rest);
    }, [updateConstaints]);

    useEffect(() => {
        if (!grabbedItemFromMenu && !grabbedItem) return;
        if (!grabbedItemFromMenu) {
            setGrabbedItem(null);
            return;
        }
        setGrabbedItem({ item: grabbedItemFromMenu, origin: "menu" });
    }, [grabbedItemFromMenu]);

    useEffect(() => {
        if (pendingUrlUpdate?.length > 0) {
            const updatedConstraints = pendingUrlUpdate.map(({ slot, values }) => {
                const value = createConstraintValue(values); // Create the constraint for each item
                return { key: slot, value }; // Return the object for updating the url Param
            });
            updateUrlParamsConstraint(updatedConstraints);
            setPendingUrlUpdate(null);
        }
    }, [boxData, pendingUrlUpdate]);

    const isDropValid = useCallback(
        (grabbedItem, slot) => {
            if (!grabbedItem) return true;
            let config = "";
            if (grabbedItem?.origin === "menu") {
                config = grabbedItem?.item?.setting?.config;
            }
            if (grabbedItem?.origin === "box") {
                config = grabbedItem?.item?.config;
            }
            if ((slot === "xst" || slot === "xnd") && config === "agg") return false;
            if (slot === "acr" && config === "agg" && grabbedItem.item.id !== "min" && grabbedItem.item.id !== "max") return false;
            return true;
        },
        [grabbedItem]
    );

    // TODO: this all looks way overcomplicated. We can store whatever the cosntraints are as JSON + whatever extra information we need in a separate object.
    // For each {'[recordName]': {'[fieldName]': {'in': ['[uuid1]', '[uuid2]']}}}, we map the uuid (or whatever value) to a label, for example {'uuid1': 'label1', 'uuid2': 'label2'}
    // The label can be taken from the dropEvent, which has all the relevant details in it.
    // We then only need to read the labels for records that don't have one yet from the API, which should only happen when the page is loaded directly from the URL.
    async function initializeBoxData(constraint) {
        try {
            // Convert constraint data from url to box values
            const boxDataFromUrl = Object.keys(constraint).map((key) => {
                const boxObj = constraint[key];
                const values = [];
                Object.keys(boxObj).forEach((nestedKey) => {
                    let config = nestedKey;
                    // Defining config from the url constraint
                    if (nestedKey === "node") config = "transform"; //the first structure key is not same with config
                    if (nestedKey === "aggregate") config = "agg"; //the first structure key is not same with config
                    if (nestedKey === "dataType" && !!boxObj[nestedKey]?.name) config = "dataType"; //the first structure key is not same with config
                    if (nestedKey === "dataType" && !!boxObj[nestedKey]?.format) config = "dataFormat"; //the first structure key is not same with config
                    if (nestedKey === "dataType" && !!boxObj[nestedKey]?.role) config = "dataRole"; //the first structure key is not same with config

                    const idArray = findInKey(boxObj[nestedKey]);
                    if (!!idArray?.length) {
                        idArray.forEach((id) => values.push({ id, config }));
                    }
                });
                return {
                    slot: key,
                    values,
                };
            });

            // check if the boxData are same with url parameter constrain.
            if (
                arraysWithEqualValues(
                    boxDataFromUrl,
                    boxData.filter((d) => !!d.values.length)
                )
            )
                return;

            setLoading(true); // Start loading

            // Define unique values (config and Id) to make one request to fetch (name and title for all values)
            const uniqueValues = Array.from(
                boxDataFromUrl.reduce((acc, item) => {
                    item.values.forEach((obj) => {
                        // Create a unique string key based on obj
                        const key = JSON.stringify(obj);
                        acc.add(key);
                    });
                    return acc;
                }, new Set())
            ).map((key) => JSON.parse(key));

            // Fetch ----> Name, Title for all values from constraint
            const dataWithLabelTitle = await fetchLabelTitle(
                createQueryArray(uniqueValues) // create query if are many ids with same endpoint
            );

            // Initialize Drop box values form constraint
            const initializeBoxes = boxes.map((box) => {
                const findValues = boxDataFromUrl.find((boxUrl) => box.slot === boxUrl.slot);
                if (!findValues) return box;
                let valuesUrl = [];
                findValues?.values.forEach((valueFromUrl) => {
                    if (valueFromUrl.config === "agg") {
                        const findAggName = aggregationsMap.find((agg) => agg.id === valueFromUrl.id);
                        valuesUrl.push({
                            ...valueFromUrl,
                            label: findAggName?.label || "",
                            title: findAggName?.label || "",
                        });
                    } else if (valueFromUrl.config === "transform") {
                        const findTranName = transformMap.find((agg) => agg.id === valueFromUrl.id);
                        valuesUrl.push({
                            ...valueFromUrl,
                            label: findTranName?.label || "",
                            title: findTranName?.label || "",
                        });
                    } else {
                        const findLabelTitle = dataWithLabelTitle?.find((dLabelTitle) => valueFromUrl.id === dLabelTitle.id);
                        valuesUrl.push({
                            ...valueFromUrl,
                            label: findLabelTitle?.label || "",
                            title: findLabelTitle?.title || "",
                        });
                    }
                });
                return { ...box, values: valuesUrl };
            });
            setBoxData(initializeBoxes);
        } catch (error) {
            console.error(error);
            console.error(error.stack);
        } finally {
            setLoading(false);
        }
    }

    function onUpdateBoxValues(updateArray) {
        setBoxData((prev) => {
            const updatedData = updateArray.reduce((acc, { values, slot }) => {
                return acc.map((box) => {
                    if (box.slot === slot) {
                        return { ...box, values };
                    }
                    return box;
                });
            }, prev);

            return updatedData;
        });
        setPendingUrlUpdate(updateArray);
    }
    return (
        <div className="build-drop-box-container">
            <Card className="PsRecordGrid slds-scrollable card-drop-box" heading="">
                <div className="box-content">
                    {boxData.map((box, i) => {
                        return <DropBox key={i} data={box} setGrabbedItem={setGrabbedItem} onUpdateValues={onUpdateBoxValues} loading={loading} dropAllowed={isDropValid(grabbedItem, box.slot)} />;
                    })}
                    <FilterDropbox
                        dropAllowed={!grabbedItem || (grabbedItem?.origin === "menu" && grabbedItem?.item?.setting?.config === "key")}
                        isItemGrabbed={!!grabbedItem}
                        onFilterChange={updateUrlParamsConstraint}
                        flt={updateConstaints?.flt || null}
                    />
                </div>
            </Card>
        </div>
    );
};

export default DropBoxBar;
