import { Link } from "react-router-dom";

function NotFound() {
    return (
        <div className="slds-text-align_center" style={{ marginTop: "300px" }}>
            <p className="slds-text-heading_medium">Page Not Found!</p>
            <p style={{ margin: "20px 20px" }}>Sorry, the page you are looking for does not exist.</p>
            <Link to="/">Go to Home Page</Link>
        </div>
    );
}

export default NotFound;
