const EditButtonIcon = ({ handleEdit }) => {
    return (
        <button className="slds-button slds-button_icon" title="Edit" onClick={(e) => handleEdit(e)}>
            <svg className="slds-button__icon" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit"></use>
            </svg>
            <span className="slds-assistive-text">Edit</span>
        </button>
    );
};

export default EditButtonIcon;
