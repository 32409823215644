export const RECORD_COLUMNS = [
    {
        key: "name",
        label: "Name",
        minWidth: 200,
        type: "link",
        property: "name",
        action: "details",
        href: "/StoreManagement?selected=chains_chain_{id}",
    },
    {
        key: "custom",
        label: "Custom",
        width: 80,
        property: "custom",
        type: "boolean",
    },
    {
        label: "Relationship",
        property: "relationship",
        width: 110,
        key: "relationship",
        type: "text",
    },
    {
        key: "leftContainerName",
        label: "From",
        width: 200,
        property: "leftContainerName",
        type: "text",
        sortable: true,
    },
    {
        key: "rightContainerName",
        label: "To",
        width: 200,
        property: "rightContainerName",
        type: "text",
        sortable: true,
    },
    {
        key: "relevance",
        label: "Relevance",
        width: 120,
        property: "relevance",
        type: "percent",
        sortable: true,
    },
];
