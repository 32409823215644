export const RECORD_COLUMNS = [
    {
        key: "question",
        label: "Suggestion",
        property: "questionPlain",
        type: "link",
        href: "/Explore?selected=types_dataRole_${dataRoleId}~data_key_${keyId}", // eslint-disable-line no-template-curly-in-string
        action: "question",
        title: "Select",
        minWidth: 200,
    },
    {
        key: "keyName",
        label: "Field",
        property: "keyName",
        type: "link",
        action: "key",
        title: "Select",
        width: 200,
    },
    {
        key: "dataRoleName",
        label: "Data Role",
        property: "dataRoleName",
        type: "link",
        action: "dataRole",
        title: "Select",
        width: 200,
    },
];
