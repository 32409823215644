import { useEffect, useRef, useState } from "react";
import { Spinner, Tooltip, Textarea } from "@salesforce/design-system-react";
import Icon from "@salesforce/design-system-react/components/icon";
import Button from "@salesforce/design-system-react/components/button";

import "../PsPatternChart.css";
import EditButtonIcon from "../../../ui/EditButtonIcon"; // TODO: use lightning design utility icon
import CustomPopover from "../../../ui/custom-popover/CustomPopover";
import useAccountSettingsContext from "../../../context/useAccountSettingsContext.js";
import RecordConstants from "../../../constants/RecordConstants.js";
import Record from "../../../helpers/recordLayer";

const maxDescCharacter = 1024;
const Description = ({ pattern, data, onPatternChange }) => {
    const { account, hasGenerativeAIAccess } = useAccountSettingsContext();
    const [generativeAIData, setGenerativeAIData] = useState(false);
    const [generativeAIDebug, setGenerativeAIDebug] = useState(false);
    const [showSection, setShowSection] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [description, setDescription] = useState("");
    const [debugPrompt, setDebugPrompt] = useState("");
    const [loading, setLoading] = useState(false);

    const inputRef = useRef({});
    const popoverRef = useRef({});

    useEffect(() => {
        const { Data: DATA, Debug: DEBUG } = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
        const genAiDataAccess = hasGenerativeAIAccess(DATA);
        const genAiEnabled = account?.settings?.enableGenerativeAIExplain;
        setGenerativeAIData(genAiDataAccess && genAiEnabled);
        setGenerativeAIDebug(hasGenerativeAIAccess(DEBUG));
    }, [account]);

    useEffect(() => {
        setDescription(pattern?.description);
    }, [pattern]);

    const handleExplain = () => {
        if (!data) {
            setDescription("No data.");
            return;
        }

        var onSuccess = function (response) {
            const explainContent = response[0];
            setDescription(explainContent);
            setLoading(false);
        };

        var onError = function (response) {
            console.error(response);
            setDescription("Error while processing.");
            setLoading(false);
        };

        setLoading(true);
        const body = { data, plotOptions: pattern?.options };
        if (generativeAIDebug && debugPrompt) {
            body.debug = true;
            body.prompt = debugPrompt;
        }
        var bodyString = JSON.stringify(body);
        Record.getRecord("present", "explain", "", {}, bodyString, "PUT", onSuccess, onError);
    };

    const handleRevertDescription = () => {
        // TODO: use new Field component overridden functionality
        const truncated = (pattern?.original?.description || "").slice(0, maxDescCharacter);
        setDescription(truncated);
        setIsEdit(false);
        updatePattern(truncated);
    };

    const handleCancelDescriptionEdit = (e) => {
        setDescription(pattern?.description);
        setIsEdit(false);
    };

    const handleSavePatternDescription = () => {
        if (description?.length > maxDescCharacter) {
            return;
        }
        setIsEdit(false);
        updatePattern(description);
    };

    const updatePattern = (description) => {
        // TODO: use new Field component overridden functionality
        const updatedPattern = { ...pattern, description, overridden: { ...pattern?.overridden, description: pattern?.description } };
        onPatternChange(updatedPattern, false);
    };

    const showGenerativeAI = data && generativeAIData;

    const popoverContent = (
        <>
            {generativeAIDebug && (
                <div className="slds-m-bottom_x-small">
                    <Textarea
                        className="slds-textarea" //
                        value={debugPrompt || ""}
                        placeholder="Enter an LLM prompt"
                        onChange={(e) => setDebugPrompt(e.target.value)}
                    />
                </div>
            )}

            {/* {TODO: use new Field component for this} */}
            <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
                <Textarea
                    className="slds-textarea"
                    value={description || ""}
                    placeholder={"Enter a description" + (showGenerativeAI ? " or explain with AI" : "")}
                    onChange={(e) => setDescription(e.target.value)}
                    inputRef={(input) => {
                        if (input && !inputRef.current) {
                            inputRef.current = input;
                            input.focus();
                        }
                    }}
                />
                {description && (
                    <button
                        className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
                        title="Clear"
                        onClick={() => setDescription("")}
                        style={{
                            top: 15,
                        }}
                    >
                        <svg className="slds-button__icon slds-icon-text-light" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
                        </svg>
                        <span className="slds-assistive-text">Clear</span>
                    </button>
                )}
            </div>
            {description?.length > maxDescCharacter && ( // TODO: use input checking validation function for this from new Field Component
                <div className="slds-form-element slds-has-error">
                    <div className="slds-form-element__help" id="error-01">
                        Description cannot be longer than {maxDescCharacter} characters.
                    </div>
                </div>
            )}
        </>
    );

    const generativeAIButton = showGenerativeAI && (
        <Tooltip
            id="tooltip"
            align="bottom"
            content={<div className="slds-p-around_xx-small">This description will be generated by an AI with limited context and may not correctly reflect your data or apply to your siutation.</div>}
        >
            <Button iconCategory="utility" iconName="magicwand" iconPosition="left" variant="outline-brand" label="Explain with AI" title="Explain with AI" onClick={(e) => handleExplain(e)} />
        </Tooltip>
    );

    return (
        pattern?.inputs && (
            <div className="slds-m-horizontal_x-small" style={{ flexBasis: "300px", flexGrow: 1, minWidth: "300px" }}>
                <div className={`slds-section ${showSection ? "slds-is-open" : "slds-is-close"}`}>
                    <h3>
                        <button className="slds-button slds-section__title-action" onClick={() => setShowSection(!showSection)}>
                            <span>
                                <Icon assistiveText={{ label: "switch section" }} category="utility" name="switch" size="x-small" className="slds-section__title-action-icon slds-button__icon_left" />
                            </span>
                            <span>Description</span>
                        </button>
                    </h3>

                    {/* {TODO: use the new Field component with overriden functionality} */}
                    <div className="slds-section__content slds-is-relative">
                        {loading && <Spinner assistiveText={{ label: "Loading" }} />}

                        {isEdit ? (
                            <CustomPopover
                                popoverRef={popoverRef}
                                title=""
                                content={popoverContent}
                                onSave={handleSavePatternDescription}
                                onCancel={handleCancelDescriptionEdit}
                                hasRevertButton={pattern?.hasOverride?.description}
                                onRevert={handleRevertDescription}
                                extraButtons={generativeAIButton}
                                inline={false}
                                onClickOutside={() => setIsEdit(false)}
                            />
                        ) : (
                            <>
                                <span className={pattern?.description ? "" : "slds-text-color_weak"} onClick={(e) => setIsEdit(true)}>
                                    {pattern?.description ? pattern?.description : "Enter a description..."}
                                </span>

                                <span className="slds-m-left_xx-small">
                                    <EditButtonIcon handleEdit={(e) => setIsEdit(true)} />
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default Description;
