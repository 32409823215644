import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Button from "@salesforce/design-system-react/components/button";
import { Spinner } from "@salesforce/design-system-react";

import "./Explore.css";
import Record from "../../helpers/recordLayer.js";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import NoActiveFilters from "./components/NoActiveFilters";
import RecordItem from "./components/RecordItem";
import SearchBox from "../../components/ps-search-box/SearchBox.js"; //This shouldn't be Explore sub component
import PsSuggestionList from "../../components/ps-suggestion-list/PsSuggestionList";
import PsCompositionGrid from "../../components/ps-composition-grid/PsCompositionGrid";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext.js";
import useToastContext from "../../context/useToastContext";

function Explore() {
    const [cmpState, setCmpState] = useState({
        view: "list",
        selectedNames: [],
        selectedRecords: [],
        searchText: "",
        navigationLoading: true,
        listFilter: {},
        pattern: "",
        isFindPatternsLoading: false,
        parentToChildEvent: {},
    });

    const { handleLogout } = useAuthContext();

    // global toast
    const { addToast } = useToastContext();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [applyNow, setApplyNow] = useState(true);

    const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);
    const leftRef = useRef(null);
    const searchdivRef = useRef(null);

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        onPageReferenceChange();
    }, [searchParams]); // runs on init and when search params are updated

    const cmp = {
        get: (key) => {
            return cmpWorking.current[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },
    };

    const onPageReferenceChange = () => {
        parsePageRef();
    };

    // navigate from event
    const handleNavigationEvent = (event) => {
        var source = event.source;

        // navigate to different tab
        var tab = event.tab;
        if (tab) {
            navigate("/" + tab);
            return;
        }

        // sync records
        var selectedRecords = cmp.get("selectedRecords") || [];
        selectedRecords = syncItem(selectedRecords, event);
        cmp.set("selectedRecords", selectedRecords);

        // scroll only
        if (["change", "closeSearch"].includes(source)) {
            var scroll = event.scroll;
            var scroller = leftRef.current;
            var searchInput = searchdivRef.current; // document.getElementById("searchdiv");
            // update scroll position after rendering, so that rendered sizes are available
            if (searchInput && scroller && scroll != null) {
                var timer = setTimeout(() => {
                    var top = searchInput.offsetTop + searchInput.offsetHeight;
                    scroller.scrollTop = scroll * (scroller.scrollHeight - top);
                }, 0);
            }
        }

        // navigate
        if (["record", "grid", "tree"].includes(source)) {
            var selectedNames = cmp.get("selectedNames") || [];
            var oldNames = selectedNames.join("~");
            var newNames = selectedRecords.map((item) => item.name).join("~");
            if (newNames !== oldNames || cmpState.view === "detail") {
                navigateSelected(newNames);
            }
        }
    };

    const handleReset = () => {
        cmp.set("selectedRecords", []);
        navigateSelected("");
    };

    const handleRemoveItem = (event) => {
        var index = event.target.id;
        var selectedRecords = cmp.get("selectedRecords") || [];
        if (index < selectedRecords.length) {
            selectedRecords = [...selectedRecords]; // prevent modifying the original
            selectedRecords.splice(index, 1);
            cmp.set("selectedRecords", selectedRecords);

            var newNames = selectedRecords.map((item) => item.name).join("~");
            navigateSelected(newNames);
        }
    };

    const handleFind = () => {
        setCmpState((prev) => ({ ...prev, isFindPatternsLoading: true }));
        actionFind();
    };

    const handleSearchTextChange = (value) => {
        cmp.set("searchText", value);
    };

    const handleDataCompEvent = (event) => {
        if (event.action === "viewDetails") {
            var pattern = event.pattern;
            var patternId = pattern?.id;
            var currentId = cmpState.pattern?.id;
            var currentView = cmpState.view;
            cmp.set("pattern", pattern);

            if (patternId) {
                if (patternId !== currentId || currentView !== "detail") {
                    navigatePattern(patternId);
                }
            } else {
                // change view, but don't navigate if no patternId available
                cmp.set("view", "detail");
            }
        } else if (event.action === "apply") {
            dispatchEvent(event);
        } else if (event.action === "close") {
            navigatePattern(undefined);
        }
    };

    const dispatchEvent = (event) => {
        cmp.set("parentToChildEvent", event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;
        if (event.type === "navigation") {
            stopPropagation = true;
            handleNavigationEvent(event);
        } else if (event.type === "dataCompEvent") {
            stopPropagation = true;
            handleDataCompEvent(event);
        } else if (event.type === "reload") {
            cmp.set("navigationLoading", true);
        } else if (event.type === "logout") {
            stopPropagation = true;
            handleLogout();
        }

        if (!stopPropagation) {
            dispatchEvent(event);
        }
    };

    // updates component navigation items from query parameters
    const parsePageRef = () => {
        var selected = (searchParams.get("selected") || "").split("~");
        var selectedNames = [];
        var listFilter = {};
        selected.forEach((name) => {
            if (name) {
                selectedNames.push(name);
                var parsed = Record.parseName(name);
                if (parsed.config !== "root") {
                    listFilter[parsed.config + "Id"] = parsed.id;
                }
            }
        });

        // determine view, but first set the component's filters and selected IDs before changing the view, so that that components are rendered with the correct filters, and don't need to reload after init with the newly set filters
        var patternId = searchParams.get("pattern");
        var view = patternId ? "detail" : "list";

        // update filters depending on the view
        if (view === "list") {
            if (JSON.stringify(listFilter) !== JSON.stringify(cmp.get("listFilter"))) {
                cmp.set("listFilter", listFilter);
            }
        } else if (view === "detail") {
            var currentId = cmp.get("pattern")?.id;
            if (patternId !== currentId) {
                cmp.set("pattern", { id: patternId });
            }
        }

        // update tree with selection
        cmp.set("selectedNames", selectedNames);

        // set right-side panel view mode
        cmp.set("view", view);
    };

    const navigateSelected = (names) => {
        navigate({ pathname: "/Explore", search: "?selected=" + names });
    };

    const navigatePattern = (patternId) => {
        if (!patternId) {
            searchParams.delete("pattern");
            setSearchParams(searchParams);
        } else {
            searchParams.set("pattern", patternId);
            setSearchParams(searchParams);
        }
    };

    const syncItem = (selected, event) => {
        // default sections used when navigating from an external component
        var sections = ["types", "data"];
        var defaultSections = { root: "types", dataRole: "types", dataType: "types", source: "data", container: "data", key: "data" };

        // create item
        var item = Record.itemFromEvent(event, defaultSections);

        // don't update if the item is already selected, and the new event has isLoading
        // the item would be stuck in loading, because no navigation is done when selecting an already selected item
        if (!item.hasDetails && selected.find((i) => i.name === item.name)) {
            return selected;
        }

        // update / insert / delete new item
        selected = selected.filter((i) => i.section !== item.section);
        if (item.id) {
            selected.push(item);
        }

        // sort in order of the sections
        var sectionMap = selected.reduce((obj, i) => {
            obj[i.section] = i;
            return obj;
        }, {});
        return sections.map((i) => sectionMap[i]).filter((i) => i != null);
    };

    const actionFind = () => {
        var queryFilter = cmp.get("listFilter");
        var onSuccess = function () {
            addToast("info", "Searching more Patterns", "This may take a few mintues");
            setCmpState((prev) => ({ ...prev, isFindPatternsLoading: false }));
        };

        var onError = function (response) {
            setCmpState((prev) => ({ ...prev, isFindPatternsLoading: false }));
        };

        Record.doAction("relate", "composition", "run.select", queryFilter, onSuccess, onError);
    };

    const isDetailView = cmpState.view === "detail";

    return (
        <div className="Explore">
            <PsNavigationHeader childToParent={bubbleEvent} loading={cmpState.loading} showClose={isDetailView} showApplyNow={isDetailView} applyNow={applyNow} setApplyNow={setApplyNow} />
            <div className="tab-content slds-p-around_medium">
                {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
                <div className={cmpState.view === "list" ? "left slds-m-right_medium" : "slds-hide"}>
                    {/* <!-- navigation tree --> */}
                    <article
                        className="slds-card" // style={{ maxWidth: "420px" }}
                    >
                        <div style={{ height: "11rem" }}>
                            <div
                                className="slds-card__header"
                                style={{
                                    marginLeft: "-5px",
                                }}
                            >
                                <h2 className=" card-main-title-lh32 slds-card__header-title slds-text-heading_small">Refine Results</h2>
                            </div>
                            {cmpState.selectedRecords.length > 0 ? (
                                <>
                                    <div className="slds-box slds-box_xx-small slds-m-horizontal_x-small" style={{ marginTop: "-5px" }}>
                                        <div className="slds-p-horizontal_x-small" style={{ display: "flex", flexWrap: "wrap" }}>
                                            {cmpState.selectedRecords.map((record, index) => (
                                                <RecordItem key={index} record={record} index={index} handleRemoveItem={handleRemoveItem} maxWidth="20rem" />
                                            ))}
                                        </div>
                                    </div>
                                    <div
                                        className="slds-grid slds-grid_align-end slds-m-around_x-small"
                                        style={{
                                            marginBottom: "-1px",
                                        }}
                                    >
                                        <div className="slds-col slds-p-left_x-small">
                                            <Button label="Reset" title="Reset" onClick={handleReset} />
                                        </div>
                                        <div className="slds-col slds-p-left_x-small">
                                            <Button
                                                label={
                                                    <div>
                                                        Find Patterns
                                                        {cmpState.isFindPatternsLoading && <Spinner size="x-small" variant="brand" />}
                                                    </div>
                                                }
                                                title="Find more Patterns"
                                                onClick={handleFind}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <NoActiveFilters />
                            )}
                            <div ref={searchdivRef} className="slds-p-around_x-small">
                                <SearchBox searchText={cmpState.searchText} loading={cmpState.navigationLoading} handleSearchTextChange={handleSearchTextChange} />
                            </div>
                        </div>

                        <div
                            ref={leftRef}
                            className="slds-p-horizontal_x-small"
                            style={{
                                overflowY: "auto",
                                height: "calc(100vh - 18.9rem)",
                            }}
                        >
                            <PsNavigationTree
                                multiSelect={true}
                                sections={["types", "data"]}
                                selected={cmpState.selectedNames}
                                searchText={cmpState.searchText}
                                setLoading={(value) => cmp.set("navigationLoading", value)}
                                parentCmp={cmp}
                                ref={cmpNavigationTree}
                                childToParent={bubbleEvent}
                                parentToChildEvent={cmpState.parentToChildEvent}
                            />
                        </div>
                    </article>
                </div>
                {/* <!-- right -->
                <!-- uses slds-hide instead of conditional rendering to prevent reloading setupStatus and also to keep the scroll position when going back from details to list view --> */}
                <div className={cmpState.view === "list" ? "right" : "slds-hide"}>
                    <PsSetupStatus title="Explore" tagLine="Explore discovered patterns using simple navigation." />

                    {/* <c:SuggestionList view="grid" tagLine="Refine results by selecting one or more filters on the left. Some suggestions:"/> */}
                    {(!cmpState.listFilter || Object.keys(cmpState.listFilter).length === 0) && cmpState.view !== "detail" && (
                        <PsSuggestionList
                            view="grid"
                            tagLine="Refine results by selecting one or more filters on the left. Some suggestions:"
                            childToParent={bubbleEvent}
                            queryFilter={cmpState.listFilter}
                            parentToChildEvent={cmpState.parentToChildEvent}
                            maxRecords="12"
                            parentCmp={cmp}
                        />
                    )}

                    {cmpState.listFilter && Object.keys(cmpState.listFilter).length !== 0 && (
                        <PsCompositionGrid view="grid" queryFilter={cmpState.listFilter} maxRecords="12" childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} parentCmp={cmp} />
                    )}
                </div>

                {/* <!-- pattern detail --> */}
                {isDetailView && <PsPatternDetailedView pattern={cmpState.pattern} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} parentCmp={cmp} applyNow={applyNow} />}
            </div>
        </div>
    );
}

export default Explore;
