import { useEffect, useRef, useState } from "react";
import { Card, IconSettings } from "@salesforce/design-system-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./Build.css";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import SearchBox from "../../components/ps-search-box/SearchBox";
import Record from "../../helpers/recordLayer";
import PsSearchGrid from "../../components/ps-search-grid/PsSearchGrid";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import Divider from "./components/Divider";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";
import DropBoxBar from "./components/drop-box-bar/DropBoxBar";
import { isValidJSON } from "./components/drop-box-bar/helpers";

const Build = () => {
    const { handleLogout } = useAuthContext();

    const [cmpState, setCmpState] = useState({
        view: "build",
        queryFilter: { version: 1 },
        pattern: null,
        showBuildResult: false,

        searchText: "",
        navigationTreeExpanded: true,
        searchLoading: false,
        parentToChildEvent: {},

        sectionOptions: [
            { label: "Data", value: "data" },
            { label: "Types", value: "types" },
            { label: "Aggregations", value: "aggs" },
            { label: "Transformations", value: "transforms" },
            { label: "Paths", value: "chains" },
        ],
        selectedSection: "data",
        selectedObject: "sources",
        selectedId: null,
        selectedItem: {},
        sourceSharedId: null,
        containerSharedId: null,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [applyNow, setApplyNow] = useState(true);
    const [updateConstaints, setUpdateConstraints] = useState(null);
    const [grabbedItem, setGrabbedItem] = useState(null);
    const navigate = useNavigate();
    const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);
    const navigationTreeRef = useRef(null);
    const searchdivRef = useRef(null);

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        cmp.init();
    }, []);

    useEffect(() => {
        parsePageRef();
    }, [searchParams]);

    const cmp = {
        get: (key) => {
            return cmpWorking.current[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        onDragEnd: () => {
            setGrabbedItem(null);
            return;
        },

        onDragStart: function (e, item) {
            const section = item.setting.section;
            const config = item.setting.config;
            // TODO: instead configure whether an item is draggable in the navigation tree directly, e.g., isDraggable; also the drag icon should not show when something is not draggable
            if (section === "chains" && ["container", "source"].includes(config)) {
                e.preventDefault();
                return;
            }

            const dragImage = document.createElement("span");
            dragImage.textContent = item.label;
            dragImage.style.position = "absolute";
            dragImage.style.top = "-9999px";
            dragImage.style.padding = "2px 4px 2px 16px";
            dragImage.style.backgroundColor = "#f5f5f5";
            dragImage.style.border = "1px solid #ccc";
            dragImage.style.borderRadius = "3px";
            dragImage.style.minWidth = "200px";
            document.body.appendChild(dragImage);
            e.dataTransfer.setDragImage(dragImage, 0, 0);
            setTimeout(() => {
                document.body.removeChild(dragImage);
            }, 0);
            setGrabbedItem(item);
            e.dataTransfer.setData("item", JSON.stringify(item));
        },

        init: function () {},
    };

    function updateUrlParamsConstraint(updatesArray) {
        // TODO: why does this need to loop through updates, can't you just send the entire constraints and overwrite the url?
        const constraintJson = searchParams.get("constraint");
        let constraint = constraintJson ? JSON.parse(constraintJson) : {};

        updatesArray.forEach(({ key, value }) => {
            // No constraint exists and no value is provided, nothing to update
            if (Object.keys(constraint).length === 0 && !value) return;

            // If no constraint exists but value is provided, create a new constraint
            if (Object.keys(constraint).length === 0 && value) {
                constraint = { [key]: value };
            } else if (value) {
                // If value is provided, update or add the constraint
                constraint[key] = value;
            } else {
                // If no value provided, remove the key from the constraint
                delete constraint[key];
            }
        });

        // Update the URL based on whether the constraint is empty
        if (Object.keys(constraint).length > 0) {
            searchParams.set("constraint", JSON.stringify(constraint));
        } else {
            searchParams.delete("constraint");
        }

        setSearchParams(searchParams);
    }

    const parsePageRef = async () => {
        // navigation tree
        let defaultSelected = Record.nameFromDetails("data", "sources");
        let selected = searchParams.get("selected") || defaultSelected;
        selected = selected === "__" ? defaultSelected : selected;
        var parsed = Record.parseName(selected);

        cmp.set("selectedSection", parsed.section || "data");
        cmp.set("selectedObject", parsed.config);
        cmp.set("selectedItem", selected);

        // view
        const patternId = searchParams.get("pattern");
        var pattern = cmp.get("pattern");
        var showBuildResult = patternId === "result";
        var hasBuildPattern = pattern && !pattern.id;
        var view = patternId && (!showBuildResult || hasBuildPattern) ? "detail" : "build";

        if (view === "build") {
            // constraints
            const constraintJson = searchParams.get("constraint");
            if (!constraintJson) {
                setUpdateConstraints(null);
            } else if (!isValidJSON(constraintJson)) {
                searchParams.delete("constraint");
                setSearchParams(searchParams);
            } else {
                const constraint = JSON.parse(constraintJson);
                setUpdateConstraints(constraint);
            }

            if (applyNow) {
                updateQueryFilter();
            }
        } else if (view === "detail") {
            // pattern
            if (patternId !== "result") {
                var currentId = cmp.get("pattern")?.id;
                if (patternId !== currentId) {
                    cmp.set("pattern", { id: patternId });
                }
            }
        }

        cmp.set("showBuildResult", showBuildResult && hasBuildPattern);
        cmp.set("view", view);
    };

    const navigateFromName = (name) => {
        if (searchParams.get("selected") !== name) {
            searchParams.set("selected", name);
            navigate({ pathname: "/Build", search: searchParams.toString(), replace: true });
        }
    };

    const handleSelectFilter = (filterValue) => {
        let selectedId;
        if (["data", "chains"].includes(filterValue)) {
            var selectedObject = cmp.get("selectedObject");
            if (!["source", "container"].includes(selectedObject)) {
                selectedObject = "container";
            }
            selectedId = selectedObject ? cmp.get(selectedObject + "SharedId") : null;
        }

        let defaultSelected = Record.nameFromDetails(filterValue);
        var name = selectedId ? Record.nameFromDetails(filterValue, selectedObject, selectedId) : defaultSelected;

        navigateFromName(name);
    };

    const handleNavigationEvent = (event) => {
        var source = event.source;

        // navigate to different tab
        var tab = event.tab;
        if (tab) {
            navigate("/" + tab);
            return;
        }

        // scroll only
        if (["change", "closeSearch"].includes(source)) {
            var scroll = event.scroll;
            var scroller = navigationTreeRef.current; // TODO pass navigationtreeref and searchdivRef to navigation tree, then we can keep this inside navigation tree and we don't have to scroll in each component separately
            var searchInput = searchdivRef.current;
            // update scroll position after rendering, so that rendered sizes are available
            if (searchInput && scroller && scroll != null) {
                var timer = setTimeout(() => {
                    var top = searchInput.offsetTop + searchInput.offsetHeight;
                    scroller.scrollTop = scroll * (scroller.scrollHeight - top);
                }, 0);
            }
        }

        // sourceId and containerId are needed for filter selection change
        if (source === "change") {
            cmp.set("sourceSharedId", event.obj === "source" ? event.id : event.obj === "container" ? event.parentId : null);
            cmp.set("containerSharedId", event.obj === "container" ? event.id : !["sources", "source", "container"].includes(event.obj) ? event.parentId : null);
        }

        // navigate to specified record
        if (["record", "grid", "tree"].includes(source)) {
            var item = Record.itemFromEvent(event, {});
            navigateFromName(item.name);
        }
    };

    const navigatePattern = (patternId) => {
        if (patternId) {
            searchParams.set("pattern", patternId);
        } else if (searchParams.get("pattern")) {
            searchParams.delete("pattern");
        }
        setSearchParams(searchParams);
    };

    const handleDataCompEvent = (event) => {
        if (event.action === "viewDetails") {
            var pattern = event.pattern;
            var patternId = pattern?.id;
            var currentId = cmp.get("pattern")?.id;
            cmp.set("pattern", pattern);
            if (patternId) {
                if (patternId !== currentId || cmp.get("view") !== "detail") {
                    navigatePattern(patternId);
                }
            } else if (!cmp.get("showBuildResult")) {
                navigatePattern("result");
            }
        } else if (event.action === "apply") {
            if (cmp.get("view") === "detail") {
                dispatchEvent(event);
            } else {
                updateQueryFilter(true);
            }
        } else if (event.action === "close") {
            navigatePattern(undefined);
        }
    };

    const dispatchEvent = (event) => {
        cmp.set("parentToChildEvent", event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (event.type === "navigation") {
            stopPropagation = true;
            handleNavigationEvent(event);
        } else if (event.type === "dataCompEvent") {
            stopPropagation = true;
            handleDataCompEvent(event);
        } else if (event.type === "logout") {
            stopPropagation = true;
            handleLogout();
        } else if (event.type === "reload") {
            cmp.set("navigationLoading", true);
        }

        if (!stopPropagation) {
            dispatchEvent(event);
        }
    };

    const updateQueryFilter = (reset) => {
        const constraintJson = searchParams.get("constraint");
        let constraints = constraintJson && isValidJSON(constraintJson) ? JSON.parse(constraintJson) : null;
        if (reset || JSON.stringify(constraints || {}) !== JSON.stringify(cmp.get("queryFilter")?.constraints || {})) {
            if (constraints == null) {
                // IMPROVEMENT: allow 'null' value for constraint in API
                cmp.set("queryFilter", { version: 1 });
            } else {
                cmp.set("queryFilter", { constraints, version: 1 });
            }
        }
    };

    const handleSearchTextChange = (value) => {
        cmp.set("searchText", value);
    };

    const handleExpandCollapse = () => {
        const navigationTreeExpanded = cmp.get("navigationTreeExpanded");
        cmp.set("navigationTreeExpanded", !navigationTreeExpanded);
    };

    const isDetailView = cmpState.view === "detail";

    return (
        <IconSettings iconPath="/assets/icons">
            <div className="build">
                <PsNavigationHeader childToParent={bubbleEvent} loading={cmpState.searchLoading} showClose={isDetailView} showApplyNow={true} applyNow={applyNow} setApplyNow={setApplyNow} />
                <div className="tab-content slds-p-around_medium">
                    {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
                    <div
                        className={isDetailView ? "slds-hide" : "left slds-m-right_medium"}
                        style={{
                            display: cmpState.navigationTreeExpanded ? "block" : "none",
                        }}
                    >
                        {/* <!-- navigation tree --> */}
                        <article className="slds-card">
                            <div
                                className="slds-card__header slds-grid"
                                style={{
                                    margin: "-5px 0 -2px -5px",
                                }}
                            >
                                <header className="slds-media slds-media_center slds-has-flexi-truncate">
                                    <div className="slds-media__body">
                                        <h2 className="slds-card__header-title">Browse</h2>
                                    </div>

                                    {cmpState.navigationTreeExpanded && (
                                        <div className="slds-no-flex" style={{ marginRight: "-8px" }}>
                                            <button
                                                className="slds-button slds-button_icon slds-button_icon-brand slds-button_icon-x-small"
                                                title="Collapse"
                                                style={{ borderRadius: "50%" }}
                                                onClick={handleExpandCollapse}
                                            >
                                                <svg className="slds-button__icon" aria-hidden="true">
                                                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#jump_to_left"></use>
                                                </svg>
                                            </button>
                                        </div>
                                    )}
                                </header>
                            </div>

                            <div ref={searchdivRef} className="slds-p-around_x-small">
                                <SearchBox searchText={cmpState.searchText} loading={cmpState.navigationLoading} handleSearchTextChange={handleSearchTextChange} />
                            </div>

                            <div className="slds-p-around_x-small">
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control">
                                        <div className="slds-select_container">
                                            <select className="slds-select" name="Filter" value={cmpState.selectedSection} id="select-01" onChange={(e) => handleSelectFilter(e.target.value)}>
                                                {cmpState.sectionOptions.map((item, idx) => (
                                                    <option key={idx} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="slds-p-horizontal_x-small"
                                ref={navigationTreeRef}
                                style={{
                                    overflowY: "auto",
                                    height: "calc(100vh - 15.5rem)",
                                }}
                            >
                                <PsNavigationTree
                                    multiSelect={false}
                                    selected={cmpState.selectedItem}
                                    sections={["data", "types", "aggs", "transforms", "chains"]}
                                    searchText={cmpState.searchText}
                                    setLoading={(value) => cmp.set("navigationLoading", value)}
                                    parentCmp={cmp}
                                    childToParent={bubbleEvent}
                                    parentToChildEvent={cmpState.parentToChildEvent}
                                    selectedSection={cmpState.selectedSection}
                                    pageName="build"
                                    ref={cmpNavigationTree}
                                    draggable={true}
                                />
                            </div>
                        </article>
                    </div>

                    {/* <!-- right panel view --> */}
                    {!isDetailView && (
                        <>
                            {/* divider */}
                            {!cmpState.navigationTreeExpanded && <Divider handleExpandCollapse={handleExpandCollapse} />}
                            <DropBoxBar grabbedItemFromMenu={grabbedItem} updateConstaints={updateConstaints} updateUrlParamsConstraint={updateUrlParamsConstraint} />
                        </>
                    )}

                    {/* content */}
                    <div className={isDetailView ? "slds-hide" : "right"}>
                        <PsSetupStatus title="Build" tagLine="Drag items to create the graphs you want to see." />
                        <PsSearchGrid
                            view="grid"
                            queryFilter={cmpState.queryFilter}
                            maxRecords="12"
                            childToParent={bubbleEvent}
                            parentToChildEvent={cmpState.parentToChildEvent}
                            parentCmp={cmp}
                            loading={cmpState.searchLoading}
                            onLoadingChange={(value) => cmp.set("searchLoading", value)}
                            emptyLine="Drag items to create the graphs you want to see"
                            navigationTreeExpanded={cmpState.navigationTreeExpanded}
                        />
                    </div>

                    {/* Detail View */}
                    {isDetailView && (
                        <PsPatternDetailedView pattern={cmpState.pattern} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} parentCmp={cmp} applyNow={applyNow} />
                    )}
                </div>
            </div>
        </IconSettings>
    );
};

export default Build;
