const NoActiveFilters = () => {
    return (
        <div className="slds-m-around_x-small">
            <div className="slds-scoped-notification slds-media slds-media_center slds-scoped-notification_light" role="status">
                <div className="slds-media__figure"></div>
                <div className="slds-media__body">
                    <p>
                        No active filters.
                        <br />
                        Refine results by selecting one or more filters.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NoActiveFilters;
