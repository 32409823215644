import { useState } from "react";

import { RECORD_COLUMNS } from "./constants";
import Record from "../../helpers/recordLayer";
import "./PsSuggestionList.css";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import PsSuggestionTile from "../ps-suggestion-tile/PsSuggestionTile.js";

const PsSuggestionList = ({ parentId, queryFilter, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("grid");
    const [orderBy, setOrderBy] = useState("relevance");
    const [orderDirection, setOrderDirection] = useState("desc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const parseResponse = (response) => {
        return response.map(({ id, name, container, dataRole, relevance, question }) => ({
            keyId: id, // need original id for row actions and hyperlinks
            keyName: name,
            sourceName: container.source.name,
            containerName: container.name,
            dataRoleName: dataRole.name,
            dataRole: dataRole,
            dataRoleId: dataRole.id,
            relevance,
            question,
            questionPlain: Record.removeMarkup(question),
            questionMarkup: Record.markupToHtml(question),
            title: [container.source.name, container.name, name].filter(Boolean).join("->"),
        }));
    };

    const handleRecordRowAction = (action, row) => {
        const parentId = null;
        switch (action) {
            case "question":
                // send two navigation events; one for KeyId and DataRoleId
                // the first one has source='change' to prevent changing the url already, which would break the 'back' button functionality
                childToParent({ type: "navigation", parentId, module: "store", obj: "key", id: row.keyId, source: "change" });
                childToParent({ type: "navigation", parentId, module: "core", obj: "dataRole", id: row.dataRole?.id, source: "grid" });
                break;
            case "key":
                childToParent({ type: "navigation", parentId, module: "store", obj: "key", id: row.keyId, source: "grid" });
                break;
            case "dataRole":
                childToParent({ type: "navigation", parentId, module: "core", obj: "dataRole", id: row.dataRole?.id, source: "grid" });
                break;
            default:
        }
    };

    return (
        <PsRecordGrid
            module="relate"
            object="suggestion"
            queryFilter={queryFilter}
            gridComponent={PsSuggestionTile}
            gridItemClass="grid-item-suggestion"
            showTitle={true}
            emptyLine="No suggestions available"
            viewOptions={[
                { label: "Table", value: "table" },
                { label: "Grid", value: "grid" },
            ]}
            changeView={true}
            recordLabel="Suggestion"
            recordLabelPlural="Suggestions"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            layout={[]}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={24}
            onRecordRowAction={handleRecordRowAction}
            childToParent={childToParent}
            parentToChildEvent={parentToChildEvent}
        />
    );
};

export default PsSuggestionList;
