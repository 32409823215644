// This is a 'helper' that replaces the old RecordGrid base component.
import { useEffect, useState, useRef } from "react";

import Button from "@salesforce/design-system-react/components/button";
import Card from "@salesforce/design-system-react/components/card";
import Spinner from "@salesforce/design-system-react/components/spinner";

import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./PsRecordGrid.css";

import Record from "../../helpers/recordLayer.js";
import IllustrationOpenRoad from "../../ui/IllustrationOpenRoad.js";
import IllustrationDesert from "../../ui/IllustrationDesert.js";
import RecordTable from "../../ui/tables/record-table/RecordTable";
import HeaderActions from "./components/HeaderActions";
import { toastErrorMessage } from "../../helpers/index.js";
import useToastContext from "../../context/useToastContext.tsx";
import { getInnerSize } from "../../utils/index";

const numCols = 12;
const defaultWidth = 4;
const defaultHeight = 3;
const itemsPerRow = Math.floor(numCols / defaultWidth);
const rowHeight = 100;
const layoutWidth = 1200;

const PsRecordGrid = ({
    module,
    object,
    recordLabel,
    recordLabelPlural,
    parentId,
    queryFilter,
    useLayout,
    layout,
    gridComponent,
    gridItemClass,
    recordColumns,
    maxRecords,
    title,
    showTitle,
    tagLine,
    header,
    emptyLine,
    emptyCallToAction,
    viewOptions,
    changeView,
    parseResponse,
    footer,
    mode,
    onModeChange,
    view,
    onViewChange,
    orderBy,
    onOrderByChange,
    orderDirection,
    onOrderDirectionChange,
    loading,
    onLoadingChange,
    showLoadMore,
    hasMore,
    onHasMoreChange,
    showCreate,
    showEdit,
    onSave,
    onCancel,
    showDelete,
    onDeleteClick,
    onRecordRowAction,
    cardActions,
    childToParent,
    parentToChildEvent,
    setEmpty,
    usePutAPI,
}) => {
    const [loadingMore, setLoadingMore] = useState(false);
    const [recordList, setRecordList] = useState([]);
    const [lastValue, setLastValue] = useState(null);
    const [lastId, setLastId] = useState(null);
    const [gridWidth, setGridWidth] = useState(null);

    const [parsedLayout, setParsedLayout] = useState([]);

    const { addToast } = useToastContext();

    const GridComponent = gridComponent;

    const DEFAULT_RECORD_COLUMNS = [
        {
            label: "Name",
            type: "link",
            property: "name",
            key: "name",
            sortype: true,
            action: "details",
            minWidth: 200,
        },
    ];

    const gridRef = useRef(null);

    useEffect(() => {
        if (!parentToChildEvent) {
            return;
        }
        handleEvent(parentToChildEvent);
    }, [parentToChildEvent]);

    useEffect(() => {
        const updateWidth = () => {
            if (gridRef.current && gridRef.current.offsetParent) {
                const innerSize = getInnerSize(gridRef.current);
                setGridWidth(innerSize?.width);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (gridRef.current) {
            resizeObserver.observe(gridRef.current);
        }

        const currentGridRef = gridRef.current;
        return () => {
            if (currentGridRef) {
                resizeObserver.unobserve(currentGridRef);
            }
        };
    }, []);

    useEffect(() => {
        if (layout == null) {
            // don't render if no layout has yet been loaded
            setParsedLayout([]);
        } else {
            setParsedLayout(calcLayout(recordList, layout));
        }
    }, [layout, recordList]);

    useEffect(() => {
        getRecords(queryFilter, orderBy, orderDirection, maxRecords, true);
    }, [queryFilter, orderBy, orderDirection]);

    const handleOrderBy = (event) => {
        onOrderByChange(event.orderBy);
        onOrderDirectionChange(event.orderDirection);
    };

    const handleLoadMore = () => {
        getRecords(queryFilter, orderBy, orderDirection, maxRecords, false);
    };

    const handleEvent = (event) => {
        if (event.type === "reload") {
            handleReload();
        }
    };

    const handleReload = () => {
        var numRecords = !maxRecords ? 0 : Math.max(recordList.length, maxRecords);
        getRecords(queryFilter, orderBy, orderDirection, numRecords, true);
    };

    const handleDelete = (event) => {
        const id = event.target.id;
        setParsedLayout((prevLayout) => prevLayout.map((item) => (item.i === id ? { ...item, w: 0, h: 0, static: true } : item)));
    };

    const handleRecordRowAction = (action, row) => {
        if (onRecordRowAction) {
            onRecordRowAction(action, row);
        } else {
            switch (action) {
                case "details":
                    notifyNavigation(parentId, module, object, row.id);
                    break;
                default:
            }
        }
    };

    const calcLayout = (recordList, loadedLayout) => {
        // remove records that are no longer present in the layout
        recordList = recordList || [];
        // TEMP In some way, in the response of getFolder, the grid value sometimes comes as an object and sometimes as an array.
        const resolvedLayout = Array.isArray(loadedLayout) ? loadedLayout : (loadedLayout?.layout ?? []);

        const recordIds = recordList.map((item) => item.id);
        var loaded = resolvedLayout.filter((item) => recordIds.includes(item.i));

        // get the first available row in the layout
        let startRow = Math.max(...loaded.map((item) => (item.y || 0) + (item.h || 0)), 0);

        // records not yet in the layout
        const layoutIds = loaded.map((item) => item.i);
        var addRecords = recordList.filter((item) => !layoutIds.includes(item.id));

        // commented out; records should already be ordered in the correct order from the API call
        // // sort by orderBy field, then id
        // addRecords.sort((a, b) => {
        //     if (a[orderBy] == null && b[orderBy] != null) {
        //         return 1;
        //     } // b comes before a (a moves down)
        //     if (a[orderBy] != null && b[orderBy] == null) {
        //         return -1;
        //     } // a comes before b (b moves down)
        //     if (a[orderBy] === b[orderBy] || (a[orderBy] == null && b[orderBy] == null)) {
        //         //return a.createdOn.localeCompare(b.createdOn);
        //         return a.id.localeCompare(b.id);
        //     }
        //     return a[orderBy] - b[orderBy];
        // });

        // set layout position for items that don't have one yet
        // NOTE that compacting may push items to a different position
        let index = 0;
        var calculated = [...loaded];
        addRecords.forEach((item) => {
            calculated.push({
                i: String(item.id || item.floatingId || index),
                x: (index % itemsPerRow) * defaultWidth,
                y: startRow + Math.floor(index / itemsPerRow) * defaultHeight,
                w: defaultWidth,
                h: defaultHeight,
            });
            index++;
        });

        return calculated;
    };

    const dispatchEvent = (event) => {
        childToParent(event);
    };

    const notifyNavigation = (parentId, module, object, id) => {
        var navigationEvent = { type: "navigation", parentId, module, obj: object, id, source: "grid" };
        dispatchEvent(navigationEvent);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;

        if (!stopPropagation) {
            childToParent(event);
        }
    };

    const handleStartEdit = () => {
        updateMode("edit");
    };

    const handleCancelEdit = () => {
        onCancel && onCancel();
        updateMode("view", recordList?.length);
    };

    const handleSaveEdit = () => {
        onSave && onSave(JSON.parse(JSON.stringify(parsedLayout)));
        const deleteMap = parsedLayout.reduce((obj, item) => {
            obj[item.i] = item.static;
            return obj;
        }, {});

        const newRecordList = recordList?.filter((item) => !deleteMap[item.id]);
        setRecordList(newRecordList);
        updateMode("view", newRecordList?.length);
    };

    const updateMode = (mode, numRecords = 0) => {
        const newMode = mode === "view" && !numRecords ? "empty" : mode;
        onModeChange(newMode);
    };

    const startLoading = (reset) => {
        if (!reset && recordList?.length > 0 && mode !== "edit") {
            setLoadingMore(true);
        } else {
            onLoadingChange(true);
        }
    };

    const stopLoading = () => {
        setLoadingMore(false);
        onLoadingChange(false);
    };

    const handleNew = () => {
        childToParent({ type: "navigation", parentId, module, obj: object, id: null, source: "grid" });
    };

    const defaultEmptyCallToAction = () => {
        const label = `Create a ${recordLabel}`;
        const title = `Create a new ${recordLabel}`;
        return <Button label={label} title={title} onClick={() => handleNew()} disabled={loading} variant="brand" />;
    };

    const renderEmptyCallToAction = emptyCallToAction ? emptyCallToAction : showCreate ? defaultEmptyCallToAction : null;

    const defaultCardActions = () => {
        return (
            <>
                {(mode === "init" || mode === "view") && ( //
                    <Button key="newButton" label="New" title={`Create a new ${recordLabel}`} onClick={() => handleNew()} disabled={loading} />
                )}
            </>
        );
    };

    const renderCardActions = cardActions ? cardActions : showCreate ? defaultCardActions : null;

    const getRecords = (queryFilter, orderBy, orderDirection, numRecords, reset) => {
        // if (!queryFilter) { // no longer needed, use setEmpty check function with more detailed checks instead
        //     updateMode("error");
        //     return;
        // }

        // new state variables are not yet available, so we need to update again here
        const newRecordList = reset ? [] : [...(recordList || [])];

        const onSuccess = (response) => {
            const records = parseResponse ? parseResponse(response) : response;

            // if there are no further records available, show toast and disable the 'Load More' button
            const hasMore = !!response?.length && response?.length >= numRecords;
            onHasMoreChange(hasMore);
            if (!hasMore && (!numRecords || newRecordList.length + response.length > numRecords)) {
                addToast("info", "No Further Records Available", "You have reached the end.");
            }

            // set last row for pagination from raw API response (before it is processed by 'parseResponse')
            if (response.length) {
                var lastRow = response[response.length - 1];
                setLastValue(lastRow[orderBy || "id"]);
                setLastId(lastRow.id);
            }

            // add items
            newRecordList.push(...(records || []));
            setRecordList(newRecordList);

            stopLoading();
            updateMode("view", newRecordList.length);
        };

        const onError = (response) => {
            setRecordList(newRecordList);
            stopLoading();
            onHasMoreChange(true);
            updateMode("error");
            addToast("error", "Error", toastErrorMessage(response));
        };

        const newLastValue = reset ? null : lastValue;
        const newLastId = reset ? null : lastId;
        setLastValue(newLastValue);
        setLastId(newLastId);

        if (reset && setEmpty && setEmpty()) {
            setRecordList([]);
            updateMode("empty");
        } else {
            startLoading(reset);
            const orderByWithDirection = orderBy + " " + (orderDirection || "asc").toUpperCase();
            const workingQueryFilter = { ...queryFilter, orderBy: orderByWithDirection, lastValue: newLastValue, lastId: newLastId, ...(numRecords && { maxRecords: numRecords }) };
            Record.getRecords(module, object, workingQueryFilter, onSuccess, onError, usePutAPI ? "PUT" : "GET");
        }
    };

    return (
        <div className="slds-is-relative" style={{ height: "100%" }}>
            {/* keep loading animation veritcally centered, even if the user scrolls away */}
            {loading && <Spinner assistiveText={{ label: "Loading" }} hasContainer={false} />}

            <Card
                id="recordGrid"
                className="slds-scrollable"
                style={{
                    height: renderEmptyCallToAction && mode === "empty" ? "auto" : "100%",
                }}
                heading={<span className="card-main-title-lh32 slds-card__header-title">{showTitle ? (title ? title : recordLabelPlural) : " "}</span>}
                headerActions={
                    <div style={{ display: "flex", gap: "5px" }}>
                        <HeaderActions
                            mode={mode}
                            loading={loading}
                            recordLabel={recordLabel}
                            showEdit={showEdit}
                            handleEdit={handleStartEdit}
                            handleSave={handleSaveEdit}
                            handleCancel={handleCancelEdit}
                            showDelete={showDelete}
                            handleDelete={onDeleteClick}
                            cardActions={renderCardActions}
                        />
                        {changeView && ["init", "view"].includes(mode) && (
                            <fieldset className="slds-form-element">
                                <div className="slds-form-element__control">
                                    <div className="slds-radio_button-group">
                                        {viewOptions.map((option) => (
                                            <span key={option.label} className="slds-button slds-radio_button" onClick={() => onViewChange(option.value)}>
                                                <input type="radio" name={option.label} value={option.value} checked={view === option.value} onChange={() => {}} />
                                                <label className="slds-radio_button__label" htmlFor={option.value}>
                                                    <span className="slds-radio_faux">{option.label}</span>
                                                </label>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </fieldset>
                        )}
                    </div>
                }
                footer={<>{mode === "empty" && renderEmptyCallToAction && renderEmptyCallToAction()}</>}
            >
                {tagLine && <div className="slds-p-around_medium">{tagLine}</div>}
                {header && header()}

                {mode === "empty" && (
                    <div className="slds-is-relative">
                        <div className="slds-p-around_medium slds-illustration slds-illustration_large" aria-hidden="true">
                            <IllustrationOpenRoad />
                            <div className="slds-text-color_weak">
                                <h3 className="slds-text-heading_medium">{emptyLine}</h3>
                            </div>
                        </div>
                        {loading && <div className="slds-spinner_container" />}
                    </div>
                )}

                {mode === "error" && (
                    <div className="slds-is-relative">
                        <div className="slds-p-around_medium slds-illustration slds-illustration_large" aria-hidden="true">
                            <IllustrationDesert />
                            <div className="slds-text-color_weak">
                                <h3 className="slds-text-heading_medium">Error while loading {recordLabelPlural}</h3>
                            </div>
                        </div>
                        {loading && <div className="slds-spinner_container" />}
                    </div>
                )}

                {["init", "view", "edit"].includes(mode) && (
                    <div className="slds-p-around_medium slds-is-relative" ref={gridRef}>
                        {view === "table" && recordList && (
                            <RecordTable
                                columns={recordColumns || DEFAULT_RECORD_COLUMNS}
                                records={recordList}
                                tableWidth={gridWidth}
                                orderBy={orderBy}
                                orderDirection={orderDirection}
                                onOrderBy={handleOrderBy}
                                onRowAction={handleRecordRowAction}
                                onScrollEnd={handleLoadMore}
                            />
                        )}

                        {(view === "grid" || (view === "table" && changeView)) && useLayout && (
                            // render in the background when in table mode, to prevent reloading a lot of items in parallel
                            <div className={view === "grid" ? "layout" : "slds-hide"}>
                                {!!parsedLayout.length && (
                                    <GridLayout
                                        //className="layout"
                                        useCSSTransforms={false} // disable, because breaks the delete button clicks because of stacking context
                                        layout={parsedLayout}
                                        cols={numCols}
                                        rowHeight={rowHeight}
                                        width={layoutWidth}
                                        //compactType={null}
                                        onLayoutChange={setParsedLayout}
                                        isDraggable={mode === "edit"}
                                        isResizable={mode === "edit"}
                                    >
                                        {recordList.map((item, index) => (
                                            <div className={gridItemClass} key={String(item.id || item.floatingId || index)}>
                                                <GridComponent
                                                    record={item} //
                                                    childToParent={bubbleEvent}
                                                    view={mode}
                                                />
                                                {mode === "edit" && (
                                                    <>
                                                        <div className="slds-p-around_x-small icon-overlay" style={{ position: "absolute", top: 0, right: 0, zIndex: 3 }}>
                                                            <Button
                                                                id={String(item.id || item.floatingId || index)}
                                                                assistiveText={{ icon: "Delete" }}
                                                                iconCategory="utility"
                                                                iconName="delete"
                                                                variant="icon"
                                                                title="Delete"
                                                                onClick={handleDelete}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </GridLayout>
                                )}
                            </div>
                        )}

                        {(view === "grid" || (view === "table" && changeView)) && !useLayout && (
                            // render in the background when in table mode, to prevent reloading a lot of items in parallel
                            <div className={view === "grid" ? "slds-grid slds-wrap grid" : "slds-hide"}>
                                {recordList.map((item, index) => (
                                    <div className={gridItemClass} key={String(item.id || item.floatingId || index)}>
                                        <GridComponent
                                            record={item} //
                                            childToParent={bubbleEvent}
                                            view={mode}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {loading && <div className="slds-spinner_container" />}

                        {showLoadMore > 0 && (
                            <div className="slds-align_absolute-center slds-m-top_small">
                                <Button
                                    label={
                                        <>
                                            Load More
                                            {!loading && loadingMore && <Spinner size="small" />}
                                        </>
                                    }
                                    disabled={loading || loadingMore || !hasMore}
                                    onClick={() => handleLoadMore()}
                                />
                            </div>
                        )}
                    </div>
                )}
                {footer && footer()}
            </Card>
        </div>
    );
};

export default PsRecordGrid;
