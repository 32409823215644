export const RECORD_COLUMNS = [
    {
        key: "name",
        label: "Name",
        property: "name",
        type: "link",
        action: "details",
        sortable: true,
        minWidth: 200,
    },
    {
        key: "type",
        label: "Type",
        property: "type",
        type: "text",
        sortable: true,
        width: 200,
    },
    {
        key: "status",
        label: "Status",
        property: "status",
        type: "text",
        sortable: true,
        width: 120,
    },
];
