import { Checkbox } from "@salesforce/design-system-react";
import Icon from "@salesforce/design-system-react/components/icon";
import { useEffect, useState } from "react";

const PlotOptions = ({ pattern, options, onPatternChange }) => {
    const [showSection, setShowSection] = useState(true);
    const [plotTypeOptions, setPlotTypeOptions] = useState([]);
    const [plotTypeRequested, setPlotTypeRequested] = useState(null);
    // const [plotTypeSelected, setPlotTypeSelected] = useState(null);
    const [showPlotTypes, setShowPlotTypes] = useState(false);
    const [stackCheckbox, setStackCheckbox] = useState(false);
    const [showStackCheckbox, setShowStackCheckbox] = useState(false);
    const [stack100Checkbox, setStack100Checkbox] = useState(false);
    const [showStack100Checkbox, setShowStack100Checkbox] = useState(false);
    const [sortCheckbox, setSortCheckbox] = useState(false);
    const [showSortCheckbox, setShowSortCheckbox] = useState(false);

    useEffect(() => {
        parseOptions(options);
    }, [options]);

    const parseOptions = (options) => {
        let plotType = options?.plotType;
        let plotTypes = options?.plotTypes || {};
        let parsedOptions = [];
        for (let [pType, pOptions] of Object.entries(plotTypes)) {
            if (plotTypes.hasOwnProperty(pType) && pOptions.enabled) {
                parsedOptions.push({
                    value: pType,
                    label: pOptions.name,
                    selected: pType === plotType,
                    disabled: true,
                });
            }
        }
        setPlotTypeOptions(parsedOptions);
        setPlotTypeRequested(plotType);
        //setPlotTypeSelected(plotType);
        setShowPlotTypes(!!parsedOptions.length);
        setStackCheckbox(options?.stack);
        setShowStackCheckbox(options?.enableStack);
        setStack100Checkbox(options?.stackToHundred);
        setShowStack100Checkbox(options?.enableStackToHundred);
        setSortCheckbox(options?.sortAxis === "xAxes");
        setShowSortCheckbox((options?.sortAxes || []).includes("xAxes"));
    };

    const plotTypeOnChange = (e) => {
        const plotType = e.target.value;
        setPlotTypeRequested(plotType);
        setPlotOptions({ plotType });
    };

    const stackCheckboxOnChange = () => {
        const stack = !stackCheckbox;
        setStackCheckbox(stack);
        setPlotOptions({ stack, stackToHundred: false });
    };

    const stack100CheckboxOnChange = () => {
        const stackToHundred = !stack100Checkbox;
        setStack100Checkbox(stackToHundred);
        setStackCheckbox(true);
        const options = { stackToHundred, stack: true }; //, plugins : {stacked100: { enable: true }} };
        setPlotOptions(options);
    };

    const sortCheckboxOnChange = () => {
        const sortAxis = !sortCheckbox;
        setSortCheckbox(sortAxis);
        setPlotOptions({ sortAxis: sortAxis ? "xAxes" : null });
    };

    const setPlotOptions = (options) => {
        const updatedPattern = { ...pattern, options: { ...pattern?.options, ...options } };
        onPatternChange(updatedPattern, true);
    };

    return (
        pattern?.inputs && (
            <div className="slds-m-horizontal_x-small" style={{ flexBasis: "300px", flexGrow: 1, minWidth: "300px" }}>
                <div className={`slds-section ${showSection ? "slds-is-open" : "slds-is-close"}`}>
                    <h3>
                        <button className="slds-button slds-section__title-action" onClick={() => setShowSection(!showSection)}>
                            <span>
                                <Icon assistiveText={{ label: "switch section" }} category="utility" name="switch" size="x-small" className="slds-section__title-action-icon slds-button__icon_left" />
                            </span>
                            <span>Plot Options</span>
                        </button>
                    </h3>
                    {showSection ? (
                        <div>
                            {showPlotTypes && (
                                <div className="slds-form-element" style={{ marginTop: "10px" }}>
                                    <label className="slds-form-element__label" htmlFor="select-id">
                                        Select a plot type:
                                    </label>
                                    <div className="slds-form-element__control">
                                        <div className="slds-select_container">
                                            <select className="slds-select" id="select-id" value={plotTypeRequested} onChange={plotTypeOnChange}>
                                                {plotTypeOptions.map((option) => (
                                                    <option value={option.value} key={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="slds-form-element" style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                {showStackCheckbox && <Checkbox labels={{ label: "Stack" }} checked={stackCheckbox} onChange={stackCheckboxOnChange} />}
                                {showStack100Checkbox && <Checkbox labels={{ label: "Stack to 100%" }} checked={stack100Checkbox} onChange={stack100CheckboxOnChange} />}
                                {showSortCheckbox && <Checkbox labels={{ label: "Sort" }} checked={sortCheckbox} onChange={sortCheckboxOnChange} />}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    );
};

export default PlotOptions;
