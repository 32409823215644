import React, { useEffect, useState, useRef } from "react";
import Icon from "@salesforce/design-system-react/components/icon";
import { Button, Spinner } from "@salesforce/design-system-react";

import "./PsNavigationHeader.css";

function PsNavigationHeader({ childToParent, loading, showClose, showFilters, showUndo, showRedo, showRefresh = true, showApplyNow, applyNow, setApplyNow }) {
    const handleCloseDetail = () => {
        dispatchEvent({ type: "dataCompEvent", action: "close" });
    };

    const handleApply = () => {
        dispatchEvent({ type: "dataCompEvent", action: "apply" });
    };

    const handleUndo = () => {
        dispatchEvent({ type: "dataCompEvent", action: "undo" });
    };

    const handleRedo = () => {
        dispatchEvent({ type: "dataCompEvent", action: "redo" });
    };

    const handleReload = () => {
        dispatchEvent({ type: "reload" });
    };

    const handleDataFilters = () => {
        console.error("handleDataFilters");
    };

    const handleSupport = () => {
        console.error("handleSupport");
    };

    const dispatchEvent = (event) => {
        childToParent(event);
    };

    const handleUndoRedo = (item) => {
        window.history[item]();
    };

    const handleApplyChange = (event) => {
        let checked = event?.target?.checked;
        setApplyNow(checked);
        if (checked) {
            handleApply();
        }
    };

    // This styling will be applied to a <NavLink> when the
    // route that it links to is currently selected.
    // let activeClassName = "slds-context-bar__item slds-is-active";
    // let inactiveClassName = "slds-context-bar__item";
    return (
        <div id="navigationHeader" className="navigationHeader">
            <div className="navigationHeaderItem">
                {showClose && (
                    <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={handleCloseDetail}>
                        <Icon assistiveText={{ label: "Back" }} category="utility" name="back" size="x-small" inverse />
                        &nbsp;Back to Results
                    </div>
                )}
            </div>
            <div className="navigationHeaderItem borderRight"></div>
            <div className="navigationHeaderItemRight">
                {showFilters && (
                    <div id="dataFiltersButton" className="navigationHeaderButton slds-p-around_medium borderRight" onClick={handleDataFilters}>
                        <Icon assistiveText={{ label: "Filter" }} category="utility" name="filterList" size="x-small" inverse />
                        &nbsp;Data Filters&nbsp;
                        <Icon assistiveText={{ label: "Down" }} category="utility" name="chevrondown" size="x-small" inverse />
                    </div>
                )}

                {/* <div  id="supportButton" className="navigationHeaderButton slds-p-top_small slds-p-horizontal_medium" onClick={handleSupport}>
                <Icon
                    assistiveText={{ label: 'Support' }}
                    category="utility"
                    name="help"
                    size="x-small"
                    inverse
                  />      
              </div>
              <div className="navigationHeaderItem borderRight"></div> */}

                {showApplyNow && (
                    <>
                        <div className="navigationHeaderButton slds-p-horizontal_medium borderRight">
                            <Button
                                label={
                                    <>
                                        Apply changes
                                        {loading && !applyNow ? <Spinner size="small" /> : null}
                                    </>
                                }
                                disabled={applyNow}
                                onClick={() => handleApply()}
                                title="Apply changes now"
                            />
                        </div>
                        <div className="navigationHeaderButton slds-p-horizontal_medium borderRight" style={{ marginLeft: -10, paddingRight: 2 }}>
                            <div style={{ margin: "10px" }}>
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control">
                                        <div className="slds-checkbox">
                                            <input
                                                type="checkbox"
                                                name="options"
                                                id="checkbox-unique-id-159"
                                                value="checkbox-unique-id-159"
                                                onChange={(e) => handleApplyChange(e)}
                                                checked={applyNow}
                                            />
                                            <label className="slds-checkbox__label" htmlFor="checkbox-unique-id-159">
                                                <span className="slds-checkbox_faux"></span>
                                                <span className="slds-form-element__label" style={{ color: "white" }}>
                                                    Apply changes immediately
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {/* {props.view === "list" && (
                    <>
                        {props.parentCmp?.buildButton() && <div className="navigationHeaderButton slds-p-horizontal_medium borderRight">{props.parentCmp.buildButton()}</div>}

                        {props.parentCmp?.refreshCheckbox() && (
                            <div className="navigationHeaderButton slds-p-horizontal_medium borderRight" style={{ marginLeft: -10, paddingRight: 2 }}>
                                {props.parentCmp.refreshCheckbox()}
                            </div>
                        )}

                        {props.parentCmp?.undoRedoRadioButtonGroup() && props.parentCmp.undoRedoRadioButtonGroup()}
                    </>
                )} */}

                {showUndo && (
                    <>
                        <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={() => handleUndo()}>
                            <Icon assistiveText={{ label: "Undo" }} category="utility" name="undo" size="x-small" inverse title="Undo" />
                        </div>
                    </>
                )}

                {showRedo && (
                    <>
                        <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={() => handleRedo()}>
                            <Icon assistiveText={{ label: "Redo" }} category="utility" name="redo" size="x-small" inverse title="Redo" />
                        </div>
                    </>
                )}

                {showRefresh && (
                    <>
                        <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={handleReload}>
                            <Icon assistiveText={{ label: "Refresh" }} category="utility" name="refresh" size="x-small" inverse title="Refresh" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default PsNavigationHeader;
