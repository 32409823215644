import EditButtonIcon from "../EditButtonIcon";
import ProgressBarComponent from "../ProgressBarComponent";

const EditableFieldControl = ({
    value, // string or number or jsx element ----> Requeued
    label, // string  ----> Requeued
    isEdit = false, // boolean
    isEditable = true, // boolean
    formElement = <></>, // jsx element
    setIsEdit, // function or undefined
    showProgressBar = false,
}) => {
    return (
        <>
            {isEditable ? (
                <>
                    {!isEdit && (
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {showProgressBar ? (
                                    <ProgressBarComponent />
                                ) : (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">{label}</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{value}</div>
                                            <div className="slds-button slds-button__icon slds-button__icon_hint">{<EditButtonIcon handleEdit={() => setIsEdit?.(true)} />}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {isEdit && (
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">{formElement}</div>
                        </div>
                    )}
                </>
            ) : (
                <div className="slds-form__item" role="listitem">
                    <div className="slds-form-element slds-form-element_stacked">
                        {showProgressBar ? (
                            <ProgressBarComponent />
                        ) : (
                            <div id="FormDiv" className={`slds-form-element_edit slds-form-element_stacked slds-hint-parent ${!isEdit ? "slds-form-element_readonly" : ""}`}>
                                <span className="slds-form-element__label">{label}</span>
                                <div className="slds-form-element__control">
                                    <div className="slds-form-element__static">{value}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default EditableFieldControl;
