import { useEffect, useRef, useState } from "react";

import PsRecord from "../ps-record/PsRecord";
import ProgressBarComponent from "../../ui/ProgressBarComponent";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import EditButtonIcon from "../../ui/EditButtonIcon";
import UndoButtonIcon from "../../ui/UndoButtonIcon";
import Statistics from "./components/Statistics";
import Buttons from "./components/Buttons";
import NameInputWithUndoIcon from "../../ui/NameInputWithUndoIcon";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import Record from "../../helpers/recordLayer";
import RecordTable from "../../ui/tables/record-table/RecordTable";
import { RECORD_COLUMNS } from "./constants";
import { getInnerSize } from "../../utils/index";

import useToastContext from "../../context/useToastContext";

const PsChain = (props) => {
    const [cmpState, setCmpState] = useState({
        recordLabel: "Path",
        recordModule: "store",
        recordObject: "chain",
        showDelete: false,

        dataTableColumns: RECORD_COLUMNS,
        dataTableKeyField: "id",
        dataTableRecords: [],

        leftKey: null,
        leftKeyId: "",
        leftKeySelected: null,
        leftKeyFilters: null,
        rightKey: null,
        rightKeyId: "",
        rightKeySelected: null,
        rightKeyFilters: null,

        loading: false,
        mode: "init",
        showEdit: true,

        record: {},
        isExpanded: false,
        activeField: "",
        missingRequiredFields: [],
    });

    const gridRef = useRef(null);
    const [gridWidth, setGridWidth] = useState(null);

    const cmpWorking = useRef({});
    const leftPsNavigationInputRef = useRef(null);
    const rightPsNavigationInputRef = useRef(null);

    // global toast
    const { addToast } = useToastContext();

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        cmp.init();
    }, []);

    useEffect(() => {
        if (!props.parentToChildEvent) {
            return;
        }
        cmp.handleEvent(props.parentToChildEvent);
    }, [props.parentToChildEvent]);

    useEffect(() => {
        try {
            // TODO: review what this is doing, can this not be done in NavigationInput?
            document.addEventListener("mousedown", cmp.handleClickOutsideOfPsNavigationInput);
            return () => {
                document.removeEventListener("mousedown", cmp.handleClickOutsideOfPsNavigationInput);
            };
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        cmp.set("recordId", props.recordId);
        cmp.handleReload();
    }, [props.recordId]);

    useEffect(() => {
        cmp.set("parentId", props.parentId);
        cmp.leftContainerFilterToParent();
    }, [props.parentId]);

    useEffect(() => {
        const currentGridRef = gridRef.current;

        const updateWidth = () => {
            if (currentGridRef) {
                const innerSize = getInnerSize(currentGridRef);
                setGridWidth(innerSize?.width);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (currentGridRef) {
            resizeObserver.observe(currentGridRef);
        }

        return () => {
            if (currentGridRef) {
                resizeObserver.unobserve(currentGridRef);
            }
        };
    }, []);

    const cmp = {
        init: function () {},

        handleReload: function () {
            try {
                PsRecord.getRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        handleEdit: function () {
            try {
                PsRecord.setMode(cmp, "edit");
            } catch (err) {
                console.error(err);
            }
        },

        handleCancel: function () {
            try {
                PsRecord.cancelRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        handleSubmit: function () {
            try {
                var record = cmp.get("record") || {};
                if (cmp.get("mode") === "new" && (!record.inputs || !record.inputs.length)) {
                    addToast("error", "Missing Joins", "Paths must have at least one Join");
                    return;
                }
                PsRecord.submitRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        handleDelete: function () {
            try {
                addToast("info", "Not Available", "");
            } catch (err) {
                console.error(err);
            }
        },

        handleOverride: function (cmp, event, helper) {
            try {
                var field = event.getSource().get("v.name");
                helper.setOverride(cmp, field);
            } catch (err) {
                console.error(err);
            }
        },

        handleRevert: function (field) {
            try {
                PsRecord.revertOverride(cmp, field);
            } catch (err) {
                console.error(err);
            }
        },

        handleRecordRowAction: function (action, row) {
            try {
                var parentId = cmp.get("parentId");
                var recordModule = cmp.get("recordModule");
                var recordObject = cmp.get("recordObject");

                var parentNav = cmp.get("parentId") || "";

                switch (action) {
                    case "details":
                        PsRecord.notifyNavigation(cmp, parentId, recordModule, recordObject, row.id);
                        break;
                    case "viewSource":
                        PsRecord.notifyNavigation(cmp, "sources", "core", "source", row.key.container.source.id);
                        break;
                    case "viewContainer":
                        PsRecord.notifyNavigation(cmp, row.key.container.source.id, "store", "container", row.key.container.id);
                        break;
                    case "viewKey":
                        PsRecord.notifyNavigation(cmp, parentNav, "store", "key", row.key.id);
                        break;
                    default:
                }
            } catch (err) {
                console.error(err);
            }
        },

        handleAddChainLink: function () {
            try {
                cmp.saveChainLink(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        handleRemoveChainLink: function () {
            try {
                cmp.removeLastChainLink();
            } catch (err) {
                console.error(err);
            }
        },

        handleLeftKeyChange: function (record) {
            try {
                cmp.setRightKeyFilter();
                cmp.set("leftKey", record);
            } catch (err) {
                console.error(err);
            }
        },

        handleRightKeyChange: function (record) {
            try {
                cmp.setRightKeyFilter();
                cmp.set("rightKey", record);
            } catch (err) {
                console.error(err);
            }
        },

        clearKeyFields: function (cmp) {
            cmp.set("leftKeySelected", null);
            cmp.set("leftKeyId", null);
            cmp.set("leftKey", null);
            cmp.set("rightKeySelected", null);
            cmp.set("rightKeyId", null);
            cmp.set("rightKey", null);
        },

        getDefaultRecord: function () {
            return { custom: true, accept: true, name: "" };
        },

        getOverrideFields: function () {
            return ["name", "accept"];
        },

        parseInputPlainText: function (record) {
            // TODO: revert overriden, then setting a new value doesn't work
            var data = record.id
                ? (({ id, name, accept, overridden }) => ({
                      id,
                      name,
                      accept,
                      overridden,
                  }))(record) // select fields that can be updated
                : (({ name, type, accept, leftContainerId, rightContainerId, inputs }) => ({
                      name,
                      type,
                      accept,
                      leftContainerId,
                      rightContainerId,
                      inputs,
                  }))(record); // select fields that can be created
            return data;
        },

        parseResponse: function (response) {
            return response.map(({ id, name, accept, leftContainer, rightContainer, relationship, lastRunOn, coverage, robustDistinct, relevance, overridden, custom, inputs }) => ({
                id,
                name,
                accept,
                leftContainerName: leftContainer.name,
                rightContainerName: rightContainer.name,
                relationship,
                lastRunOn,
                coverage,
                robustDistinct,
                relevance,
                overridden,
                custom,
                inputs,
            }));
        },

        setParent: function (record) {
            var parentId = record?.leftContainerId || record?.leftContainer?.id;
            cmp.set("parentId", parentId);
            cmp.leftContainerFilterToParent();
            return parentId;
        },

        leftContainerFilterToParent: function () {
            var parentId = cmp.get("parentId");
            if (parentId) {
                cmp.set("leftKeyFilters", {
                    keys: { key: { containerId: parentId } },
                });
            } else {
                cmp.set("leftKeyFilters", null);
            }
        },

        setRightKeyFilter: function () {
            var leftKey = cmp.get("leftKey");
            if (leftKey && leftKey.dataType && leftKey.dataType.dataFormat) {
                cmp.set("rightKeyFilters", {
                    keys: { key: { dataFormatId: leftKey.dataType.dataFormat.id } },
                });
            } else {
                cmp.set("rightKeyFilters", null);
            }
        },

        updateUI: function () {
            try {
                var chain = cmp.get("record") || {};
                var chainLinks = chain.inputs || [];
                // sort by argOrder
                var orderBy = "argOrder";
                var orderFunc = Record.sortFunction(chainLinks, orderBy);
                chainLinks.sort(orderFunc(orderBy));
                // set Key and Container details on records
                chainLinks.forEach((chainLink) => {
                    var link = chainLink.link || {};
                    var linkKeys = link.inputs || [];
                    // sort by (isLeft, argOrder)
                    var orderBy = "argOrder";
                    var orderFunc = Record.sortFunction(linkKeys, orderBy);
                    linkKeys.sort(orderFunc(orderBy));
                    orderBy = "isLeft";
                    orderFunc = Record.sortFunction(linkKeys, orderBy);
                    linkKeys.sort(orderFunc(orderBy, true));
                    linkKeys.forEach((linkKey) => {
                        var key = linkKey.key || {};
                        var container = key.container || {};
                        var source = container.source || {};
                        Object.assign(linkKey, {
                            name: (linkKey.isLeft ? "From: " : "To: ") + key.name,
                            keyName: key?.name,
                            robustDistinct: key.robustDistinct,
                            containerName: container.name,
                            sourceName: source.name,
                            _parentId: chainLink.id,
                        });
                    });
                    Object.assign(chainLink, {
                        name: link.name,
                        robustDistinct: link.robustDistinct,
                        object: "link",
                        _children: linkKeys,
                    });
                });

                const records = [];
                chainLinks.forEach((chainLink) => {
                    records.push(chainLink);
                    chainLink._children.forEach((child) => {
                        records.push(child);
                    });
                });
                cmp.set("dataTableRecords", records);
            } catch (err) {
                console.error(err);
            }
        },

        saveChainLink: function () {
            try {
                var leftKey = cmp.get("leftKey");
                var rightKey = cmp.get("rightKey");
                if (!leftKey || !rightKey) {
                    addToast("warning", "Missing Field", 'Select both a "From" and a "To" Field');
                    return;
                }

                var leftType = leftKey.dataType || {};
                var rightType = rightKey.dataType || {};
                if (!leftType.dataFormat || !rightType.dataFormat || leftType.dataFormat.id !== rightType.dataFormat.id) {
                    addToast("warning", "Mismatching Formats", '"From" and "To" Fields must have the same data format');
                    return;
                }
                var leftContainer = leftKey.container || {};
                var rightContainer = rightKey.container || {};
                // construct LinkKeys, Link and ChainLink records
                var record = cmp.get("record") || {};
                var chainLinks = record.inputs || [];
                var numChainLinks = chainLinks.length;
                var name = leftKey.container?.name + "." + leftKey.name + " + " + rightKey.container?.name + "." + rightKey.name;
                var linkKeys = [
                    {
                        id: Record.uuidv4(),
                        isLeft: true,
                        argOrder: 0,
                        keyId: leftKey.id,
                        key: leftKey,
                    },
                    {
                        id: Record.uuidv4(),
                        isLeft: false,
                        argOrder: 0,
                        keyId: rightKey.id,
                        key: rightKey,
                    },
                ];
                var link = {
                    name,
                    type: "Custom Record",
                    leftContainerId: leftContainer.id,
                    rightContainerId: rightContainer.id,
                    leftContainer,
                    rightContainer,
                    inputs: linkKeys,
                };
                var chainLink = { id: Record.uuidv4(), argOrder: numChainLinks, link };
                // update Chain record
                if (numChainLinks === 0) {
                    Object.assign(record, {
                        leftContainer,
                        leftContainerId: leftContainer.id,
                        leftContainerName: leftContainer.name,
                    });
                }
                Object.assign(record, {
                    rightContainer,
                    rightContainerId: rightContainer.id,
                    rightContainerName: rightContainer.name,
                });
                chainLinks.push(chainLink);
                record.inputs = chainLinks;
                // update filters
                cmp.set("leftKeyFilters", {
                    keys: { key: { containerId: rightContainer.id } },
                });
                cmp.set("rightKeyFilters", null);
                // update UI
                this.clearKeyFields(cmp);
                cmp.set("record", record);
                // the next Link's leftContainer has to match the current Link's rightContainer
                this.updateUI();
            } catch (err) {
                console.error(err);
            }
        },

        removeLastChainLink: function () {
            try {
                var record = cmp.get("record") || {};
                var chainLinks = record.inputs || [];
                var numChainLinks = chainLinks.length;
                if (numChainLinks) {
                    chainLinks.pop();
                    numChainLinks = chainLinks.length;
                }
                // update Chain.container fields
                if (numChainLinks) {
                    var lastChainLink = chainLinks[numChainLinks - 1];
                    var rightContainer = lastChainLink.link?.rightContainer || {};
                    cmp.set("leftKeyFilters", {
                        keys: { key: { containerId: rightContainer.id } },
                    });
                    Object.assign(record, {
                        rightContainer,
                        rightContainerId: rightContainer.id,
                        rightContainerName: rightContainer.name,
                    });
                } else {
                    this.leftContainerFilterToParent(cmp);
                    Object.assign(record, {
                        leftContainer: null,
                        leftContainerId: null,
                        leftContainerName: null,
                        rightContainer: null,
                        rightContainerId: null,
                        rightContainerName: null,
                    });
                }
                // update UI
                this.clearKeyFields(cmp);
                cmp.set("record", record);
                this.updateUI();
            } catch (err) {
                console.error(err);
            }
        },

        name: "PsChain",

        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        dispatchEvent: function (event) {
            props.childToParent(event);
        },

        bubbleEvent: function (event) {
            let stopPropagation = false;

            if (!stopPropagation) {
                props.childToParent(event);
            }
        },

        handleEvent: (event) => {
            if (event.type === "reload") {
                cmp.handleReload();
                //props.parentCmp.set("parentToChildEvent", {});
            }
        },

        handleClickOutsideOfPsNavigationInput: function (event) {
            if (!leftPsNavigationInputRef.current && !rightPsNavigationInputRef.current) {
                return;
            }

            const isExpanded = leftPsNavigationInputRef.current.contains(event.target) || rightPsNavigationInputRef.current.contains(event.target);
            if (cmpWorking.current.isExpanded !== isExpanded) {
                // only update if necessary to prevent rerendering other items that were clicked
                cmp.set("isExpanded", isExpanded);
            }
        },

        handleReset: function () {
            try {
                PsRecord.getRecord(cmp);
            } catch (err) {
                console.error(err);
            }
        },

        checkForm: function () {
            try {
                const record = cmp.get("record");
                if (!record.name) {
                    cmp.set("missingRequiredFields", ["record.name"]);
                }
                return !record.name;
            } catch (err) {
                console.error(err);
            }
        },

        body: function () {
            return (
                <div className="slds-form slds-var-m-around_medium" role="list">
                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Path Details</h3>
                    <div className="slds-form__row">
                        {/* <!-- Name --> */}
                        <NameInputWithUndoIcon cmpState={cmpState} cmp={cmp} />
                    </div>

                    <div className="slds-form__row">
                        {/* <!-- Left Container --> */}
                        <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {(cmpState.mode === "new" || cmpState.mode === "edit" || cmpState.mode === "view") && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">From</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.leftContainerName}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <!-- Right Container --> */}
                        <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {(cmpState.mode === "new" || cmpState.mode === "edit" || cmpState.mode === "view") && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">To</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">{cmpState.record?.rightContainerName}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="slds-form__row">
                        {/* <!-- Custom --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {(cmpState.mode === "new" || cmpState.mode === "edit" || cmpState.mode === "view") && (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Custom</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">
                                                <CheckAndCloseIcons selectedItem={cmpState.record?.custom} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <!-- Accept --> */}
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                {cmpState.mode === "init" && <ProgressBarComponent />}
                                {cmpState.mode === "view" ? (
                                    <div id="FormDiv" className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                                        <span className="slds-form-element__label">Use in Pattern Discovery</span>
                                        <div className="slds-form-element__control">
                                            <div className="slds-form-element__static">
                                                <CheckAndCloseIcons selectedItem={cmpState.record?.accept} />
                                            </div>
                                            <div className="slds-button slds-button__icon slds-button__icon_hint">
                                                <EditButtonIcon handleEdit={cmp.handleEdit} />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {cmpState.mode === "new" || cmpState.mode === "edit" ? (
                                    <div verticalalign="start">
                                        <label className="slds-form-element__label" htmlFor="toggle">
                                            Use in pattern discovery
                                        </label>
                                        <div style={{ display: "flex", width: "14em" }}>
                                            <label className="slds-checkbox_toggle slds-grid">
                                                <input type="checkbox" checked={cmpState.record?.accept || false} onChange={() => PsRecord.handleToggleChange(cmp, "accept")} />
                                                <span id="checkbox-toggle-16" className="slds-checkbox_faux_container">
                                                    <span className="slds-checkbox_faux"></span>
                                                    <span className="slds-checkbox_on">Yes</span>
                                                    <span className="slds-checkbox_off">No</span>
                                                </span>
                                            </label>{" "}
                                            {cmpState.record?.hasOverride?.accept && (
                                                <div
                                                    style={{
                                                        marginTop: "-25px",
                                                    }}
                                                >
                                                    <UndoButtonIcon handleRevert={cmp.handleRevert} field="accept" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    {(cmpState.mode === "init" || cmpState.mode === "view") && <Statistics cmp={cmp} cmpState={cmpState} />}
                    {(cmpState.mode === "init" || cmpState.mode === "new" || cmpState.mode === "view") && (
                        <>
                            <h3 className="slds-section-title--divider slds-var-m-top_medium">Joins</h3>
                            <p>&nbsp;</p>
                            <div className="slds-form__row">
                                <div className="slds-form__item" role="listitem">
                                    <div className="slds-form-element slds-form-element_stacked" id="gridRef" ref={gridRef}>
                                        {cmpState.mode === "init" && <ProgressBarComponent />}
                                        {(cmpState.mode === "view" || cmpState.mode === "new") && (
                                            <RecordTable
                                                columns={RECORD_COLUMNS}
                                                records={cmpState.dataTableRecords}
                                                tableWidth={gridWidth}
                                                orderBy="argOrder"
                                                orderDirection="asc"
                                                onOrderBy={() => {}}
                                                onRowAction={cmp.handleRecordRowAction}
                                                onScrollEnd={() => {}}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {cmpState.mode === "new" && (
                        <>
                            {/* <!-- Left and Right Keys to add --> */}
                            <div className="slds-form__row">
                                <div className="slds-form__item" role="listitem" ref={leftPsNavigationInputRef}>
                                    <PsNavigationInput
                                        label="From Field"
                                        object="key"
                                        sections={["keys"]}
                                        selected={cmpState.leftKeySelected}
                                        value={cmpState.leftKeyId}
                                        record={cmpState.leftKey}
                                        filters={cmpState.leftKeyFilters}
                                        onChange={cmp.handleLeftKeyChange}
                                        isExpanded={cmpState.isExpanded}
                                        activeField={cmpState.activeField}
                                        setParentCmpState={setCmpState}
                                        showRequiredFieldError={false}
                                        childToParent={cmp.bubbleEvent}
                                    />
                                </div>
                                <div className="slds-form__item" role="listitem" ref={rightPsNavigationInputRef}>
                                    <PsNavigationInput
                                        label="To Field"
                                        object="key"
                                        sections={["keys"]}
                                        selected={cmpState.rightKeySelected}
                                        value={cmpState.rightKeyId}
                                        record={cmpState.rightKey}
                                        filters={cmpState.rightKeyFilters}
                                        onChange={cmp.handleRightKeyChange}
                                        disabled={!cmpState.leftKey}
                                        isExpanded={cmpState.isExpanded}
                                        activeField={cmpState.activeField}
                                        setParentCmpState={setCmpState}
                                        showRequiredFieldError={false}
                                        childToParent={cmp.bubbleEvent}
                                    />
                                </div>
                            </div>

                            {/* <!-- Buttons for add / remove --> */}
                            <Buttons cmpState={cmpState} cmp={cmp} />
                        </>
                    )}
                </div>
            );
        },
    };

    return PsRecord.render(cmp, cmpState);
};

export default PsChain;
