import { Combobox, Input } from "@salesforce/design-system-react";
import RecordConstants from "../../../../constants/RecordConstants";
const { DATETIME_ROLLUP } = RecordConstants;
const groupingIntervalOptions = DATETIME_ROLLUP.map((opt) => ({
    id: opt.value,
    label: opt.label,
    disabled: true,
}));

// We need to simplify this component by collaborating with the backend team
// to better separate the selection logic for unit and interval.
const IntervalSelect = ({ index, inputSlot, onChange }) => {
    const skipDecimate = !!inputSlot.parameters?.skipDecimate;
    const hasMaxNumberLike = inputSlot.parameters?.maxNumberLike != null;
    const hasInterval = inputSlot.parameters?.interval != null;
    const showInterval = !skipDecimate && (hasMaxNumberLike || hasInterval) && (inputSlot.computed?.dataType?.format === "DateTime" || inputSlot.computed?.dataType?.format == "Number");

    const showUnitSelect = inputSlot.computed?.dataType?.name === "Time Difference" && showInterval; // if showUnitSelect is true what ever are showIntervalSelect, showIntervalText return

    const showIntervalSelect = inputSlot.computed?.dataType?.format === "DateTime" && showInterval;

    const showIntervalNumber = inputSlot.computed?.dataType?.format === "Number" && showInterval;

    function onChangeParameters(e) {
        const { name, value } = e.target;
        const updatedInputSlot = { ...inputSlot };
        // Check if `parameters` exists, if not, initialize it as an object
        if (!updatedInputSlot.parameters) {
            updatedInputSlot.parameters = {};
        }
        if (value === "") {
            delete updatedInputSlot.parameters[name];
        } else {
            updatedInputSlot.parameters[name] = value;
        }
        onChange(updatedInputSlot, index);
    }

    function onChangeInternalNumber(e) {
        const { name, value } = e.target;
        const updatedInputSlot = { ...inputSlot };
        // Check if `parameters` exists, if not, initialize it as an object
        if (!updatedInputSlot.parameters) {
            updatedInputSlot.parameters = {};
        }
        if (value === "") {
            delete updatedInputSlot.parameters[name];
        } else if (Number(value) <= 0) {
            // allow only integer > 0
            delete updatedInputSlot.parameters[name];
        } else {
            updatedInputSlot.parameters[name] = value;
        }
        onChange(updatedInputSlot, index);
    }

    function findSelection(value) {
        const findSelection = groupingIntervalOptions.find((opt) => opt.id === value);
        if (!!findSelection) return [findSelection];
        return undefined;
    }

    function disableOptions(minInterval) {
        const minIntervalIndex = groupingIntervalOptions.findIndex((opt) => opt.id === minInterval);
        return [...groupingIntervalOptions].map((opt, i) => ({
            ...opt,
            disabled: i < minIntervalIndex,
        }));
    }

    if (showUnitSelect)
        return (
            <Combobox
                options={groupingIntervalOptions}
                labels={{ label: "Grouping Interval" }}
                events={{
                    onSelect: (_e, v) =>
                        onChangeParameters({
                            target: { name: "unit", value: v.selection[0]?.id },
                        }),
                }}
                selection={findSelection(inputSlot.parameters?.unit || inputSlot.computed?.unit)}
                menuPosition="relative"
                menuItemVisibleLength={10}
                variant="readonly"
            />
        );

    return (
        <>
            {showIntervalSelect && (
                <Combobox
                    options={disableOptions(inputSlot.computed?.minInterval)}
                    labels={{ label: "Grouping Interval" }}
                    events={{
                        onSelect: (_e, v) =>
                            onChangeParameters({
                                target: { name: "interval", value: v.selection[0]?.id },
                            }),
                    }}
                    selection={findSelection(inputSlot.parameters?.interval || inputSlot.computed?.interval)}
                    menuPosition="relative"
                    menuItemVisibleLength={10}
                    variant="readonly"
                />
            )}
            {showIntervalNumber && (
                <Input type="number" label="Grouping Interval" name="interval" value={inputSlot.parameters?.interval || inputSlot.computed?.interval} onChange={onChangeInternalNumber} />
            )}
        </>
    );
};

export default IntervalSelect;
