import { useState } from "react";
import reactDom from "react-dom";

import Button from "@salesforce/design-system-react/components/button";
import { toastErrorMessage } from "../../../helpers/index.js";
import Record from "../../../helpers/recordLayer.js";
import { Link } from "react-router-dom";
import PatternSave from "../components/PatternSave.js";
import useToastContext from "../../../context/useToastContext";

const HeaderActionsIcons = ({ pattern, showEdit, onEdit, childToParent, parentToChildEvent, onLoading }) => {
    const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);

    const { addToast } = useToastContext();

    const handleLike = () => {
        markPatternLikedAndAddToFolder();
    };

    const handleDislike = () => {
        dislikePattern();
    };

    const handleSaveOpen = () => {
        setSaveModalIsOpen(true);
    };

    const handleSaveClose = () => {
        setSaveModalIsOpen(false);
    };

    const markPatternLikedAndAddToFolder = () => {
        var onSuccess = function (response) {
            if (response?.length) {
                likePattern(response[0].id);
            } else {
                createLikedFolderAndAddPattern();
            }
        };

        var onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
        };

        Record.getRecords("relate", "folder", { name: "Liked" }, onSuccess, onError);
    };

    const savePattern = (folder) => {
        var processed = JSON.parse(JSON.stringify(pattern || {}));
        // processed = (({ container, key, name, description, type, inputs, parameters, overridden, options, treeHash, version }) => ({
        //     container,
        //     key,
        //     name,
        //     description,
        //     type,
        //     inputs,
        //     parameters,
        //     overridden,
        //     options,
        //     treeHash,
        //     version,
        // }))(processed); // select fields that can be updated

        cleanInput(processed);

        var folderId = folder?.id;
        delete processed.id;
        processed.folderId = folderId;

        var onSuccess = function (response) {
            let patternId = response[0]?.id;
            let selected = Record.nameFromDetails("folders", "folder", folderId);
            const message = (
                <>
                    "<Link to={`/SavedInsights?selected=${selected}&pattern=${patternId}`}>{processed.namePlain || "Pattern"}</Link>" was saved successfully in: "
                    <Link to={`/SavedInsights?selected=${selected}`}>{folder?.name}</Link>"
                </>
            );
            addToast("success", "Pattern Saved", message);
            onLoading(false);
        };

        var onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            onLoading(false);
        };

        onLoading(true);
        Record.createRecord("relate", "pattern", JSON.stringify([processed]), onSuccess, onError);
    };

    const createLikedFolderAndAddPattern = () => {
        var onSuccess = function (response) {
            likePattern(response[0].id);
        };

        var onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
        };

        onLoading(true);
        Record.createRecord("relate", "folder", JSON.stringify([{ name: "Liked", description: "" }]), onSuccess, onError);
    };

    const likePattern = (folderId) => {
        const patternId = pattern.id;

        var onSuccess = function (response) {
            savePattern({ id: folderId, name: "Liked" });
            onLoading(false);
        };

        var onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            onLoading(false);
        };

        onLoading(true);
        Record.updateRecord("relate", "pattern", JSON.stringify({ relevanceExternal: 1 }), patternId, onSuccess, onError);
    };

    const dislikePattern = () => {
        const patternId = pattern?.id;

        var onSuccess = function (response) {
            // TODO: if the pattern was in the "Liked" folder, it must be removed
            onLoading(false);
            addToast("success", "Disliked Pattern", "Pattern marked as disliked");
        };

        var onError = function (response) {
            onLoading(false);
            addToast("error", "Error", toastErrorMessage(response));
        };

        onLoading(true);
        Record.updateRecord("relate", "pattern", JSON.stringify({ relevanceExternal: -1 }), patternId, onSuccess, onError);
    };

    const cleanInput = (obj) => {
        if (obj == null) {
            return;
        }

        //cleanInput(obj.options);

        const unwantedProperties = ["floatingId", "relevance", "relevanceIntrinsic", "custom", "computed"];
        const idProperties = ["key", "chain", "filter", "map", "source", "container"];

        for (const key in obj) {
            if (unwantedProperties.includes(key) || obj[key] == null) {
                delete obj[key];
                continue;
            }
            if (idProperties.includes(key)) {
                if (obj[key].id != null && obj[key].id !== "") {
                    obj[key + "Id"] = obj[key].id;
                    delete obj[key];
                    continue;
                }
            }
        }

        if (obj.filter != null) {
            cleanInput(obj.filter);
        }

        //recursively call cleanInput for all inputs
        if (obj.inputs != null && Array.isArray(obj.inputs)) {
            if (obj.inputs.length === 0) {
                delete obj.inputs;
                return;
            }
            obj.inputs.forEach((item) => {
                cleanInput(item);
            });
        }
    };

    function handleDeletePattern() {
        //dispatchEvent({ action: "gridItemDeleted", order, record: processed });
    }

    const handleCloseClick = () => {
        dispatchEvent({ type: "dataCompEvent", action: "close" });
    };

    return (
        <>
            <div className="header-actions-icons" style={{ margin: 0 }}>
                {pattern?.id && (
                    <>
                        <Button
                            assistiveText={{ icon: "Like this Pattern" }} //
                            iconCategory="utility"
                            iconName="like"
                            iconSize="large"
                            variant="icon"
                            onClick={handleLike}
                            title="Like this Pattern"
                        />
                        <Button
                            assistiveText={{ icon: "Dislike this Pattern" }}
                            iconCategory="utility"
                            iconName="dislike"
                            iconSize="large"
                            variant="icon"
                            onClick={handleDislike}
                            title="Dislike this Pattern"
                        />
                    </>
                )}
                {pattern && (
                    <Button assistiveText={{ icon: "Save this Pattern" }} iconCategory="utility" iconName="save" iconSize="large" variant="icon" onClick={handleSaveOpen} title="Save this Pattern" />
                )}
                {showEdit && (
                    <Button assistiveText={{ icon: "Edit this Pattern" }} iconCategory="utility" iconName="expand_alt" iconSize="large" variant="icon" onClick={onEdit} title="Edit this Pattern" />
                )}
            </div>

            {saveModalIsOpen
                ? reactDom.createPortal(
                      <PatternSave
                          folderId={pattern?.folder?.id || pattern?.folderId} //
                          onSave={savePattern}
                          onClose={handleSaveClose}
                          childToParent={childToParent}
                          parentToChildEvent={parentToChildEvent}
                      />,
                      document.body
                  )
                : null}
        </>
    );
};

export default HeaderActionsIcons;
