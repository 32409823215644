import Modal from "../../ui/Modal";
import { useEffect, useRef, useState } from "react";
import PsRecord from "../ps-record/PsRecord";
import { isValidEmail } from "../../utils";
import PsUserFormBody from "./components/PsUserFormBody";
import RecordConstants from "../../constants/RecordConstants";
import useAuthContext from "../../context/useAuthContext";

const PsUser = (props) => {
    const [fieldsError, setFieldError] = useState({ email: "", name: "" });
    const [cmpState, setCmpState] = useState({
        recordLabel: "User",
        recordModule: "core",
        recordObject: "user",
        loading: false,
        mode: "init",
        record: {},
        showEdit: true,
        showDelete: true,
        errorTextcheckFields: ["name"],
        missingRequiredFields: [],
        showDeleteConfirmDialog: false,
        statusOptions: Object.values(RecordConstants.GENERIC_STATUS),
        userTypesOptions: Object.values(RecordConstants.USER_TYPE),
    });
    const cmpWorking = useRef({});
    const { userDetails } = useAuthContext();

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        cmp.init();
    }, []);

    useEffect(() => {
        cmp.set("recordId", props.recordId);
        cmp.handleReload();
    }, [props.recordId]);

    useEffect(() => {
        if (!cmpState?.record?.id) return;
        // Hide delete button when the user is updating the own profile
        setCmpState((prev) => ({
            ...prev,
            showDelete: userDetails?.id !== cmpState.record.id,
        }));
    }, [userDetails, cmpState.record.id]);

    useEffect(() => {
        if (!props.parentToChildEvent) {
            return;
        }
        cmp.handleEvent(props.parentToChildEvent);
    }, [props.parentToChildEvent]);

    const cmp = {
        init: function () {},

        handleReload: function () {
            PsRecord.getRecord(cmp);
        },

        handleEdit: function () {
            PsRecord.setMode(cmp, "edit");
        },

        handleCancel: function () {
            PsRecord.cancelRecord(cmp);
        },

        // REMOVE this, because we can use generic error handling in PsRecord to show consistent error messages
        // // Handle a generic message  onCreate and onUpdate user Information
        // submitCallback: function (_cmp, isSuccess) {
        //   if (isSuccess) {
        //     addToast("success", "User Saved", "User details have been successfully saved.");
        //     return;
        //   }
        //   addToast("error", "Save Failed", "User details could not be saved. Please try again." );
        // },

        handleSubmit: function (cmp) {
            PsRecord.submitRecord(cmp);
        },

        handleDelete: function (cmp) {
            cmp.set("showDeleteConfirmDialog", true);
        },

        getDefaultRecord: function (_cmp) {
            return { name: "", email: "", status: "Active", type: "User", settings: null };
        },

        parseResponse: function (response) {
            return response.map(({ id, name, type, status, email, settings }) => ({
                id,
                name,
                type,
                status,
                email,
                settings,
            }));
        },

        handleFieldChange: function (e) {
            const { value, name } = e.target;
            let record = cmp.get("record");
            record = { ...record, [name]: value };
            cmp.set("record", record);
            if (value) {
                cmp.set("missingRequiredFields", []);
                setFieldError((prev) => ({ ...prev, [name]: "" }));
            }
        },

        dispatchEvent: function (event) {
            props.childToParent(event);
        },

        bubbleEvent: function (event) {
            let stopPropagation = false;

            if (!stopPropagation) {
                props.childToParent(event);
            }
        },

        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        handleEvent: (event) => {
            if (event.type === "reload") {
                cmp.handleReload();
                //props.parentCmp.set("parentToChildEvent", {});
            }
        },

        setParent: function (record) {
            var parentId = "users";
            cmp.set("parentId", parentId);
            return parentId;
        },

        // checkForm return true if there is an error
        checkForm: function () {
            try {
                const { name, email } = cmp.get("record");
                const isValidUserName = !!name && !name?.includes(" "); // type boolean
                const isValidUserEmail = isValidEmail(email); // type boolean
                if (!isValidUserName) {
                    const missingRequiredFields = cmp.get("missingRequiredFields");
                    setFieldError((prev) => ({
                        ...prev,
                        name: !name ? "Complete this field." : "This field required no empty space.",
                    }));
                    cmp.set("missingRequiredFields", [...missingRequiredFields, "record.name"]);
                }
                if (!isValidUserEmail) {
                    const missingRequiredFields = cmp.get("missingRequiredFields");
                    setFieldError((prev) => ({
                        ...prev,
                        email: !email ? "Complete this field." : "Invalid email format.",
                    }));
                    cmp.set("missingRequiredFields", [...missingRequiredFields, "record.email"]);
                }
                return !isValidUserName || !isValidUserEmail;
            } catch (err) {
                console.error(err);
            }
        },

        body: function () {
            return (
                <PsUserFormBody
                    mode={cmpState.mode}
                    record={cmpState.record}
                    handleEdit={cmp.handleEdit}
                    handleFieldChange={cmp.handleFieldChange}
                    getParentStateByKey={cmp.get}
                    fieldsError={fieldsError}
                />
            );
        },
    };

    return (
        <>
            {cmpState.showDeleteConfirmDialog ? (
                <Modal
                    apply={() => PsRecord.confirmDeleteRecord(cmp)}
                    cancel={() => PsRecord.cancelDeleteRecord(cmp)}
                    header="Confirmation"
                    modalContent="Are you sure you want to delete this User?"
                    applyButtonContent="Delete"
                />
            ) : null}
            {PsRecord.render(cmp, cmpState)}
        </>
    );
};

export default PsUser;
