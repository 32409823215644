import React from "react";
import { Button, ButtonGroup } from "@salesforce/design-system-react";

const HeaderActions = ({ mode, loading, recordLabel, showEdit, handleEdit, handleCancel, handleSave, showDelete, handleDelete, cardActions, cmp }) => {
    const editButton =
        showEdit && handleEdit && ["view", "empty"].includes(mode) ? (
            <Button
                key="editButton" //
                disabled={loading}
                title={`Edit this ${recordLabel}`}
                label="Edit"
                onClick={() => handleEdit(cmp)}
            />
        ) : null;

    const deleteButton =
        showDelete && handleDelete && !["init", "edit", "new"].includes(mode) ? (
            <Button
                key="deleteButton" //
                label="Delete"
                disabled={loading}
                title={`Delete this ${recordLabel}`}
                onClick={() => handleDelete(cmp)}
            />
        ) : null;

    const cancelButton =
        showEdit && handleCancel && ["edit"].includes(mode) ? (
            <Button
                key="cancelButton" //
                label="Cancel"
                disabled={loading}
                title={"Discard changes"}
                onClick={() => handleCancel(cmp)}
            />
        ) : null;

    const saveButton =
        showEdit && handleSave && ["edit"].includes(mode) ? (
            <Button
                key="saveButton" //
                label="Save changes"
                disabled={loading}
                title={"Save changes"}
                variant="brand"
                onClick={() => handleSave(cmp)}
            />
        ) : null;

    // wrap cardActions in Fragment to prevent: "warning: Each child in a list should have a unique "key" prop"
    const cardActionElements = cardActions ? <React.Fragment key="cardActions">{cardActions()}</React.Fragment> : null;
    const actions = [editButton, deleteButton, cancelButton, saveButton, cardActionElements].filter(Boolean);
    return actions.length > 0 ? <ButtonGroup variant="list">{actions}</ButtonGroup> : null;
};

export default HeaderActions;
