import React, { Component } from "react";
import "./PsErrorBoundary.css";

// Class component to catch errors in wrapped child components
class PsErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    // Function to handle going back to the previous page
    handleGoBack = () => {
        window.history.back();
        // Use a timeout to allow navigation to complete before reloading the page
        setTimeout(() => {
            window.location.reload(); // Force a page refresh to clear the error
        }, 100); // Navigate back to the previous page
    };

    render() {
        const { hasError } = this.state;
        const { fallback } = this.props; // props for PsErrorBoundary: children and fallback

        if (hasError && fallback === undefined) {
            return (
                <div className="ps-error-boundary-container">
                    <p className="slds-text-heading_medium">Something went wrong!</p>
                    <p>An unexpected error occurred. Please try again later.</p>
                    <button onClick={this.handleGoBack} className="boundary-back-btn">
                        Go Back
                    </button>
                </div>
            );
        }

        if (hasError && fallback !== undefined) {
            // If fallback is not undefined, return custom jsx element or null
            return fallback;
        }

        // Render children if no error
        return this.props.children;
    }
}

export default PsErrorBoundary;
