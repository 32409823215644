import { Button } from "@salesforce/design-system-react";

const Buttons = ({ cmpState, cmp }) => {
    return (
        <div className="slds-form__row slds-m-top_small">
            <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                    <Button
                        disabled={cmpState.loading || !cmpState.dataTableRecords || cmpState.dataTableRecords.length === 0}
                        label="Remove"
                        title="Remove last Link"
                        onClick={() => cmp.handleRemoveChainLink()}
                    />
                    <Button disabled={cmpState.loading || !cmpState.leftKey || !cmpState.rightKey} label="Add" title="Add new Link" onClick={() => cmp.handleAddChainLink()} variant="brand" />
                </div>
            </div>
        </div>
    );
};

export default Buttons;
