export const RECORD_COLUMNS = [
    {
        key: "name",
        label: "Name",
        property: "name",
        type: "link",
        href: "/StoreManagement?selected=containers_container_${id}", // eslint-disable-line no-template-curly-in-string
        action: "details",
        sortable: true,
        minWidth: 200,
    },
    {
        key: "numRows",
        label: "# Rows",
        property: "numRows",
        type: "number",
        width: 170,
    },
    //{ label: 'Relevance', fieldName: 'relevance', type: 'number', sortable: true},
    {
        key: "dataLastChangedOn",
        label: "Latest Data",
        property: "dataLastChangedOn",
        type: "formattedDate",
        width: 180,
    },
];
