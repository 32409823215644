import { createContext, useContext, useEffect, useState } from "react";
import Record from "../helpers/recordLayer";
import { Auth, Amplify } from "aws-amplify";
import { auth, endpoints, regions } from "../utils";
import Cookies from "js-cookie";
import RecordConstants from "../constants/RecordConstants";

// interface AuthContextReturnType {
// ... type of the object that return from useAuthContextProvider()
// }

const AuthContext = createContext(null); //type  AuthContextReturnType | null

const useAuthContextProvider = () => {
    // This state is for User Permission it contains only the user information
    const module = "core";
    const object = "user";
    const [userDetails, setUserDetails] = useState(null); // type null or object userDetails

    // This state is for User authentication authorization and it updates when user logs in or logs out
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const regionFromCookies = Cookies.get("region");
    const [user, setUser] = useState({
        // TODO: storing the password in an object is not secure. This needs to be refactored into more secure code.
        id: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        region: regionFromCookies || "",
    }); // type null or user auth object

    //----> Start  Get User details and user permission <----//
    useEffect(() => {
        // Call the function to fetch permissions
        fetchUserPermissions();
    }, [user?.id]);

    const onSuccessUserDetails = (res) => {
        if (!!res.length)
            setUserDetails({
                ...res[0],
                isAdmin: res[0].type === RecordConstants.USER_TYPE.ADMINISTRATOR.value,
                module,
                object,
            });
    };
    const onErrorUserDetails = () => {
        setUserDetails(null);
    };

    async function fetchUserPermissions() {
        // Check if user.id is not empty
        if (!user?.id) return;
        try {
            Record.getRecord(module, object, user.id, {}, "", "GET", onSuccessUserDetails, onErrorUserDetails);
        } catch (error) {
            console.error("Failed to fetch user details:", error);
        }
    }
    // ----> End Get User details and user permission <----//

    /////////////////////////////////////////////////////////

    //----> Start User Authentication flow <----//
    useEffect(() => {
        checkAuth();

        let region = user.region;
        if (regionFromCookies && regionFromCookies !== region) {
            region = regionFromCookies;
            setUser((prev) => ({ ...prev, region }));
        }

        // if regions has only 1 region
        if (regions().length === 1 && region !== regions()[0].value) {
            setUser((prevUser) => ({ ...prevUser, region: regions()[0].value }));
        }

        // every 5 minutes will check it;
        const sessionCheckInterval = setInterval(checkAuth, 300000);
        return () => {
            clearInterval(sessionCheckInterval);
        };
    }, []);

    const userHasAuthenticated = (authenticated) => {
        setIsAuthenticated(authenticated);
    };

    const handleLogout = async () => {
        await Auth.signOut();
        userHasAuthenticated(false);
        //Cookies.remove("region"); // keep the region
        setUserDetails(null);
    };

    const checkAuth = async () => {
        setIsAuthenticating(true);
        try {
            const isCurrentSession = await Auth.currentSession();
            if (isCurrentSession) {
                setUser((prev) => ({
                    ...prev,
                    username: isCurrentSession.accessToken.payload.username,
                    email: isCurrentSession.idToken.payload.email,
                    id: isCurrentSession.idToken.payload.sub,
                }));
                userHasAuthenticated(true);
            } else {
                handleLogout();
            }
        } catch (err) {
            if (err !== "No current user") {
                //log so that I can understand how to catch it in case the refresh token has expired
            }
        } finally {
            setIsAuthenticating(false);
        }
    };

    Amplify.configure({
        Auth: auth(user?.region || regionFromCookies),
        API: {
            endpoints: endpoints(user?.region || regionFromCookies),
        },
    });

    // ----> End User Authentication flow  <----//

    return {
        user,
        userDetails,
        isAuthenticated,
        isAuthenticating,
        setUser,
        checkAuth,
        handleLogout,
        userHasAuthenticated,
        refetchUserDetails: fetchUserPermissions,
    };
};

export const AuthContextProvider = (props) => {
    const value = useAuthContextProvider();
    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) throw new Error("You can't use useAuthContext() outside of <AuthContextProvider />");
    return context;
};

export default useAuthContext;
