export const allSettings = {
    overview: {
        root: { records: { connectors: { name: "Overview" } } },
        connectors: { records: { connectorList: { name: "Connectors" } } },
    },
    pump: {
        root: { records: { sources: { name: "Manage" } } },
        sources: { records: { source: { name: "Sources" } } },
        source: {
            childConfig: "connector",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
            searchField: "name",
        },
        connector: {
            childConfig: "object",
            module: "pump",
            object: "connector",
            parent: "source",
            parentField: "sourceId",
            searchField: "name",
        },
        object: {
            childConfig: "field",
            module: "pump",
            object: "object",
            parent: "connector",
            parentField: "connectorId",
            searchField: "name",
        },
        field: {
            module: "pump",
            object: "field",
            parent: "object",
            parentField: "objectId",
            searchField: "name",
        },
    },
    // storeData: { // TODO come on guys, don't copy-paste 130 lines of code if you need just a few changes
    //   root: { records: { sources: { name: "Manage" } } },
    //   sources: { records: { source: { name: "Sources" } } },
    //   source: {
    //     childConfig: "container",
    //     module: "core",
    //     object: "source",
    //     parent: null,
    //     parentField: null,
    //     searchField: "name",
    //   },
    //   container: {
    //     childConfig: "key",
    //     module: "store",
    //     object: "container",
    //     parent: "source",
    //     parentField: "sourceId",
    //     searchField: "name",
    //   },
    //   key: {
    //     module: "store",
    //     object: "key",
    //     parent: "container",
    //     parentField: "containerId",
    //     searchField: "name",
    //   },
    // },
    // storeMaps: {
    //   root: { records: { sources: { name: "Manage" } } },
    //   sources: { records: { source: { name: "Sources" } } },
    //   source: {
    //     childConfig: "container",
    //     module: "core",
    //     object: "source",
    //     parent: null,
    //     parentField: null,
    //     searchField: "name",
    //   },
    //   container: {
    //     childConfig: "map",
    //     module: "store",
    //     object: "container",
    //     parent: "source",
    //     parentField: "sourceId",
    //     searchField: "name",
    //   },
    //   map: {
    //     module: "store",
    //     object: "map",
    //     parent: "container",
    //     parentField: "containerId",
    //     searchField: "name",
    //   },
    // },
    // storeFilters: {
    //   root: { records: { sources: { name: "Manage" } } },
    //   sources: { records: { source: { name: "Sources" } } },
    //   source: {
    //     childConfig: "container",
    //     module: "core",
    //     object: "source",
    //     parent: null,
    //     parentField: null,
    //     searchField: "name",
    //   },
    //   container: {
    //     childConfig: "filter",
    //     module: "store",
    //     object: "container",
    //     parent: "source",
    //     parentField: "sourceId",
    //     searchField: "name",
    //   },
    //   filter: {
    //     module: "store",
    //     object: "filter",
    //     parent: "container",
    //     parentField: "containerId",
    //     searchField: "name",
    //   },
    // },

    // storeJoins: {
    //   root: { records: { sources: { name: "Manage" } } },
    //   sources: { records: { source: { name: "Sources" } } },
    //   source: {
    //     childConfig: "container",
    //     module: "core",
    //     object: "source",
    //     parent: null,
    //     parentField: null,
    //     searchField: "name",
    //   },
    //   container: {
    //     childConfig: "link",
    //     module: "store",
    //     object: "container",
    //     parent: "source",
    //     parentField: "sourceId",
    //     searchField: "name",
    //   },
    //   link: {
    //     module: "store",
    //     object: "link",
    //     parent: "leftContainer",
    //     parentField: "leftContainerId",
    //     searchField: "name",
    //   },
    // },

    // storeChains: {
    //   root: { records: { sources: { name: "Manage" } } },
    //   sources: { records: { source: { name: "Sources" } } },
    //   source: {
    //     childConfig: "container",
    //     module: "core",
    //     object: "source",
    //     parent: null,
    //     parentField: null,
    //     searchField: "name",
    //   },
    //   container: {
    //     childConfig: "chain",
    //     module: "store",
    //     object: "container",
    //     parent: "source",
    //     parentField: "sourceId",
    //     searchField: "name",
    //   },
    //   chain: {
    //     module: "store",
    //     object: "chain",
    //     parent: "leftContainer",
    //     parentField: "leftContainerId",
    //     searchField: "name",
    //   },
    // },

    aggs: {
        root: { records: { agg: { name: "Aggregations" } } },
        agg: {
            searchField: "name",
            records: {
                mean: { name: "Mean" },
                sum: { name: "Sum" },
                count: { name: "Count" },
                nunique: { name: "Unique Count" },
                min: { name: "Min" },
                max: { name: "Max" },
                median: { name: "Median" },
                range: { name: "Range" },
            },
        },
    },
    transforms: {
        root: { records: { transform: { name: "Transformations" } } },
        transform: {
            searchField: "name",
            records: {
                "Transform - Number - Add": { name: "Add" },
                "Transform - Time Difference": { name: "Subtract" },
                "Transform - Number - Multiply": { name: "Multiply" },
                "Transform - Number - Divide": { name: "Divide" },
                "Transform - Date and Time - Hour": { name: "Hour" },
                "Transform - Date and Time - Day of Week": { name: "Day of Week" },
                "Transform - Date and Time - Is Weekend": { name: "Is Weekend" },
                "Transform - Date and Time - Calendar Month": {
                    name: "Calendar Month",
                },
                "Transform - Date and Time - Calendar Quarter": {
                    name: "Calendar Quarter",
                },
            },
        },
    },
    types: {
        root: { records: { dataRole: { name: "Types" } } },
        dataRole: {
            childConfig: "dataType",
            module: "core",
            object: "datarole",
            parent: null,
            parentField: null,
            searchField: "name",
        },
        dataType: {
            module: "core",
            object: "datatype",
            parent: "dataRole",
            parentField: "dataRoleId",
            searchField: "name",
        },
    },
    data: {
        root: { records: { source: { name: "Data" } } },
        source: {
            childConfig: "container",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
            searchField: "name",
        },
        container: {
            childConfig: "key",
            module: "store",
            object: "container",
            parent: "source",
            parentField: "sourceId",
            searchField: "name",
        },
        key: {
            module: "store",
            object: "key",
            parent: "container",
            parentField: "containerId",
            searchField: "name",
        },
    },
    keys: {
        // same as 'data' section, but doesn't search containers and sources
        root: { records: { source: { name: "Fields" } } },
        source: {
            childConfig: "container",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
        },
        container: {
            childConfig: "key",
            module: "store",
            object: "container",
            parent: "source",
            parentField: "sourceId",
        },
        key: {
            module: "store",
            object: "key",
            parent: "container",
            parentField: "containerId",
            searchField: "name",
        },
    },
    containers: {
        root: { records: { source: { name: "Objects" } } },
        source: {
            childConfig: "container",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
        },
        container: {
            module: "store",
            object: "container",
            parent: "source",
            parentField: "sourceId",
            searchField: "name",
        },
    },
    links: {
        root: { records: { source: { name: "Joins" } } },
        source: {
            childConfig: "container",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
        },
        container: {
            childConfig: "link",
            module: "store",
            object: "container",
            parent: "source",
            parentField: "sourceId",
        },
        link: {
            module: "store",
            object: "link",
            parent: "leftContainer",
            parentField: "leftContainerId",
            searchField: "name",
        },
    },
    chains: {
        root: { records: { source: { name: "Paths" } } },
        source: {
            childConfig: "container",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
        },
        container: {
            childConfig: "chain",
            module: "store",
            object: "container",
            parent: "source",
            parentField: "sourceId",
        },
        chain: {
            module: "store",
            object: "chain",
            parent: "leftContainer",
            parentField: "leftContainerId",
            searchField: "name",
        },
    },
    filters: {
        root: { records: { source: { name: "Filters" } } },
        source: {
            childConfig: "container",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
        },
        container: {
            childConfig: "filter",
            module: "store",
            object: "container",
            parent: "source",
            parentField: "sourceId",
        },
        filter: {
            module: "store",
            object: "filter",
            parent: "container",
            parentField: "containerId",
            searchField: "name",
        },
    },
    maps: {
        root: { records: { source: { name: "Maps" } } },
        source: {
            childConfig: "container",
            module: "core",
            object: "source",
            parent: null,
            parentField: null,
        },
        container: {
            childConfig: "map",
            module: "store",
            object: "container",
            parent: "source",
            parentField: "sourceId",
        },
        map: {
            module: "store",
            object: "map",
            parent: "container",
            parentField: "containerId",
            searchField: "name",
        },
    },
    folders: {
        root: { records: { folder: { name: "Folders" } } },
        folder: {
            //childConfig: "folder", // this creates endless recursion
            module: "relate",
            object: "folder",
            parent: null,
            parentField: "parentFolderId",
            searchField: "name",
        },
    },

    // // Build page, only names were emptied
    // buildData: {
    //     root: { records: { source: { name: "" } } },
    //     source: {
    //         childConfig: "container",
    //         module: "core",
    //         object: "source",
    //         parent: null,
    //         parentField: null,
    //         searchField: "name",
    //     },
    //     container: {
    //         childConfig: "key",
    //         module: "store",
    //         object: "container",
    //         parent: "source",
    //         parentField: "sourceId",
    //         searchField: "name",
    //     },
    //     key: {
    //         module: "store",
    //         object: "key",
    //         parent: "container",
    //         parentField: "containerId",
    //         searchField: "name",
    //     },
    // },
    // buildAggs: {
    //     root: { records: { agg: { name: "" } } },
    //     agg: {
    //         searchField: "name",
    //         records: {
    //             mean: { name: "Mean" },
    //             sum: { name: "Sum" },
    //             count: { name: "Count" },
    //             nunique: { name: "Unique Count" },
    //             min: { name: "Min" },
    //             max: { name: "Max" },
    //             median: { name: "Median" },
    //             range: { name: "Range" },
    //         },
    //     },
    // },
    // buildTransforms: {
    //     root: { records: { transform: { name: "" } } },
    //     transform: {
    //         searchField: "name",
    //         records: {
    //             "Transform - Number - Add": { name: "Add" },
    //             "Transform - Time Difference": { name: "Subtract" },
    //             "Transform - Number - Multiply": { name: "Multiply" },
    //             "Transform - Number - Divide": { name: "Divide" },
    //             "Transform - Date and Time - Hour": { name: "Hour" },
    //             "Transform - Date and Time - Day of Week": { name: "Day of Week" },
    //             "Transform - Date and Time - Is Weekend": { name: "Is Weekend" },
    //             "Transform - Date and Time - Calendar Month": {
    //                 name: "Calendar Month",
    //             },
    //             "Transform - Date and Time - Calendar Quarter": {
    //                 name: "Calendar Quarter",
    //             },
    //         },
    //     },
    // },
    // buildTypes: {
    //     root: { records: { dataRole: { name: "" } } },
    //     dataRole: {
    //         childConfig: "dataType",
    //         module: "core",
    //         object: "datarole",
    //         parent: null,
    //         parentField: null,
    //         searchField: "name",
    //     },
    //     dataType: {
    //         module: "core",
    //         object: "datatype",
    //         parent: "dataRole",
    //         parentField: "dataRoleId",
    //         searchField: "name",
    //     },
    // },
    // buildChains: {
    //     root: { records: { source: { name: "" } } },
    //     source: {
    //         childConfig: "container",
    //         module: "core",
    //         object: "source",
    //         parent: null,
    //         parentField: null,
    //     },
    //     container: {
    //         childConfig: "chain",
    //         module: "store",
    //         object: "container",
    //         parent: "source",
    //         parentField: "sourceId",
    //     },
    //     chain: {
    //         module: "store",
    //         object: "chain",
    //         parent: "leftContainer",
    //         parentField: "leftContainerId",
    //         searchField: "name",
    //     },
    // },
    setup: {
        root: { records: { users: { name: "Overview" } } },
        users: { records: { userList: { name: "Users" } } },
        // "key": { records: { "key": { name: "Company Settings" } } },// ---> Need for future features
    },
    profile: {
        root: { records: { users: { name: "Overview" } } },
        users: { records: { userList: { name: "User" } } },
        // "key": { records: { "key": { name: "Local Settings" } } },// ---> Need for future features
        user: {
            module: "core",
            object: "user",
            parent: null,
            parentField: null,
            searchField: "id",
        },
    },
};
