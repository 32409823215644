import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { Button, Icon, IconSettings, Modal, Spinner } from "@salesforce/design-system-react";

import "./InputSlotModal.css";
import InputSlotForm from "./InputSlotForm";
import { flattenWithIndex } from "./helpers";

const nodeMap = ["Value", "Value Grouping", "Breakdown", "Detail", "Filter", "Value Select"];

const InputSlotModal = ({ pattern, selectedInput, onClose, onPatternChange }) => {
    const title = ["table", "data"].includes(pattern?.option?.chartType) ? "Columns" : "Axis";
    const [axisTabs, setAxisTabs] = useState([]);
    const [indexInput, setIndexInput] = useState(-1);
    const [inputsData, setInputsData] = useState([]);
    const [initialName, setInitialName] = useState("");
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    useEffect(() => {
        if (!pattern?.inputs?.length) return;
        const performOperations = async () => {
            setLoading(true); // set loading true in case that are many tabs
            try {
                const counterMap = {
                    0: 0, // Value
                    1: 0, // Value Grouping
                    2: 0, // Breakdown
                    3: 0, // Detail
                    4: 0, // Filter
                    5: 0, // Value Select
                };
                const tabs = [...pattern.inputs]
                    // sort tabs depending on argOrder||nodeOrder||floatingId
                    .sort((a, b) => {
                        if (a.argOrder !== b.argOrder) {
                            return a.argOrder - b.argOrder;
                        }
                        if (a.nodeOrder !== b.nodeOrder) {
                            return a.nodeOrder - b.nodeOrder;
                        }
                        return a.floatingId.localeCompare(b.floatingId);
                    })
                    // filter tabs depending on (type=Where || Nested)  or (transient=true)
                    .filter((item) => (item.type !== "Where" && item.type !== "WhereLogic" && item.type !== "Nested") || item.transient)
                    .map(({ floatingId, name, argOrder }, i) => {
                        const counter = ++counterMap[argOrder] || 0; // set counter for each argOrder order in case there is no name
                        return {
                            key: floatingId,
                            label: name || `${nodeMap[argOrder]} ${counter}`,
                            title: name, // keep tha name in this state to use it for initial name on undo name action
                        };
                    });
                setAxisTabs(tabs);
                const tab = tabs.find((tab) => tab.key === selectedInput) || tabs[0]; // TODO: there is a lot of searching of inputs with find. Please create a map (object) to find the relevant items directly instead of searching through an array (in fact, multiple times)
                handleSelectTab(tab);
                setInputsData([...pattern.inputs]);
            } catch (error) {
                // Handle any errors that occur
                console.error("Error processing inputs:", error);
            } finally {
                // Set loading to false regardless of success or error
                setLoading(false);
            }
        };
        performOperations();
    }, [pattern, selectedInput]);

    function handleChangeTab(activeKey) {
        const tab = axisTabs.find((tab) => tab.key === activeKey);
        if (!!tab) handleSelectTab(tab);
    }

    function handleSelectTab(tab) {
        const { title, key } = tab;
        const findIndex = pattern?.inputs.findIndex((slot) => slot.floatingId === key);
        setIndexInput(findIndex);
        setInitialName(title);
    }

    function handleSlotChange(slot, index) {
        const updateSlots = [...inputsData];
        updateSlots[index] = slot;
        setInputsData(updateSlots);
    }

    function onChangeAllChains(oldChainId, newChain, pathIndex) {
        const deepCopyInputs = JSON.parse(JSON.stringify(inputsData)); // Deep copy
        const updateInputs = deepCopyInputs.map((inputSlot) => {
            const flattenedInput = flattenWithIndex(inputSlot, "inputs");
            if (flattenedInput[pathIndex]?.chain?.id === oldChainId) {
                flattenedInput[pathIndex].chain = newChain;
            }
            return inputSlot;
        });
        setInputsData(updateInputs);
    }

    function handleSave() {
        onPatternChange({ ...pattern, inputs: inputsData }, true);
        onClose();
    }

    return (
        <IconSettings iconPath="/assets/icons">
            <Modal
                className="input-slot-modal"
                isOpen={true}
                onRequestClose={onClose}
                size="medium"
                align="top"
                heading={
                    <>
                        {`Edit ${title}`}
                        {axisTabs.length && (
                            <Tabs
                                className="input-slot-tabs"
                                defaultActiveKey={selectedInput}
                                popupClassName="input-slot-tabs-more"
                                items={axisTabs}
                                onChange={handleChangeTab}
                                moreIcon={<Icon assistiveText={{ label: "down" }} category="utility" name="down" size="x-small" />}
                            />
                        )}
                    </>
                }
                footer={
                    <>
                        <Button onClick={onClose} label="Cancel" variant="outline-brand" />
                        <Button onClick={() => handleSave(inputsData)} label="Save" variant="brand" />
                    </>
                }
            >
                {(loading || formLoading) && <Spinner containerClassName="input-slot-modal-spinner" />}
                {indexInput >= 0 && (
                    <InputSlotForm
                        title={title}
                        inputSlot={inputsData[indexInput]}
                        index={indexInput}
                        onSlotChange={handleSlotChange}
                        initialName={initialName}
                        setLoading={setFormLoading}
                        onChangeAllChains={onChangeAllChains}
                    />
                )}
            </Modal>
        </IconSettings>
    );
};

export default InputSlotModal;
