import { useEffect, useRef, useState } from "react";
import { Input } from "@salesforce/design-system-react";

import CustomPopover from "../../../ui/custom-popover/CustomPopover";
import Record from "../../../helpers/recordLayer";
import EditButtonIcon from "../../../ui/EditButtonIcon"; // TODO: use lightning design utility icon
import { coloredText } from "../../../utils";

const maxNameCharacter = 255;
const EditablePatternName = ({ pattern, onPatternChange }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [name, setName] = useState("");

    const popoverRef = useRef({});
    const inputRef = useRef({});

    useEffect(() => {
        setName(pattern?.namePlain);
    }, [pattern]);

    const handleRevertName = () => {
        // TODO: use new Field component overridden functionality
        const truncated = (pattern?.original?.name || "").slice(0, maxNameCharacter);

        setName(truncated);
        setIsEdit(false);
        updatePattern(truncated);
    };

    const handleCancelNameEdit = (e) => {
        setName(pattern?.namePlain);
        setIsEdit(false);
    };

    const handleSavePatternName = () => {
        if (name?.length > maxNameCharacter) {
            return;
        }

        setIsEdit(false);
        updatePattern(name);
    };

    const updatePattern = (name) => {
        // TODO: use new Field component overridden functionality
        const namePlain = Record.removeMarkup(name);
        const nameMarkup = Record.markupToHtml(name);
        const updatedPattern = { ...pattern, name, namePlain, nameMarkup, overridden: { ...pattern?.overridden, name: pattern?.name } };
        onPatternChange(updatedPattern, false);
    };

    const popoverContent = (
        <>
            {/* {TODO: use new Field component for this} */}
            <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
                <Input
                    value={name || ""}
                    placeholder="Enter a name"
                    onChange={(e) => setName(e.target.value)}
                    inputRef={(input) => {
                        if (input && !inputRef.current) {
                            inputRef.current = input;
                            input.focus();
                        }
                    }}
                />
                {name && (
                    <button
                        className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
                        title="Clear"
                        onClick={() => setName("")}
                        style={{
                            top: 15,
                        }}
                    >
                        <svg className="slds-button__icon slds-icon-text-light" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
                        </svg>
                        <span className="slds-assistive-text">Clear</span>
                    </button>
                )}
            </div>
            {name?.length > maxNameCharacter && ( // TODO: use input checking validation function for this from new Field Component
                <div className="slds-form-element slds-has-error">
                    <div className="slds-form-element__help" id="error-01">
                        Name cannot be longer than {maxNameCharacter} characters.
                    </div>
                </div>
            )}
        </>
    );

    return (
        pattern?.inputs && (
            <div className="slds-p-horizontal_medium slds-m-bottom_x-small slds-grid slds-align-bottom">
                {isEdit ? (
                    <CustomPopover
                        popoverRef={popoverRef}
                        title=""
                        content={popoverContent}
                        onSave={handleSavePatternName}
                        onCancel={handleCancelNameEdit}
                        hasRevertButton={pattern?.hasOverride?.name}
                        onRevert={handleRevertName}
                        onClickOutside={() => setIsEdit(false)}
                    />
                ) : (
                    <>
                        <div className={pattern?.nameMarkup ? "slds-truncate" : "slds-truncate slds-text-color_weak"} onClick={(e) => setIsEdit(true)} title={pattern?.namePlain}>
                            {pattern?.nameMarkup ? coloredText(pattern?.nameMarkup || "") : "Enter a name..."}
                        </div>

                        <div className="slds-m-left_xx-small slds-shrink-none">
                            <EditButtonIcon handleEdit={(e) => setIsEdit(true)} />
                        </div>
                    </>
                )}
            </div>
        )
    );
};

export default EditablePatternName;
