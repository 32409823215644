import Record from "../../../../helpers/recordLayer";
import { mapConstraintsWithEndPoint } from "./constants";

// Check if id is label
export function isIdLabel(config) {
    return config === "dataType" || config === "dataRole" || config === "dataFormat";
}

// This function it create a query for all unique id and config for all values in boxes
export function createQueryArray(data) {
    const configMap = {};
    data.forEach((item) => {
        // If the config type doesn't exist yet, create an empty array for it
        if (!configMap[item.config]) {
            configMap[item.config] = [];
        }
        // Add the id to the corresponding config type
        configMap[item.config].push(item.id);
    });
    // Convert the grouped config data to the desired format
    return Object.keys(configMap).map((config) => {
        const query = configMap[config]
            .map((id) => `${isIdLabel(config) ? "name" : "id"}=${id}`) // Map ids to the query parameter format
            .join("&"); // Join them with '&'

        return { config, query: `?${query}` };
    });
}

// TODO: this logic can come from PSNavigationTree
export function getDropBoxTitle(config, resValue) {
    if (config === "container") return `${resValue?.source?.name} -> ${resValue.name}`;
    if (config === "key") return `${resValue?.container?.source?.name} -> ${resValue?.container?.name} -> ${resValue?.name}`;
    if (config === "chain") return `${resValue?.leftContainer?.source?.name} -> ${resValue?.leftContainer?.name} -> ${resValue?.name}`;
    if (config === "dataType") return `${resValue?.dataRole?.name} -> ${resValue?.name}`;
    // add map ....
    return null;
}

// This function it returns the label and title for the values of all boxes
export async function fetchLabelTitle(queriesArray) {
    let allNames = [];
    await Promise.all(
        queriesArray.map(async (item) => {
            const { config, query } = item;
            if (config === "transform" || config === "agg") return;

            const findEndPoint = mapConstraintsWithEndPoint.find((d) => d.config === config);
            if (!findEndPoint) return;
            return new Promise((resolve, reject) => {
                function onSuccess(dataRes) {
                    if (dataRes?.length) {
                        const arrayData = dataRes.map((v) => {
                            return {
                                id: isIdLabel(config) ? v.name : v.id,
                                label: v.name,
                                config: config,
                                title: getDropBoxTitle(config, v) || v.name,
                            };
                        });
                        allNames = allNames.concat(arrayData);
                    }
                    resolve();
                }
                function onError(errorD) {
                    reject(errorD);
                }

                Record.makeCallout(
                    {
                        path: `${findEndPoint.endPoint}${query}`,
                        method: "GET",
                        body: "",
                    },
                    false,
                    onSuccess,
                    onError
                );
            });
        })
    );
    return allNames;
}

// find the value that has "in" key (opposite of createConstraintValue function )
export function findInKey(obj) {
    let result = null;
    // Iterate through the entries of the object
    Object.entries(obj).forEach(([key, value]) => {
        if (key === "in") {
            result = value; // If key is "in", store the value
        } else if (typeof value === "object" && value != null) {
            // If the value is an object, recursively search it
            const nestedResult = findInKey(value);
            if (nestedResult) result = nestedResult;
        }
    });

    return result;
}

// This function return the correct structure for constraint object dementing on mapConstraintsWithEndPoint static data OR null if there is no values
export function createConstraintValue(values) {
    if (!values?.length) return null;
    let value = {};
    const groupedByConfig = values.reduce((acc, item) => {
        if (!acc[item.config]) {
            acc[item.config] = [];
        }
        acc[item.config].push(item.id);
        return acc;
    }, {});
    mapConstraintsWithEndPoint.forEach((v) => {
        const valuesArray = groupedByConfig[v.config];
        if (!!valuesArray) {
            value = {
                ...value,
                ...v.onReturnStructure(valuesArray),
            };
        }
    });
    return value;
}

export function arraysWithEqualValues(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    // Sort both arrays by the `slot` field (which is a string)
    const sortedArr1 = [...arr1].sort((a, b) => a.slot.localeCompare(b.slot));
    const sortedArr2 = [...arr2].sort((a, b) => a.slot.localeCompare(b.slot));

    for (let i = 0; i < sortedArr1.length; i++) {
        // Compare the slot for both arrays
        if (sortedArr1[i].slot !== sortedArr2[i].slot) {
            return false;
        }
        // Compare the lengths of the values array
        if (sortedArr1[i].values.length !== sortedArr2[i].values.length) {
            return false;
        }
        // Sort values by id for comparison
        const sortedArrValues1 = [...sortedArr1[i].values].sort((a, b) => a.id.localeCompare(b.id));
        const sortedArrValues2 = [...sortedArr2[i].values].sort((a, b) => a.id.localeCompare(b.id));
        // Compare each id in the values array
        for (let j = 0; j < sortedArrValues1.length; j++) {
            if (sortedArrValues1[j].id !== sortedArrValues2[j].id) {
                return false;
            }
        }
    }

    return true;
}

// TODO: we already have this function somewhere else, as we check input fields for valid JSON in the Key record page.
export function isValidJSON(str) {
    try {
        JSON.parse(str); // Try to parse the string
        return true; // If parsing is successful, it's valid JSON
    } catch (e) {
        return false; // If an error occurs, it's not valid JSON
    }
}

export function dropBoxValidation(item, slot, from) {
    return true;
}
