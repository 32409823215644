import React from "react";
// TODO: this could be a way more generic 'Pill' component, just pass the label directly instead of the whole record!
interface RecordItemProps {
    // TO DO this any types will change with correct types
    record: any; // TEMP
    index: any; // TEMP
    handleRemoveItem: () => void;
    maxWidth: string;
}

const RecordItem: React.FC<RecordItemProps> = ({ record, index, handleRemoveItem, maxWidth }) => {
    return (
        <div className="record-item-container" key={record.id} style={{ maxWidth: `${maxWidth}` }}>
            <span id={index} className="slds-pill pill">
                <span id={index} title={record.title} className="slds-pill__label pill-label">
                    {record.label}
                </span>
                <button id={index} className="slds-button slds-button_icon" title="Remove" onClick={handleRemoveItem}>
                    <svg className="slds-button__icon slds-button__icon_small" aria-hidden="true">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                    </svg>
                    <span className="slds-assistive-text">Remove</span>
                </button>
            </span>
        </div>
    );
};

export default RecordItem;
