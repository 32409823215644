import { FilterQueryType } from "./types";

// Helpers function for api calls
export function buildQueryString(filters?: FilterQueryType): string {
    if (!filters || Object.keys(filters).length === 0) {
        return ""; // Return empty string if no filters
    }
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(filters)) {
        if (Array.isArray(value)) {
            value.forEach((item) => params.append(key, String(item))); // #TODO: needs URL Encoding for keys and values!
        } else if (value != null) {
            params.append(key, String(value)); // #TODO: needs URL Encoding for keys and values!
        }
    }
    return `?${params.toString()}`;
}
