import Icon from "@salesforce/design-system-react/components/icon";

import FilterSet from "../../filter-set/FilterSet";
import { useState } from "react";

const Filters = ({ pattern, onPatternChange, handleEvent, childToParent }) => {
    const [showSection, setShowSection] = useState(true);

    return (
        pattern?.showFilters && (
            <div className="slds-m-horizontal_x-small" style={{ flexBasis: "300px", flexGrow: 1, minWidth: "300px" }}>
                <div className={`slds-section ${showSection ? "slds-is-open" : "slds-is-close"}`}>
                    <h3>
                        <button className="slds-button slds-section__title-action" onClick={() => setShowSection(!showSection)}>
                            <span>
                                <Icon assistiveText={{ label: "switch section" }} category="utility" name="switch" size="x-small" className="slds-section__title-action-icon slds-button__icon_left" />
                            </span>
                            <span>Filters</span>
                        </button>
                    </h3>

                    <div className="slds-section__content">
                        <FilterSet pattern={pattern} onPatternChange={onPatternChange} handleEvent={handleEvent} childToParent={childToParent} />
                    </div>
                </div>
            </div>
        )
    );
};

export default Filters;
