import React from "react";
import { Button, ButtonGroup } from "@salesforce/design-system-react";

const HeaderActions = ({ mode, loading, recordLabel, handleEdit, handleDelete, handleCancel, handleSave, cardActions, cmp }) => {
    const editButton =
        handleEdit && mode === "view" ? (
            <Button
                key="editButton" //
                disabled={loading}
                title={`Edit this ${recordLabel}`}
                label="Edit"
                onClick={() => handleEdit(cmp)}
            />
        ) : null;

    const deleteButton =
        handleDelete && !["init", "edit", "new"].includes(mode) ? (
            <Button
                key="deleteButton" //
                label="Delete"
                disabled={loading}
                title={`Delete this ${recordLabel}`}
                onClick={() => handleDelete(cmp)}
            />
        ) : null;

    // wrap cardActions in Fragment to prevent: "warning: Each child in a list should have a unique "key" prop"
    const cardActionElements = cardActions ? <React.Fragment key="cardActions">{cardActions()}</React.Fragment> : null;
    const actions = [editButton, deleteButton, cardActionElements].filter(Boolean);
    return actions.length > 0 ? <ButtonGroup variant="list">{actions}</ButtonGroup> : null;
};

export default HeaderActions;
