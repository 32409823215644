export const RECORD_COLUMNS = [
    {
        label: "Name",
        type: "link",
        property: "name",
        key: "name",
        sortable: true,
        title: "View Details",
        action: "details",
        minWidth: 200,
        href: "/DataManagement?selected=pump_field_${id}", // eslint-disable-line no-template-curly-in-string
    },
    {
        label: "Source Identifier",
        width: 200,
        property: "sourceIdentifier",
        key: "sourceIdentifier",
        type: "text",
        cellAttributes: { alignment: "left" },
    },
    {
        label: "Status",
        width: 120,
        property: "status",
        key: "status",
        type: "text",
        sortable: true,
        cellAttributes: { alignment: "left" },
    },
    {
        label: "# Records Loaded",
        width: 170,
        property: "totalRecords",
        key: "totalRecords",
        type: "number",
        sortable: true,
    },
    {
        label: "Found in Source",
        width: 140,
        property: "sourceExists",
        key: "sourceExists",
        type: "boolean",
    },
    {
        label: "Restrictions",
        width: 140,
        property: "restrictions",
        key: "restrictions",
        type: "text",
    },
];
