export const transformMap = [
    { id: "Transform - Number - Add", label: "Add" },
    { id: "Transform - Time Difference", label: "Subtract" },
    { id: "Transform - Number - Multiply", label: "Multiply" },
    { id: "Transform - Number - Divide", label: "Divide" },
    { id: "Transform - Date and Time - Hour", label: "Hour" },
    { id: "Transform - Date and Time - Day of Week", label: "Day of Week" },
    { id: "Transform - Date and Time - Is Weekend", label: "Is Weekend" },
    { id: "Transform - Date and Time - Calendar Month", label: "Calendar Month" },
    {
        id: "Transform - Date and Time - Calendar Quarter",
        label: "Calendar Quarter",
    },
];

export const aggregationsMap = [
    { id: "mean", label: "Mean" },
    { id: "sum", label: "Sum" },
    { id: "count", label: "Count" },
    { id: "nunique", label: "Unique Count" },
    { id: "min", label: "Min" },
    { id: "max", label: "Max" },
    { id: "median", label: "Median" },
    { id: "range", label: "Range" },
];

export const boxes = [
    {
        order: 1,
        name: "Value",
        slot: "val",
        info: "The value or values to be shown",
        values: [],
    },

    {
        order: 2,
        name: "Breakdown",
        slot: "xst",
        info: "Break down the values",
        values: [],
    },

    {
        order: 3,
        name: "Detail",
        slot: "xnd",
        info: "Break down the values further on a separate axis",
        values: [],
    },

    {
        order: 4,
        name: "Value Grouping",
        slot: "qsh",
        info: "Group and aggregate values before breaking down",
        values: [],
    },

    {
        order: 5,
        name: "Value Select",
        slot: "acr",
        info: "Specify how to select a single row from each group in the Value Grouping",
        values: [],
    },
];

export const mapConstraintsWithEndPoint = [
    {
        endPoint: "/store/key",
        config: "key",
        prefix: "Field",
        onReturnStructure: (array) => ({ key: { id: { in: array } } }),
    },
    {
        endPoint: "/store/map",
        config: "map",
        prefix: "Map",
        onReturnStructure: (array) => ({ map: { id: { in: array } } }),
    },
    {
        endPoint: "/store/container",
        config: "container",
        prefix: "Container",
        onReturnStructure: (array) => ({ container: { id: { in: array } } }),
    },
    {
        endPoint: "/core/source",
        config: "source",
        prefix: "Source",
        onReturnStructure: (array) => ({ source: { id: { in: array } } }),
    },
    {
        endPoint: "/store/chain",
        config: "chain",
        prefix: "Chain",
        onReturnStructure: (array) => ({ chain: { id: { in: array } } }),
    },
    {
        endPoint: "",
        config: "agg", // aggregate
        prefix: "Aggregate", // aggregate
        onReturnStructure: (array) => ({ aggregate: { function: { in: array } } }), // the first structure key is not same with config
    },
    {
        endPoint: "",
        config: "transform",
        prefix: "Transform",
        onReturnStructure: (array) => ({ node: { type: { in: array } } }), // the first structure key is not same with config
    },
    {
        endPoint: "/core/datatype",
        config: "dataType",
        prefix: "Data Type",
        onReturnStructure: (array) => ({ dataType: { name: { in: array } } }),
    },
    {
        endPoint: "/core/dataformat",
        config: "dataFormat",
        prefix: "Data Format",
        onReturnStructure: (array) => ({ dataType: { format: { in: array } } }),
    },
    {
        endPoint: "/core/datarole",
        config: "dataRole",
        prefix: "Data Role",
        onReturnStructure: (array) => ({ dataRole: { role: { in: array } } }),
    },
];
