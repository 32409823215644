export const RECORD_COLUMNS = [
    {
        key: "name",
        label: "Folder Name",
        property: "name",
        type: "link",
        action: "details",
        minWidth: 200,
    },
    {
        key: "description",
        label: "Description",
        property: "description",
        type: "text",
        action: "text",
        minWidth: 200,
    },
];
