import React, { useMemo, useEffect, useState } from "react";
import { DataTable, DataTableCell, DataTableColumn, IconSettings } from "@salesforce/design-system-react";
import "@salesforce/design-system-react/assets/styles/table.css";

import "./RecordTable.css";
import CheckIcon from "../../CheckIcon.js";
import { CellPropsType, ColumnType, RecordTableProps } from "./types.js";
import { formattedDateTime, formattedPercentage } from "../../../utils/index.js";

const RecordTable = ({ columns, records, orderBy, orderDirection, tableWidth, onOrderBy, onRowAction }: RecordTableProps) => {
    const minColumnWidth = 50;
    const maxColumnWidth = 1000;

    const [localRecords, setLocalRecords] = useState([]);

    const CustomDataTableCell = (props: CellPropsType) => {
        const { columnDefinition, onRowAction, children, ...cellProps } = props;
        if (columnDefinition.type === "link") {
            let value = children == null ? null : String(children);
            cellProps.title = value;
            return (
                <DataTableCell {...cellProps}>
                    {columnDefinition.expandable && (
                        <>
                            {cellProps.item._hasChildren && expandCollapseButton(cellProps.item)}
                            <span style={{ marginLeft: cellProps.item._parentId ? "3em" : undefined }} />
                        </>
                    )}
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            onRowAction(columnDefinition.action, cellProps.item);
                        }}
                    >
                        {children}
                    </a>
                </DataTableCell>
            );
        } else if (columnDefinition.type === "formattedDate") {
            let value = children == null ? null : formattedDateTime(children);
            cellProps.title = value;
            return <DataTableCell {...cellProps}>{value}</DataTableCell>;
        } else if (columnDefinition.type === "boolean") {
            return <DataTableCell {...cellProps}>{children ? <CheckIcon /> : ""}</DataTableCell>;
        } else if (columnDefinition.type === "percent") {
            let value = children == null ? null : formattedPercentage(children);
            cellProps.title = value;
            return <DataTableCell {...cellProps}>{value}</DataTableCell>;
        } else if (columnDefinition.type === "number") {
            let value = children == null ? null : children.toLocaleString();
            cellProps.title = value;
            return <DataTableCell {...cellProps}>{value}</DataTableCell>;
        }
        return <DataTableCell {...cellProps}>{children}</DataTableCell>;
    };

    // Required: Set the `displayName` for proper functioning with DataTable
    CustomDataTableCell.displayName = DataTableCell.displayName;

    useEffect(() => {
        let index = 0;
        const newRecords = [...records];

        // make sure all record have an id, as we use this further down in the code
        newRecords.forEach((record) => {
            record.id = record.id || `${index++}`;
        });

        const recordMap = newRecords.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
        }, {});
        newRecords.forEach((record) => {
            record.id = record.id || `${index++}`;
            let parent = recordMap[record._parentId];
            if (parent) {
                parent._hasChildren = true;
                record.classNameRow = parent._expanded ? "expanded" : "collapsed";
            }
        });
        setLocalRecords(newRecords);
    }, [records]);

    const onSort = ({ property, sortDirection }: { property: string; sortDirection: "desc" | "asc" }) => {
        onOrderBy({ orderBy: property, orderDirection: sortDirection });
    };

    const toggleRecord = (record) => {
        const expanded = !record._expanded;
        setLocalRecords((prev) => {
            return prev.map((item) => {
                if (item.id === record.id) {
                    return { ...item, _expanded: expanded };
                } else if (item._parentId === record.id) {
                    return { ...item, classNameRow: expanded ? "expanded" : "collapsed" };
                } else {
                    return item;
                }
            });
        });
    };

    const expandCollapseButton = (record) => {
        return (
            <button
                className="slds-button slds-button_icon slds-button_icon-x-small slds-m-right_x-small ps-tree-grid-expand-collapse-button"
                tabIndex={-1}
                title={record._expanded ? "Collapse" : "Expand"}
                onClick={() => toggleRecord(record)}
            >
                <svg className="slds-button__icon slds-button__icon_small" aria-hidden="true">
                    <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#chevron${record._expanded ? "down" : "right"}`} />
                </svg>
            </button>
        );
    };

    const defaultColumnWidth = useMemo(() => {
        const validColumns = Array.isArray(columns) ? columns : [];
        const totalFixedWidth = validColumns.reduce((total, col) => total + (col.width || 0), 0);
        const columnsWithFixedWidth = validColumns.filter((col) => !!col.width);
        const padding = 8; // default padding from DataTable
        const totalWidth = tableWidth - padding;
        const adjustableColumnCount = Math.max(1, validColumns.length - columnsWithFixedWidth.length);
        const defaultWidth = totalFixedWidth > 0 ? (totalWidth - totalFixedWidth) / adjustableColumnCount : totalWidth / Math.max(1, validColumns.length);
        return Math.max(minColumnWidth, defaultWidth);
    }, [columns, tableWidth]);

    const checkWidth = (column: ColumnType, width: number) => {
        width = Math.max(Math.min(width, maxColumnWidth), minColumnWidth);
        width = Math.min(width, column?.maxWidth || width);
        width = Math.max(width, column?.minWidth || width);
        return width;
    };

    return (
        <div className="custom-record-table">
            <IconSettings iconPath="/assets/icons">
                {!!columns?.length && (
                    <DataTable
                        key={localRecords?.length} // needs different value each time the number of records changes, otherwise the column resizing breaks
                        resizable
                        fixedLayout
                        fixedHeader
                        columnBordered
                        keyboardNavigation
                        items={localRecords}
                        resizableOptions={{ resizeMode: "overflow" }}
                        id="custom-record-table"
                        onSort={onSort}
                    >
                        {columns.map((col) => {
                            const isSortColumn = orderBy === col.property;
                            return (
                                <DataTableColumn
                                    key={col.property}
                                    label={col.label}
                                    property={col.property}
                                    sortable={col.sortable}
                                    sortDirection={isSortColumn ? orderDirection : undefined}
                                    isSorted={isSortColumn}
                                    width={`${checkWidth(col, col.width || defaultColumnWidth)}px`}
                                >
                                    <CustomDataTableCell columnDefinition={col} onRowAction={onRowAction} fixedLayout />
                                </DataTableColumn>
                            );
                        })}
                    </DataTable>
                )}
            </IconSettings>
        </div>
    );
};

export default RecordTable;
