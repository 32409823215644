import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import { GENERAL_ERROR, PASSWORD_MISMATCH } from "../../../constants/ToastMessages";
import "../../Pages.css";
import useAuthContext from "../../../context/useAuthContext";
import useToastContext from "../../../context/useToastContext";

const NewPasswordForm = ({ newUser }) => {
    const { userHasAuthenticated, user, setUser } = useAuthContext();
    const { addToast } = useToastContext();

    const [userDetail, setUserDeatil] = useState({
        password: "",
        confirmPassword: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const { region } = user;
    const { password, confirmPassword } = userDetail;
    const navigate = useNavigate();

    const handleUserInputChange = (e, item) => {
        setUserDeatil((prevUser) => ({ ...prevUser, [item]: e.target.value }));
    };

    const handleSubmitResetForm = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true);

            if (password !== confirmPassword) {
                addToast("warning", "Password Mismatch", PASSWORD_MISMATCH);
                return;
            }

            await Auth.completeNewPassword(newUser, password); //returns user, but without attributes
            var user = await Auth.currentAuthenticatedUser(); // returns user, with attributes

            if (user) {
                setUser((prev) => ({
                    ...prev,
                    username: user.username,
                    email: user.attributes.email,
                    id: user.attributes.sub,
                }));
                userHasAuthenticated(true);
                Cookies.set("region", region, { expires: 90 });
                navigate("/", { replace: true });
                return;
            } else {
                addToast("error", "Error", "Failed to change password.");
            }
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmitResetForm}>
            <p className="form-title">Enter your new password</p>
            <div className="user-inputs">
                <Input type="password" label="New password" value={password} onChange={(e) => handleUserInputChange(e, "password")} autoComplete="new-password" />
            </div>
            <div className="user-inputs">
                <Input type="password" label="Confirm new password" value={confirmPassword} onChange={(e) => handleUserInputChange(e, "confirmPassword")} autoComplete="new-password" />
            </div>
            <div className="login-signup-button">
                <Button
                    type="submit"
                    label={<>Change Password {isLoading ? <Spinner size="small" /> : null}</>}
                    variant="brand"
                    disabled={isLoading || !password || !confirmPassword}
                    className="login-submit-button"
                />
            </div>
        </form>
    );
};

export default NewPasswordForm;
