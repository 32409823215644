export const RECORD_COLUMNS = [
    {
        label: "Name",
        type: "link",
        property: "name",
        action: "details",
        sortable: true,
        minWidth: 200,
        href: "/StoreManagement?selected=keys_key_${id}", // eslint-disable-line no-template-curly-in-string
    },
    {
        label: "# Values",
        width: 120,
        property: "numValues",
        type: "number",
    },
    {
        label: "# Distinct",
        width: 170,
        property: "robustDistinct",
        type: "number",
    },
    {
        label: "Data Type",
        width: 200,
        property: "dataType",
        type: "text",
    },
    {
        label: "Relevance",
        width: 120,
        property: "relevance",
        type: "percent",
        sortable: true,
    },
    {
        label: "Latest Data",
        width: 180,
        property: "dataLastChangedOn",
        type: "formattedDate",
    },
];
