import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import { GENERAL_ERROR } from "../../../constants/ToastMessages";
import "../../Pages.css";
import useAuthContext from "../../../context/useAuthContext";
import useToastContext from "../../../context/useToastContext";

const ConfirmationForm = ({ userForm }) => {
    const { addToast } = useToastContext();

    const [isLoading, setIsLoading] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");

    const { userHasAuthenticated, user, setUser } = useAuthContext();
    const { region } = user;
    const { username, password, email } = userForm;

    const navigate = useNavigate();

    const handleConfirmationCodeChange = (e) => {
        setConfirmationCode(e.target.value);
    };

    const handleConfirmationSubmit = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true);

            const confirmationResult = await Auth.confirmSignUp(username, confirmationCode);
            const user = await Auth.signIn(username, password);
            if (confirmationResult && user) {
                setUser((prev) => ({ ...prev, username, email, region }));
                userHasAuthenticated(true);
                navigate("/", { replace: true });

                // set region in cookies
                Cookies.set("region", region, { expires: 90 });
                return;
            }
        } catch (err) {
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleConfirmationSubmit}>
            <p className="form-title">We have sent a code by email. Enter it below to sign up</p>
            <div className="user-inputs">
                <Input type="tel" label="Code" value={confirmationCode} onChange={(e) => handleConfirmationCodeChange(e)} />
            </div>
            <div className="login-signup-button">
                <Button type="submit" label={<>Verify {isLoading ? <Spinner size="small" /> : null}</>} variant="brand" disabled={isLoading || !confirmationCode} className="login-submit-button" />
            </div>
        </form>
    );
};

export default ConfirmationForm;
