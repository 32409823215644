export const RECORD_COLUMNS = [
    {
        label: "Name",
        type: "link",
        property: "name",
        key: "name",
        sortable: true,
        minWidth: 200,
        action: "details",
    },
    {
        key: "custom",
        label: "Custom",
        property: "custom",
        width: 80,
        type: "boolean",
    },
    {
        label: "Field",
        width: 200,
        property: "keyName",
        key: "keyName",
        type: "text",
    },
    {
        label: "Relevance",
        width: 120,
        property: "relevance",
        type: "percent",
        sortable: true,
    },
];
