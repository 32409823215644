// ****************
// We are not currently using this Global state, it was not deleted for future use.
// ****************

import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the type for the context
type GlobalStateContextType = {
    recordLoading: boolean;
    setRecordLoading: (loading: boolean) => void;
    reload: string;
    setReload: (reload: string) => void;
};

// Create the context
const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined);

// Create the provider component
export const GlobalStateContextProvider = ({ children }: { children: ReactNode }) => {
    const [recordLoading, setRecordLoadingState] = useState<boolean>(false);
    const [reload, setReloadState] = useState<string>("");

    // Function to update the `recordLoading` state
    const setRecordLoading = (loading: boolean) => {
        setRecordLoadingState(loading);
    };

    // Function to update the `reload` state
    const setReload = (reload: string) => {
        setReloadState(reload);
    };

    return <GlobalStateContext.Provider value={{ recordLoading, setRecordLoading, reload, setReload }}>{children}</GlobalStateContext.Provider>;
};

// Custom hook to use the GlobalStateContext
export const useGlobalStateContext = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("useGlobalStateContext must be used within a GlobalStateContextProvider");
    }
    return context;
};

export default useGlobalStateContext;
