import { useState } from "react";

import Record from "../../helpers/recordLayer";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants";

const PsFolderList = (props) => {
    const [view, setView] = useState("table");
    const [mode, setMode] = useState("init");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const parseResponse = (response) => {
        return response.map(({ id, name, type, description, sortOrder }) => ({
            id,
            name,
            type,
            namePlain: Record.removeMarkup(name),
            nameMarkup: Record.markupToHtml(name),
            description,
            descriptionPlain: Record.removeMarkup(description),
            descriptionMarkup: Record.markupToHtml(description),
            sortOrder,
        }));
    };
    return (
        <div
            style={{
                height: mode === "empty" ? "auto" : "100%",
            }}
        >
            <PsRecordGrid
                module="relate"
                object="folder"
                queryFilter={props.queryFilter}
                tagLine="Select a folder to view saved insights"
                emptyLine="No records found for the specified criteria"
                recordLabel="Folder"
                recordLabelPlural="Folders"
                parseResponse={parseResponse}
                parentId={props.parentId}
                mode={mode}
                onModeChange={setMode}
                view={view}
                onViewChange={setView}
                orderBy={orderBy}
                onOrderByChange={setOrderBy}
                orderDirection={orderDirection}
                onOrderDirectionChange={setOrderDirection}
                loading={loading}
                onLoadingChange={setLoading}
                showLoadMore={true}
                hasMore={hasMore}
                onHasMoreChange={setHasMore}
                recordColumns={RECORD_COLUMNS}
                maxRecords={100}
                childToParent={props.childToParent}
                parentToChildEvent={props.parentToChildEvent}
            />
        </div>
    );
};

export default PsFolderList;
