import { Tooltip, Spinner } from "@salesforce/design-system-react";
import "./DropBox.css";
import { StopOutlined, CloseCircleFilled } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { Tag } from "antd";
import IconButton from "../../../../ui/buttons/IconButton";
import { isIdLabel } from "./helpers";
import { mapConstraintsWithEndPoint } from "./constants";
import { truncateText } from "../../../../utils";

const DropBox = ({ dropAllowed = true, data, setGrabbedItem, onUpdateValues, loading }) => {
    function onDrop(e) {
        e.preventDefault();
        if (!dropAllowed) return;
        const dataJson = e.dataTransfer.getData("data");
        if (!!dataJson) {
            setGrabbedItem(null);
            const { value, dragBoxSlot, updatedDragBoxValues } = JSON.parse(dataJson);
            if (dragBoxSlot === data?.slot) return;
            const updatedValues = [...data.values, value];
            onUpdateValues([
                { slot: data?.slot, values: updatedValues },
                { slot: dragBoxSlot, values: updatedDragBoxValues },
            ]);
            return;
        }
        const field = JSON.parse(e.dataTransfer.getData("item"));
        let title = "";
        field.breadcrumb?.forEach((breadcrumbItem) => {
            if (breadcrumbItem.name) title = title ? title + " -> " + breadcrumbItem.name : breadcrumbItem.name;
        });
        const updatedValues = [...data.values];
        const config = field?.setting?.config;

        const newValue = {
            id: isIdLabel(config) ? field.label : field.id,
            label: field.label,
            title: title,
            config,
        };
        updatedValues.push(newValue);
        onUpdateValues([{ slot: data?.slot, values: updatedValues }]);
    }
    function oDragOver(e) {
        e.preventDefault();
        if (!dropAllowed) {
            e.dataTransfer.dropEffect = "none";
        } else {
            e.dataTransfer.dropEffect = "copy";
        }
    }
    function onDelete(index) {
        // Create a new array by filtering out the item at the specified index
        const updatedValues = data.values.filter((_, i) => i !== index);
        // Call the update function with the modified values
        onUpdateValues([{ slot: data?.slot, values: updatedValues }]);
    }
    function getPrefix(config) {
        const findPrefix = mapConstraintsWithEndPoint.find((item) => item.config === config)?.prefix || "";
        return findPrefix ? findPrefix + ":" : "";
    }
    function onDragStart(e, value, index, slot) {
        const updatedDragBoxValues = data.values.filter((_, i) => i !== index);
        e.dataTransfer.setData(
            "data",
            JSON.stringify({
                value,
                dragBoxSlot: slot,
                updatedDragBoxValues,
            })
        );
        setGrabbedItem({ item: value, origin: "box" });
    }
    function onDragEnd() {
        setGrabbedItem(null);
    }
    return (
        <div onDrop={onDrop} onDragOver={oDragOver} className="builder-drop-box-container">
            <div className="box-name-wrapper">
                <p className="box-name">{data.name}</p>
                <div className="tooltip-wrapper">
                    <Tooltip content={data.info} id="tooltip" align="top left" variant="learnMore" />
                </div>
            </div>

            <div className={`drop-zone ${!dropAllowed ? "disabled" : "enabled"} `}>
                {loading && (
                    <span className="drop-zone-container">
                        <Spinner size="small" />
                    </span>
                )}
                {!dropAllowed && !loading && (
                    <span className="drop-zone-container disabled">
                        <StopOutlined className="icon-not-allowed" />
                        <p className="text">Can not drop this item</p>
                    </span>
                )}
                {dropAllowed && !loading && (
                    <span className="drop-zone-container">
                        {!data?.values?.length ? (
                            <p className="text">Drag from selection menu on the left</p>
                        ) : (
                            <>
                                {data.values.map((v, i) => {
                                    return (
                                        <div key={i}>
                                            <Tag
                                                draggable
                                                className="tag-drop-box"
                                                onDragStart={(e) => onDragStart(e, v, i, data.slot)}
                                                onDragEnd={onDragEnd}
                                                key={i}
                                                title={v?.title}
                                                closable
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    onDelete(i);
                                                }}
                                            >
                                                {truncateText(`${getPrefix(v.config)} ${v?.label}`, 35) /*TODO: use slds-truncate instead*/}
                                            </Tag>
                                        </div>
                                    );
                                })}
                                <div className="delete-all-box">
                                    <IconButton
                                        icon={<CloseCircleFilled />}
                                        onClick={() =>
                                            onUpdateValues([
                                                {
                                                    slot: data?.slot,
                                                    values: [],
                                                },
                                            ])
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

export default DropBox;
