import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./SavedInsights.css";
import Record from "../../helpers/recordLayer";
import PsFolderList from "../../components/ps-folder-list/PsFolderList";
import PsDashboardGrid from "../../components/ps-dashboard-grid/PsDashboardGrid";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";

const SavedInsights = () => {
    const { handleLogout } = useAuthContext();
    const [applyNow, setApplyNow] = useState(true);
    const [cmpState, setCmpState] = useState({
        type: {},
        view: "list",

        // <!-- navigation tree -->
        folderMap: {},
        folderTree: {},

        selectedObject: "topFolder",
        folderId: "",
        listFilter: {},

        pattern: {},
        queryFilter: {},

        // navigation tree
        navigationLoading: true,

        // new
        selectedNames: [],
        selectedRecords: [],

        parentToChildEvent: {},
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const cmpWorking = useRef({});
    const cmpNavigationTree = useRef(null);
    const leftRef = useRef(null);
    const searchdivRef = useRef(null);
    const navigationRef = useRef(null);
    const navigationscroll = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        init();
    }, []);

    useEffect(() => {
        onPageReferenceChange();
    }, [searchParams]);

    const cmp = {
        get: (key) => {
            return cmpWorking.current[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },
    };

    const onPageReferenceChange = () => {
        try {
            parsePageRef();
        } catch (err) {
            console.error(err);
        }
    };

    const init = () => {};

    const dispatchEvent = (event) => {
        cmp.set("parentToChildEvent", event);
    };

    const bubbleEvent = (event) => {
        let stopPropagation = false;
        if (event.type === "navigation") {
            stopPropagation = true;
            handleNavigationEvent(event);
        } else if (event.type === "dataCompEvent") {
            stopPropagation = true;
            handleDataCompEvent(event);
        } else if (event.type === "logout") {
            stopPropagation = true;
            handleLogout();
        }

        if (!stopPropagation) {
            dispatchEvent(event);
        }
    };

    const handleDataCompEvent = (event) => {
        try {
            if (event.action === "viewDetails") {
                var pattern = event.pattern;
                var patternId = pattern?.id;
                var currentId = cmpState.pattern?.id;
                var currentView = cmpState.view;
                cmp.set("pattern", pattern);

                if (patternId) {
                    if (patternId !== currentId || currentView !== "detail") {
                        navigatePattern(patternId);
                    }
                } else {
                    // don't navigate if no patternId available
                    cmp.set("view", "details");
                }
            } else if (event.action === "apply") {
                dispatchEvent(event);
            } else if (event.action === "close") {
                navigatePattern(undefined);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleNavigationEvent = (event) => {
        var source = event.source;

        // navigate to different tab
        var tab = event.tab;
        if (tab) {
            navigate("/" + tab);
            return;
        }

        // if no object is provided (e.g., when navigating to parentId), use the navigation tree to figure out where to go
        var id = event.id;
        var object = event.obj;
        if (id && !object) {
            var name = cmpNavigationTree.current.getNameFromId(id) || Record.nameFromDetails("folders", "folder");
            navigateFromName(null, name);
            return;
        }

        // scroll only
        if (["change", "closeSearch"].includes(source)) {
            var scroll = event.scroll;
            var scroller = leftRef.current;
            var searchInput = searchdivRef.current;

            // update scroll position after rendering, so that rendered sizes are available
            if (searchInput && scroller && scroll != null) {
                var timer = setTimeout(() => {
                    var top = searchInput.offsetTop + searchInput.offsetHeight;
                    scroller.scrollTop = scroll * (scroller.scrollHeight - top);
                }, 0);
            }
        }

        // navigate to specified record
        if (["record", "grid", "tree"].includes(source)) {
            navigateFromEvent(event);
        }
    };

    const navigateFromEvent = (event) => {
        // if id is empty (i.e., when creating a new record), set the parent
        var id = event.id;
        var parentId = id ? undefined : event.parentId;

        // navigate
        var defaultSections = { folder: "folders" };
        var item = Record.itemFromEvent(event, defaultSections);

        // navigate
        navigateFromName(parentId, item.name);
    };

    const navigateFromName = (parentId, name) => {
        var selectedObject = cmp.get("selectedObject");
        var selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
        var selectedParentId = cmp.get("parentId");
        var parsed = Record.parseName(name);
        var selected = searchParams.get("selected");

        if (parsed.id !== selectedId || parsed.config !== selectedObject || parentId !== selectedParentId || selected !== name) {
            let search = "?selected=" + name; // TODO: use proper URL construction methods here and throughout the code
            if (parentId) {
                search = search + "__parent=" + parentId;
            }

            navigate({ pathname: "/SavedInsights", search });
        }
    };

    const navigatePattern = (patternId) => {
        if (!patternId) {
            searchParams.delete("pattern");
            setSearchParams(searchParams);
        } else {
            searchParams.set("pattern", patternId);
            setSearchParams(searchParams);
        }
    };

    // updates component navigation items from query parameters
    const parsePageRef = () => {
        let defaultSelected = Record.nameFromDetails("folders", "folder");
        let selected = searchParams.get("selected") || defaultSelected;
        selected = selected === "__" ? defaultSelected : selected;

        var parsed = Record.parseName(selected);
        var config = parsed.config;
        var id = parsed.id;
        var listFilter = { folderId: id };

        const parentId = selected.split("parent=")[1]; // TODO: NAVIGATION: use proper url parameter
        if (parentId !== cmp.get("parentId")) {
            cmp.set("parentId", parentId);
        }

        cmp.set("selectedObject", config);
        cmp.set("selectedItem", selected);
        cmp.set(`${config}Id`, id);

        var patternId = searchParams.get("pattern");
        var view = patternId ? "detail" : "list";

        // update filters depending on the view
        if (view === "list") {
            if (JSON.stringify(listFilter) !== JSON.stringify(cmp.get("listFilter"))) {
                cmp.set("listFilter", listFilter);
            }
        } else if (view === "detail") {
            var currentId = cmp.get("pattern")?.id;
            if (patternId !== currentId) {
                cmp.set("pattern", { id: patternId });
            }
        }

        // set right-side panel view mode
        cmp.set("view", view);
    };

    const isDetailView = cmpState.view === "detail";

    return (
        <div className="SavedInsights">
            <PsNavigationHeader childToParent={bubbleEvent} loading={cmpState.loading} showClose={isDetailView} showApplyNow={isDetailView} applyNow={applyNow} setApplyNow={setApplyNow} />

            <div className="tab-content slds-p-around_medium">
                {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
                <div className={cmpState.view === "list" ? "left slds-m-right_medium" : "slds-hide"}>
                    <div ref={searchdivRef}>
                        {/* <SearchBox
              searchText={cmpState.searchText}
              loading={cmpState.navigationLoading}
              handleSearchTextChange={handleSearchTextChange}
              handleSearchSubmit={handleSearchSubmit}
            /> */}
                    </div>
                    {/* <!-- navigation tree --> */}
                    <article ref={leftRef} className="slds-card left-card slds-card_boundary slds-scrollable">
                        <div className="slds-card__header">
                            <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small">Browse</h2>
                        </div>

                        <div className="slds-p-horizontal_x-small">
                            <PsNavigationTree
                                multiSelect={false}
                                sections={["folders"]}
                                selected={cmpState.selectedItem}
                                parentCmp={cmp}
                                setLoading={(value) => cmp.set("navigationLoading", value)}
                                ref={cmpNavigationTree}
                                childToParent={bubbleEvent}
                                parentToChildEvent={cmpState.parentToChildEvent}
                            />
                        </div>
                    </article>
                </div>

                {/* <!-- right panel view --> */}
                <div className={!isDetailView ? "right " : "slds-hide"}>
                    <PsSetupStatus title="Saved Insights" tagLine="View saved and liked insights, and organize insights as dashboards." />

                    {
                        //   <Card className="PsRecordGrid slds-scrollable" heading="">
                        //     <div className="slds-is-relative">
                        //       <div
                        //         className="slds-p-around_medium slds-illustration slds-illustration_large"
                        //         aria-hidden="true"
                        //       >
                        //         <IllustrationOpenRoad />
                        //         <div
                        //           className="slds-text-color_weak"
                        //           style={{
                        //             marginBottom: "150px",
                        //           }}
                        //         >
                        //           <h3 className="slds-text-heading_medium">
                        //             Select a folder
                        //           </h3>
                        //         </div>
                        //       </div>
                        //     </div>
                        //   </Card>
                    }

                    {cmpState.selectedObject === "folder" && !cmpState.listFilter.folderId && (
                        <PsFolderList
                            parentId={cmpState.folderId} //
                            queryFilter={cmpState.listFilter}
                            childToParent={bubbleEvent}
                        />
                    )}

                    {cmpState.selectedObject === "folder" && cmpState.listFilter.folderId && (
                        <PsDashboardGrid view="grid" queryFilter={cmpState.listFilter} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} parentCmp={cmp} />
                    )}
                </div>

                {/* <!-- pattern detail --> */}
                {isDetailView && <PsPatternDetailedView pattern={cmpState.pattern} childToParent={bubbleEvent} parentToChildEvent={cmpState.parentToChildEvent} parentCmp={cmp} applyNow={applyNow} />}
            </div>
        </div>
    );
};

export default SavedInsights;
