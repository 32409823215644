import { v4 as uuidv4 } from "uuid";
import { Auth, API } from "aws-amplify";
import RecordConstants from "../constants/RecordConstants";

const Record = {
    /*
  =========================================
     ApiWrapper
  =========================================
*/

    makeCallout: function (myCalloutParameters, failsWhenEmpty, onSuccess, onError) {
        Auth.currentSession()
            .then((res) => {
                let accessToken = res.getAccessToken();
                let jwt = accessToken.getJwtToken();
                // to start Chrome without CORS checks:
                // open /Applications/Google\ Chrome.app --args --user-data-dir="/var/tmp/chrome-dev-disabled-security" --disable-web-security --disable-site-isolation-trials
                let headerBearerToken = `Bearer ` + jwt;

                const apiName = "Application";
                const path = myCalloutParameters.path;
                if (myCalloutParameters.method === "GET") {
                    const myInit = {
                        headers: { Authorization: headerBearerToken },
                        response: true,
                    };

                    API.get(apiName, path, myInit)
                        .then((response) => {
                            //can do more with the fact that we have error handling in here
                            var parsedResponse = this.parseResponse(response, failsWhenEmpty);

                            this.onRecordCallback(myCalloutParameters, parsedResponse, onSuccess, onError);
                        })
                        .catch((error) => {
                            this.onRecordCallback(myCalloutParameters, error.response, onSuccess, onError);
                            //onError.call();
                        });
                } else if (myCalloutParameters.method === "PUT") {
                    const myInit = {
                        headers: { Authorization: headerBearerToken },
                        response: true,
                        body: JSON.parse(myCalloutParameters.body),
                    };

                    API.put(apiName, path, myInit)
                        .then((response) => {
                            //can do more with the fact that we have error handling in here
                            var parsedResponse = this.parseResponse(response, failsWhenEmpty);

                            this.onRecordCallback(myCalloutParameters, parsedResponse, onSuccess, onError);
                        })
                        .catch((error) => {
                            this.onRecordCallback(myCalloutParameters, error.response, onSuccess, onError);
                            //onError.call();
                        });
                } else if (myCalloutParameters.method === "POST") {
                    const myInit = {
                        headers: { Authorization: headerBearerToken },
                        response: true,
                        body: JSON.parse(myCalloutParameters.body),
                    };

                    API.post(apiName, path, myInit)
                        .then((response) => {
                            var parsedResponse = this.parseResponse(response, failsWhenEmpty);

                            this.onRecordCallback(myCalloutParameters, parsedResponse, onSuccess, onError);
                        })
                        .catch((error) => {
                            this.onRecordCallback(myCalloutParameters, error.response, onSuccess, onError);
                            //onError.call();
                        });
                } else if (myCalloutParameters.method === "DELETE") {
                    const myInit = {
                        headers: { Authorization: headerBearerToken },
                        response: true,
                        body: "",
                    };

                    API.del(apiName, path, myInit)
                        .then((response) => {
                            var parsedResponse = this.parseResponse(response, failsWhenEmpty);

                            this.onRecordCallback(myCalloutParameters, parsedResponse, onSuccess, onError);
                        })
                        .catch((error) => {
                            this.onRecordCallback(myCalloutParameters, error.response, onSuccess, onError);
                            //onError.call();
                        });
                } else if (myCalloutParameters.method === "PATCH") {
                    const myInit = {
                        headers: { Authorization: headerBearerToken },
                        response: true,
                        body: JSON.parse(myCalloutParameters.body),
                    };

                    API.patch(apiName, path, myInit)
                        .then((response) => {
                            var parsedResponse = this.parseResponse(response, failsWhenEmpty);

                            this.onRecordCallback(myCalloutParameters, parsedResponse, onSuccess, onError);
                        })
                        .catch((error) => {
                            this.onRecordCallback(myCalloutParameters, error.response, onSuccess, onError);
                            //onError.call();
                        });
                }
            })
            .catch((err) => {
                if (err === "No current user") {
                    window.location.href = "/login";
                }
            });
    },

    encodeQueryData: function (data) {
        const ret = [];
        for (let d in data) {
            if (data[d] != null) {
                ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
            }
        }
        return ret.join("&");
    },

    arrayToQueryString: function (obj) {
        const queryString = Object.entries(obj)
            .filter(([key, val]) => val != null && (!Array.isArray(val) || val.length))
            .map(([key, val]) => (Array.isArray(val) ? val.map((val) => encodeURIComponent(key) + "=" + encodeURIComponent(val)).join("&") : encodeURIComponent(key) + "=" + encodeURIComponent(val)))
            .join("&");
        return queryString;
    },

    // parses API response
    //parseResponse : function(HttpResponse response, Boolean failsWhenEmpty) {
    parseResponse: function (response, failsWhenEmpty) {
        if (!response) {
            return { success: false, data: { type: "NotConnected", message: "No response from server", suggestion: "Check your network connection" } };
        }

        try {
            if (response.status === 200) {
                if (!response.data?.length && failsWhenEmpty) {
                    return { success: false, data: { type: "RecordsNotFound", message: "Record(s) not found" } };
                } else {
                    return { success: true, data: response.data };
                }
            } else {
                if (response?.type === "Timeout") {
                    return {
                        success: false,
                        data: { type: response.type, message: "Service busy", suggestion: "We cannot currently handle your request due to high server load. Please try again in a few moments" },
                    };
                } else {
                    return { success: false, data: response.data };
                }
                // this may be Salesforce-related error??
                // if (result.message === "Endpoint request timed out") {
                //   result.type = "Timeout";
                // }
            }
        } catch (e) {
            return { success: false, data: { type: "UnexpectedApiResponse", message: "Unexpected API response format" } };
        }
    },

    /*
  =========================================
     Records API
  =========================================
  */

    getRecords: function (module, object, filters = {}, onSuccess = null, onError = null, operation = "GET") {
        let path = "/" + module + "/" + object;
        let inputBody = "";

        if (operation === "PUT") {
            inputBody = this.filtersMapToInputBody(filters);
        } else {
            if (filters && Object.keys(filters).length !== 0) {
                const queryString = this.arrayToQueryString(filters);
                path += queryString ? "?" + queryString : "";
            }
        }

        //makeCallout
        const myCalloutParameters = {
            path: path,
            method: operation,
            body: inputBody,
        };
        this.makeCallout(myCalloutParameters, false, onSuccess, onError);
    },

    filtersMapToInputBody: function (filters) {
        return JSON.stringify(filters || {});
        // TODO: the below code doesn't work; it translates null to "null"
        // let inputBody = "";

        // const keyList = Object.keys(filters);

        // if (keyList.length > 0) {
        //     inputBody = "{ ";
        //     for (let i = 0; i < keyList.length; i++) {
        //         const key = keyList[i];
        //         const value = filters[key];
        //         if (value === undefined) continue;
        //         if (key === "maxRecords") {
        //             inputBody += `"${key}": ${value} `;
        //         } else if (key === "constraints" && typeof value === "object") {
        //             inputBody += `"${key}": ${JSON.stringify(value)} `;
        //         } else {
        //             inputBody += `"${key}": "${value}" `;
        //         }
        //         if (i < keyList.length - 1) {
        //             inputBody += ", "; // add comma, unless last item
        //         }
        //     }
        //     inputBody += " }";
        // } else {
        //     inputBody = "{}";
        // }
        // return inputBody;
    },

    getRecord: function (module, object, recordId, filters = {}, inputBody = "", operation = "GET", onSuccess = null, onError = null) {
        //need to do callout directly, check apex code, especially for inputBody
        let path = "/" + module + "/" + object + "/" + recordId;
        if (operation === "PUT" && module === "plot") {
            path = "/plot";
        }

        if (filters != null && Object.keys(filters).length > 0) {
            path += "?" + this.encodeQueryData(filters);
        }

        const myCalloutParameters = { path: path, method: operation, body: inputBody };
        this.makeCallout(myCalloutParameters, true, onSuccess, onError);
    },

    // creates record returning record ID immediately
    createRecord: function (module, object, inputBody = "", onSuccess = null, onError = null) {
        let path = "/" + module + "/" + object;

        const myCalloutParameters = {
            path,
            method: "POST",
            body: inputBody,
        };
        this.makeCallout(myCalloutParameters, true, onSuccess, onError);
    },

    // update record
    updateRecord: function (module, object, inputBody, recordId, onSuccess = null, onError = null) {
        let path = "/" + module + "/" + object + "/" + recordId;

        const myCalloutParameters = {
            path,
            method: "PATCH",
            body: inputBody,
        };
        this.makeCallout(myCalloutParameters, true, onSuccess, onError);
    },

    // submitRecord: function (
    //   cmp,
    //   helper,
    //   module,
    //   object,
    //   data,
    //   onSuccess = null,
    //   onError = null
    // ) {
    //   // update record if `id` is set, or create a new record otherwise
    //   var recordId = data.id;
    //   var action;
    //   if (recordId) {
    //     action = cmp.get("c.updateRecord");
    //     action.setParams({
    //       module: module,
    //       obj: object,
    //       recordId: recordId,
    //       value: data,
    //     });
    //   } else {
    //     action = cmp.get("c.createRecord");
    //     action.setParams({ module: module, obj: object, value: data });
    //   }
    //   var params = action.getParams();
    //   action.setCallback(Record, function (response) {
    //     Record.onRecordCallback(
    //       cmp,
    //       helper,
    //       params,
    //       response,
    //       onSuccess,
    //       onError
    //     );
    //   });
    //   // $A.enqueueAction(action);
    // },

    submitRecord: function (module, object, data, onSuccess = null, onError = null) {
        var recordId = data.id;

        if (recordId) {
            this.updateRecord(module, object, JSON.stringify(data), recordId, onSuccess, onError);
        } else {
            this.createRecord(module, object, JSON.stringify([data]), onSuccess, onError);
        }
    },

    deleteRecord: function (module, object, recordId, onSuccess = null, onError = null) {
        let path = "/" + module + "/" + object + "/" + recordId;

        const myCalloutParameters = {
            path,
            method: "DELETE",
            body: "",
        };
        this.makeCallout(myCalloutParameters, true, onSuccess, onError);
    },

    doAction: function (module, obj, action, args, onSuccess = null, onError = null) {
        let path = "/" + module + "/" + obj + "/action";
        const inputBodyObject = {
            action,
            ...args,
        };

        const myCalloutParameters = {
            path,
            method: "POST",
            body: JSON.stringify(inputBodyObject),
        };
        this.makeCallout(myCalloutParameters, true, onSuccess, onError);
    },

    // intializes action on module and object; NB: actions run asynchronously and the API responds before the action starts
    //   public static ApiResultWrapper doAction(String module, String obj, String action, Map<String, String> args) {
    //     Map<String, String> body = new Map<String, String>{'action'=>action};
    //     body.putAll(args);
    //     CalloutParameters myCalloutParameters = new CalloutParameters();
    //     myCalloutParameters.path = '/' + module  + '/' + obj + '/action';
    //     myCalloutParameters.method = 'POST';
    //     myCalloutParameters.body = JSON.serialize(body);
    //     HttpResponse response = makeCallout(myCalloutParameters);
    //     return parseResponse(response, true);
    // }

    getUploadLink: function (module, folder, onSuccess = null, onError = null) {
        let path = "/" + module + "/upload?id=" + folder;

        const myCalloutParameters = {
            path: path,
            method: "GET",
        };
        this.makeCallout(myCalloutParameters, true, onSuccess, onError);
    },

    //OLD SF implementation
    // getUploadLink: function (
    //   cmp,
    //   helper,
    //   module,
    //   folder,
    //   onSuccess = null,
    //   onError = null
    // ) {
    //   var action = cmp.get("c.getUploadLink");
    //   action.setParams({ module: module, folder: folder });
    //   var params = action.getParams();
    //   action.setCallback(Record, function (response) {
    //     Record.onRecordCallback(
    //       cmp,
    //       helper,
    //       params,
    //       response,
    //       onSuccess,
    //       onError
    //     );
    //   });
    //   // $A.enqueueAction(action);
    // },

    // intializes action on module and object; NB: actions run asynchronously and the API responds before the action starts
    //   public static ApiResultWrapper getUploadLink(String module, String folder) {
    //     PageReference path = new PageReference( '/' + module + '/upload');
    //     path.getParameters().put('id', folder);
    //     CalloutParameters myCalloutParameters = new CalloutParameters();
    //     myCalloutParameters.path = path.getUrl();
    //     myCalloutParameters.method = 'GET';
    //     HttpResponse response = makeCallout(myCalloutParameters);
    //     return parseResponse(response, true);
    // }

    //   public class ApiResultWrapper{
    //     @AuraEnabled
    //     Public Boolean success = false;
    //     Public String status = '';
    //     @AuraEnabled
    //     public String type;
    //     @AuraEnabled
    //     public String message;
    //     @AuraEnabled
    //     public String reference;
    //     @AuraEnabled
    //     public String suggestion;
    //     @AuraEnabled
    //     public String json;
    // }

    //IN SF, 2 levels of callouts

    // In SF, 2 levels of myCallouts
    // JS > SF
    // SF > AWS
    onRecordCallback: function (myCalloutParameters, returnValue, onSuccess, onError) {
        try {
            if (returnValue?.success) {
                if (onSuccess) {
                    onSuccess(returnValue.data);
                }
            } else {
                // NOTE: showToast is not implemented here
                // var data = returnValue?.data || {};
                // var toastMessage = data?.message || "No response from server";
                // if (data?.suggestion)
                //   toastMessage += "\n" + data.suggestion;
                // if (data?.reference)
                //   toastMessage += "\n" + data.reference;

                // if (!RecordConstants.SILENT_API_ERRORS.includes(data?.type)) {
                //   this.showToast("Error", toastMessage, "error");
                //   console.log("Error: " + toastMessage);
                // }

                // API error on console helps finding log references
                console.error("API error response:");
                console.error(JSON.parse(JSON.stringify(myCalloutParameters)));
                console.error(returnValue);
                // Redirect to login and clear region data if server response indicates user is inactive.
                if (returnValue?.data?.message === "User is not active") {
                    // IMPROVEMENT: we should explicitly log the user out!
                    window.location.href = "/login";
                }
                if (onError) {
                    onError(returnValue || {});
                }
            }
        } catch (err) {
            console.error(err);
        }
    },

    // IMPROVEMENT: this is not a very good way to generate a UUID, and we can update to use standard javascript UUID generator once that becomes available (https://github.com/tc39/proposal-uuid)
    // from: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
    uuidv4: function () {
        return uuidv4();

        // return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        // );
    },

    flatten: function (record, attrib) {
        // flatten Object inputs on the specified attribute
        const flat = (record[attrib] || []).slice(); // shallow copy
        var i = 0;
        while (i < flat.length) {
            if (Array.isArray(flat[i][attrib])) {
                flat.push(...flat[i][attrib]);
            }
            i++;
        }
        return flat;
    },

    /*
  =========================================
     sorting
  =========================================
*/

    sortByString: function (field, reverse) {
        var dir = reverse ? -1 : 1;
        return function (a, b) {
            a = a[field];
            b = b[field];
            if (a == null) {
                return dir;
            }
            if (b == null) {
                return -1 * dir;
            }
            return dir * a.toLowerCase().localeCompare(b.toLowerCase());
        };
    },

    sortByOther: function (field, reverse) {
        var dir = reverse ? -1 : 1;
        return function (a, b) {
            a = a[field];
            b = b[field];
            return dir * ((a > b) - (b > a));
        };
    },

    // returns the sort function for the data type
    sortFunction: function (records, field) {
        if (Array.isArray(records) && records.length && typeof records[0][field] === "string") {
            return Record.sortByString;
        } else {
            return Record.sortByOther;
        }
    },

    /*
  =========================================
     UI
  =========================================
*/
    showToast: function (title, message, variant, sticky = false) {
        // var notifLib = cmp.find('notifLib');
        // if (!notifLib) {
        //     console.error('The component does not include "notifLib"');
        // }
        // var mode = (sticky) ? 'sticky' : 'dismissable';
        // if (Array.isArray(message)) {
        //     var messageTemplate = message[0];
        //     var messageData = message[1];
        //     cmp.find('notifLib').showToast({title, message: messageTemplate, messageData, variant, mode});
        // } else {
        //     cmp.find('notifLib').showToast({title, message, variant, mode});
        // }
    },

    //challenge that id's have to be unique for regular html ids, whereas auraid's can be duplicated
    setElementAttribute: function (id, attribute, value) {
        try {
            var elements = document.querySelectorAll("[id=" + id + "]");
            elements.forEach((element) => {
                element.setAttribute(attribute, value);
            });
            // const elem = document.getElementById(id);
            // if(elem){elem.setAttribute(attribute, value);}
        } catch (err) {
            console.error("setElementAttribute failed");
            console.error(err);
        }
    },

    //TODO > this is SF code. Convert to react
    checkForm: function (cmp, auraIds, focusFirst = true) {
        return true;

        // check inputs
        var components = [];
        auraIds.forEach((auraId) => {
            // cmp.find returns a single item if there is only one, we always require a list here
            components.push(...[].concat(cmp.find(auraId) || []));
        });

        // check validity
        var allValid = components.reduce(function (validSoFar, inputCmp) {
            inputCmp.showHelpMessageIfInvalid();
            var valid = inputCmp.get("v.validity")?.valid;
            if (!valid && validSoFar && focusFirst) {
                inputCmp.focus();
            } // set focus to the first invalid item; IMPROVEMENT: not sure what 'first' means in this context, we need the invalidated input field that is the highest on the page
            return validSoFar && valid;
        }, true);

        if (!allValid) {
            Record.showToast(cmp, "Input Error", "Please update the invalid form entries and try again.", "error");
        }

        return allValid;
    },

    /*
  =========================================
     Formatting
  =========================================
*/

    // API markup to html
    markupToHtml: function (text) {
        if (text) {
            return text.replace(/\[(.*?)\]/g, "<b>$1</b>");
        } else {
            return "";
        }
    },

    // remove API markup
    removeMarkup: function (text) {
        if (text) {
            return text.replace(/\[(.*?)\]/g, "$1");
        } else {
            return "";
        }
    },

    // very basic cron to human-readable conversion, assuming expression is filled from left to right
    cronToHuman: function (cron) {
        if (!cron) {
            return "";
        }
        var cronParts = ["Every Minute", "Hourly", "Daily", "Monthly", "Weekly", "Yearly"];
        var cronSplit = cron.split(" ");
        if (!["?", "*"].includes(cronSplit[4])) {
            return cronParts[4]; // Weekly
        } else {
            return cronParts[cronSplit.indexOf("*")] || "";
        }
    },

    // IMPROVEMENT: we now randomly set a datetime within the selected frequency as a cron expression, and change that every time the connector is saved. The user may also set their own CRON expression over the API, which we would then overwrite here when saving the connector. Build functionality to keep the selected cron expression if it is not changed, e.g., by dynamically setting the picklist, and updating the picklist values with the chosen record.
    humanToCron: function (human) {
        if (!human || human === "--None--") {
            return "";
        }
        //Random number between min (inclusive) and max (exclusive)
        var getRandomInt = function (min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        var CRON_MAP = {
            "Every Minute": "* * * * ? *",
            Hourly: getRandomInt(0, 59) + " * * * ? *",
            Daily: getRandomInt(0, 59) + " " + getRandomInt(0, 23) + " * * ? *",
            Monthly: getRandomInt(0, 59) + " " + getRandomInt(0, 23) + " " + getRandomInt(1, 28) + " * ? *",
            Weekly: getRandomInt(0, 59) + " " + getRandomInt(0, 23) + " ? * " + getRandomInt(0, 6) + " *",
            Yearly: getRandomInt(0, 59) + " " + getRandomInt(0, 23) + " " + getRandomInt(1, 28) + " " + getRandomInt(1, 12) + " ? *",
        };
        return CRON_MAP[human];
    },

    // parse comma-separated
    parseCSV: function (text) {
        if (!text) {
            return null;
        }
        // adjusted from https://stackoverflow.com/questions/1757065/java-splitting-a-comma-separated-string-but-ignoring-commas-in-quotes
        //   var results = text.split(/,\s*(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g);
        var results = text.split(/,\s*(?=(?:[^"]*"[^"]*")*[^"]*$)/g);
        results = results.map((item) => {
            item = item.trim();
            return item.replace(/^"+|"+$/g, "");
        }); // trim spaces and double quotes;
        return results;
    },

    // array to CSV
    toCSV: function (list) {
        if (!list) {
            return null;
        }
        var results = list.reduce((lst, item) => {
            if (item.includes(",")) {
                // put quotes around items with commas inside
                item = '"' + item + '"';
            } else {
                // trim quotes from items with no commas inside
                item = item.replace(/^"+|"+$/g, "");
            }
            lst.push(item);
            return lst;
        }, []);
        return results.join(", ");
    },

    intervalToHuman: function (interval) {
        // IMPROVEMENT: remove 's' when value=1; use 'this [unit]' when value=0
        return interval.startsWith("-") ? interval.slice(1) + " ago" : interval + " from now";
    },
    /*
  =========================================
     picklists
  =========================================
*/

    // post-processing after loading picklist items
    selectLoadedOption: function (cmp, optionsAttribute, selectedValueAttribute, selectedLabelAttribute = null, setDefault = false) {
        var options = cmp.get(optionsAttribute) || [];
        var selectedValue = cmp.get(selectedValueAttribute);

        // clear the selectedValue if it is not in the options
        if (selectedValue && !options.find((i) => i.value === selectedValue)) {
            selectedValue = null;
        }

        // if there is only one item in the options, select it
        if (options.length === 1) {
            selectedValue = options[0].value;
        }

        // no option was selected, see if there is a default option, and select it
        if (!selectedValue && setDefault) {
            selectedValue = options.find((i) => i.default)?.value;
        }

        // mark the relevant item as selected in the options items
        options.forEach((i) => {
            i.selected = i.value === selectedValue;
        });
        var selectedItem = options.find((i) => i.value === selectedValue) || {};

        // update the component
        try {
            cmp.set(optionsAttribute, options);
        } catch (err) {}
        try {
            cmp.set(selectedValueAttribute, selectedValue);
        } catch (err) {}
        try {
            cmp.set(selectedLabelAttribute, selectedItem.label);
        } catch (err) {}

        return selectedValue;
    },

    // post-processing after loading multi-select picklist items
    selectLoadedOptions: function (optionsAttribute, selectedValuesAttribute, selectedLabelsAttribute = null) {
        var options = optionsAttribute || [];
        var optionsMap = options.reduce((obj, item) => {
            obj[item.value] = item;
            return obj;
        }, {});
        var selectedValues = selectedValuesAttribute || [];

        // clear selectedValues that are not not in the options
        selectedValues = selectedValues.reduce((obj, item) => {
            if (optionsMap[item]) {
                obj.push(item);
            }
            return obj;
        }, []);

        // set labels
        var selectedLabels = selectedValues.reduce((obj, item) => {
            obj.push(optionsMap[item].label);
            return obj;
        }, []);

        // update the component
        try {
            selectedValuesAttribute = selectedValues;
        } catch (err) {}
        try {
            selectedLabelsAttribute = selectedLabels.join(", ");
        } catch (err) {}

        return selectedValues;
    },

    // set label for selected piclist item
    setSelectedLabel: function (cmp, optionsAttribute, selectedValue, labelAttribute) {
        var options = cmp.get(optionsAttribute) || [];
        var item = options.find((i) => i.value === selectedValue) || {};

        //REVIEW: cmp.set doesn't work with record.sourceName
        cmp.set(labelAttribute, item.label);
    },

    // set labels for selection in multi-select picklist
    setSelectedLabels: function (optionsAttribute, selectedValues, labelAttribute) {
        var options = optionsAttribute || [];
        var valueToLabelMap = options.reduce((obj, item) => {
            obj[item.value] = item.label;
            return obj;
        }, {});
        var selectedLabels = selectedValues.reduce((obj, value) => {
            obj.push(valueToLabelMap[value]);
            return obj;
        }, []);
        labelAttribute = selectedLabels.join(", ");
    },

    /*
  =========================================
     Navigation tree
  =========================================
*/

    // navigation tree item from name
    parseName: function (name) {
        if (typeof name !== "string") {
            return {};
        }
        var parts = name.split("_");
        return { name, section: parts[0] || null, config: parts[1] || null, id: parts[2] || null };
    },

    // navigation tree item from name
    nameFromDetails: function (section, config, id) {
        return [section || null, config || null, id || null].map((v) => (v == null ? "" : v)).join("_");
    },

    // create navigation tree item from navigation event, setting default section in case the section is not specified
    itemFromEvent: function (event, objectSectionMap) {
        var object = event.obj;
        var id = event.id;
        var section = event.section || objectSectionMap[object];
        var breadcrumb = event.breadcrumb || [];
        var hasDetails = event.label != null;
        var label = event.label || "loading...";
        var titleBreadCrumb = breadcrumb.slice(1).map((item) => item.name);
        var type = RecordConstants.TERMINOLOGY[object] || "Unknown";
        var title = titleBreadCrumb.length ? type + ": " + titleBreadCrumb.join("->") : "loading...";
        var name = this.nameFromDetails(section, object, id);
        var item = { name, section, config: object, id };
        Object.assign(item, { label: type + ": " + label, title, hasDetails });
        return item;
    },

    // update navigation tree item from record, or create new if not exists
    itemFromRecord: function (item, record, expanded = false) {
        if (item) {
            // update any changed values
            Object.assign(item, { label: record.name, argOrder: record.argOrder });
            return item;
        } else {
            // create new list item from record
            return (({ id, name, argOrder }) => ({
                name: id,
                label: name,
                expanded: expanded,
                argOrder,
                items: [],
                test: "asdf",
            }))(record);
        }
    },

    // keep separate mapping between record and navigation tree item
    mapRecord: function (itemMap, parentId, module, object, record) {
        var id = record.id;
        var loaded = itemMap[id]?.loaded;
        var breadcrumb = Record.breadcrumb(itemMap, parentId);
        var rootId = (breadcrumb ? breadcrumb[0] : {}).id;
        return {
            id,
            name: record.name,
            module,
            object,
            rootId,
            parentId,
            loaded,
            breadcrumb,
        };
    },

    // recursively goes through items tree to find the item with the specified name
    findItem: function (itemTree, name) {
        var selectedItem;
        itemTree.some(function (item) {
            if (item.name === name) {
                selectedItem = item;
                return true;
            }
            var items = item.items;
            if (items && items.length) {
                selectedItem = Record.findItem(items, name);
                return selectedItem !== undefined;
            }
            return null; //Array.prototype.some() expects a value to be returned
        });
        return selectedItem;
    },

    // recursively goes through items tree to find the parent of the item with the specified name
    findParent: function (itemTree, name) {
        var selectedItem;
        itemTree.some(function (item) {
            var items = item.items;
            if (items && items.length) {
                if (items.find((i) => i.name === name)) {
                    selectedItem = item;
                    return true;
                }
                selectedItem = Record.findParent(items, name);
                return selectedItem !== undefined;
            }
            return null; //Array.prototype.some() expects a value to be returned
        });
        return selectedItem;
    },

    // extracts breadcrumb from tree structure as flat list
    breadcrumb: function (itemMap, id) {
        if (id) {
            var record = itemMap[id] || {};
            var result = Record.breadcrumb(itemMap, record.parentId);
            result.push(record);
            return result;
        }
        return [];
    },

    // create item when browsing through navigation tree programmatically
    parseItem: function (itemMap, module, object, record, parentItem) {
        var items = parentItem.items || [];
        var thisItem = items.find((item) => item.name === record.id);

        // create item if not exists
        if (!thisItem) {
            // remove placeholder item
            if (items.length === 1 && items[0].name === undefined) {
                items = [];
            }

            // create and add item to parent
            thisItem = Record.itemFromRecord(null, record, true);
            items.push(thisItem);
            parentItem.items = items;

            // construct and add map item
            itemMap[record.id] = this.mapRecord(itemMap, parentItem.name, module, object, record);
        }
        return thisItem;
    },

    updateItems: function (cmp, action, itemTreeName, itemMapName, parentId, module, object, id, data) {
        // action: 'list', 'create', 'read', 'update', 'delete'
        // data: record or list of records (in case action === 'list') having at least 'id' and 'name' fields
        // object: object of the children
        var itemTree = cmp.get("v." + itemTreeName);
        var itemMap = cmp.get("v." + itemMapName);
        var parent = {};
        if (parentId) {
            parent = Record.findItem(itemTree, parentId) || {};
        } else if (id) {
            parent = Record.findParent(itemTree, id) || {};
            parentId = parent.name;
        }
        var items = parent.items || [];
        var itemLookup = items.reduce((obj, item) => {
            obj[item.name] = item;
            return obj;
        }, {});

        if (action === "list") {
            // update items with list of loaded records
            items = data.reduce((obj, record) => {
                obj.push(this.itemFromRecord(itemLookup[record.id], record));
                itemMap[record.id] = this.mapRecord(itemMap, parentId, module, object, record);
                return obj;
            }, []);

            // update parent
            parent.expanded = true;
            itemMap[parentId].loaded = true;

            // set any removed items to 'undefined' in itemMap
            var newItems = items.reduce((obj, item) => {
                obj[item.name] = item;
                return obj;
            }, {});
            Object.keys(itemLookup).forEach((item) => {
                if (!newItems[item]) {
                    itemMap[item] = undefined;
                }
            });
        } else {
            // remove placeholder item
            if (items.length === 1 && items[0].name === undefined) {
                items = [];
            }

            // delete, add or update single record
            if (action === "delete") {
                var index = items.findIndex((item) => item.name === id);
                if (index > -1) {
                    items.splice(index, 1);
                }
                if (itemMap[id]) {
                    itemMap[id] = undefined;
                }
            } else {
                var item = this.itemFromRecord(itemLookup[id], data); // this already updates any existing item
                if (!itemLookup[id]) {
                    items.push(item);
                } // add new item if not yet in list
                itemMap[id] = this.mapRecord(itemMap, parentId, module, object, data);
            }
        }

        // If there are no items, add a non-selectable '--None--' child item instead, to indicate that the items were loaded
        // Setting 'disabled=true' to make the item becomes non-selectable; NB: some kind of Salesforce-related javascript error is generated.
        // Alternatively; "items = [ {} ];" to show the expanded arrow without any child items.
        if (!items.length) {
            items = [{ name: "", label: " ", metatext: "--None--", disabled: true }];
        } else {
            // sort
            var sortField = items && items.length && items[0].argOrder != null ? "argOrder" : "label";
            var sortFunction = Record.sortFunction(items, sortField);
            items.sort(sortFunction(sortField, false));
        }

        // store updated items with parent
        parent.items = items;
        cmp.set("v." + itemTreeName, itemTree);

        // store itemMap
        cmp.set("v." + itemMapName, itemMap);
    },

    //React combobox requires "id", instead of "value"
    addIds: function (array) {
        array.map((item) => {
            item.id = item.value;
        });
        return array;
    },
};

export default Record;
