import React from "react";

import "../PsKey.css";

interface TextAreaProps {
    handleOverride: (field: string, value: string) => void;
    checkJsonInput: (field: string, value: string) => void;

    fieldErrors: Record<string, string>;
    record: Record<string, any>;

    field: string;
    label: string;
    value: string;
}

const TextArea2: React.FC<TextAreaProps> = ({ handleOverride, checkJsonInput, record, field, label, value, fieldErrors }) => {
    const error = fieldErrors[field];

    return (
        <div className={`slds-form-element textarea-container ${error ? " slds-has-error" : ""}`}>
            <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor={`textarea-${field}`}>
                    {label}
                </label>
                <div className="slds-form-element__control textarea-container-item">
                    <textarea
                        id={`textarea-${field}`}
                        name={field}
                        value={record[value] || ""}
                        onChange={(e) => handleOverride(field, e.target.value)}
                        onBlur={(e) => checkJsonInput(field, e.target.value)}
                        className="slds-textarea textarea"
                    ></textarea>
                </div>
                {error && (
                    <div className="slds-form-element__help" id={`error-${field}`}>
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TextArea2;
