import React, { createContext, useContext, useState, ReactNode } from "react";

export type ToastVariant = "success" | "error" | "info" | "warning";
export type Toast = {
    id: string;
    variant: ToastVariant;
    heading: string;
    details: string | ReactNode;
    visible: boolean;
};

type ToastContextType = {
    toasts: Toast[];
    addToast: (variant: ToastVariant, heading: string, details: string | ReactNode, id?: string) => void;
    removeToast: (id: string) => void;
};

const maxVisibleToastCount = 3;

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    // Function to add a new toast
    const addToast = (variant: ToastVariant, heading: string, details: string, id: string = "") => {
        if (details == null) {
            // TODO: allow sending API response directly, and parse here rather than using toastErrorMessage in many different places
            return;
        }
        setToasts((prevToasts) => {
            const toastId = id || new Date().toISOString();
            if (prevToasts.some((toast) => toast.id === toastId)) {
                return prevToasts;
            }
            return [...prevToasts, { id: toastId, variant, heading, details, visible: prevToasts.length < maxVisibleToastCount }];
        });
    };

    // Function to remove a toast by ID
    const removeToast = (id: string) => {
        setToasts((prevToasts) => {
            // Remove the toast by filtering out the ID
            const updatedToasts = prevToasts.filter((toast) => toast.id !== id);

            // Check how many toasts are visible
            const visibleToastsCount = updatedToasts.filter((toast) => toast.visible).length;

            // If there are less than maxVisibleToastCount visible toasts, make the next toast visible (if exists)
            if (visibleToastsCount < maxVisibleToastCount && updatedToasts.length > visibleToastsCount) {
                updatedToasts.forEach((toast, index) => {
                    if (!toast.visible && index < maxVisibleToastCount) {
                        updatedToasts[index].visible = true;
                    }
                });
            }

            return updatedToasts;
        });
    };

    return <ToastContext.Provider value={{ toasts, addToast, removeToast }}>{children}</ToastContext.Provider>;
};

// Custom hook to use the useToastContext
const useToastContext = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToastContext must be used within a ToastProvider");
    }
    return context;
};

export default useToastContext;
