import { Navigate, Route, Routes } from "react-router";
import PsSEO from "../components/ps-SEO/PsSEO";
import { PAGE_SEO } from "../constants";
import Login from "../pages/login";
import Signup from "../pages/signup";
import ForgotPassword from "../pages/forgot-password";

const UnAuthRouter = () => {
    return (
        <Routes>
            <Route
                path="login"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.login} />
                        <Login />
                    </>
                }
            />
            <Route
                path="signup"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.signup} />
                        <Signup />
                    </>
                }
            />
            <Route
                path="forgotPassword"
                element={
                    <>
                        <PsSEO {...PAGE_SEO.forgotPassword} />
                        <ForgotPassword />
                    </>
                }
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
};

export default UnAuthRouter;
