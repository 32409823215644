export const RECORD_COLUMNS = [
    {
        label: "Name",
        type: "link",
        property: "name",
        key: "name",
        sortable: true,
        action: "details",
        title: "View Details",
        minWidth: 200,
        href: "/DataManagement?selected=filters_filter_${id}", // eslint-disable-line no-template-curly-in-string
    },
    {
        key: "custom",
        label: "Custom",
        width: 80,
        property: "custom",
        type: "boolean",
    },
    {
        label: "Type",
        width: 200,
        property: "type",
        key: "type",
        type: "text",
    },
    {
        label: "Scope",
        width: 200,
        property: "scope",
        key: "scope",
        type: "text",
    },
];
